import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Grid, Typography } from "@mui/material";

import Flag from "@mui/icons-material/Flag";
import { ReactComponent as RideABicycle } from "../svg_logos/ride_a_bicycle.svg";
import { ReactComponent as Adventure } from "../svg_logos/adventure.svg";

import { useSearchParams } from "react-router-dom";
import axios from "axios";

import PopUpDialog from "../components/PopUpDialog";
import ProgressBar from "../components/ProgressBar";
import SectionHeader from "../components/SectionHeader";

const theme = createTheme({
  palette: {
    primary: {
      main: "#257cff",
    },
    secondary: {
      main: "#f45a2a",
    },
  },
  typography: {
    footer: {
      fontSize: 14,
    },
    title: {
      fontSize: 16,
      fontWeight: "bold",
    },
    subtitle: {
      fontSize: 14,
    },
  },
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        asterisk: { display: "none" }
      }
    },
  },
});

const TellUsAboutYourself = ({ url }) => {
  const { t } = useTranslation();

  const [nextClicked, setnextClicked] = useState(false);

  const [searchParams] = useSearchParams();
  const properize_id = searchParams.get("properize_id");

  async function handleSubmit(flowType) {
    setnextClicked(true);
    const payload = {
      properize_id: properize_id,
      flow_type: flowType,
    };

    await axios.post(url + "/flow_type_selection", payload)
    window.location.href = "/id_verification_main?properize_id=" + properize_id;
  };

  const defaultProps = {
    display: "flex",
    flexDirection: "column",
    alignContent: "space-around",
    bgcolor: "background.paper",
    m: 2,
    border: 2,
    borderColor: "#257cff",
    borderRadius: 8,
    style: { width: "20rem", height: "20rem" },
    px: { xs: 0, sm: 4 },
    py: { xs: 0, sm: 5 },
    sx: [
      {
        "&:hover": {
          color: "#f45a2a",
          cursor: "pointer",
        },
      },
    ],
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        <ProgressBar completed={10} />
        <br />
        <SectionHeader Icon={Flag} text={t("about_yourself")} />
        <br />
        <Typography variant="p" color="textSecondary">
          {t("about_yourself_paragraph")}
        </Typography>
        <br />
        <br />
        <Grid container alignItems="center">
          <Box {...defaultProps} onClick={() => handleSubmit("Netherlands")}>
            <Box m="auto">
              <Typography variant="title">{t("already_in_NL")}</Typography>
            </Box>
            <Box m="auto">
              <RideABicycle width="150" height="150" />
            </Box>
            <Box m="auto">
              <Typography variant="subtitle">{t("moreThan3")}</Typography>
            </Box>
          </Box>
          <Box {...defaultProps} onClick={() => handleSubmit("Expat")}>
            <Box m="auto">
              <Typography variant="title">{t("newInNL")}</Typography>
            </Box>
            <Box m="auto">
              <Adventure width="150" height="150" />
            </Box>
            <Box m="auto">
              <Typography variant="subtitle">{t("lessThan3")}</Typography>
            </Box>
          </Box>
        </Grid>
        <PopUpDialog nextClicked={nextClicked} />
      </ThemeProvider>
    </div>
  );
};

export default TellUsAboutYourself;
