import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Link,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";

import ListIcon from "@mui/icons-material/List";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteIcon from "@mui/icons-material/Delete";

import CircularProgressWithLabel from "../components/CircularProgressWithLabel";
import NoFileDialog from "../components/NoFileDialog";
import { uploadFilesToS3Directly } from "../utils/uploadToS3";
import unidecode from "unidecode"
import SectionHeader from "../components/SectionHeader";
import MessagePopup from "../components/MessagePopup";
import Nordigen from "../components/Nordigen";

function makeid(length) {
  var result = "";
  var characters = "abcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const IsExtraCompletedDialog = ({ extraInfoSubmitted, nameAgent, emailAgent }) => {
  const { t } = useTranslation();
  return (
    <Dialog open={extraInfoSubmitted}>
      <DialogContent>
        <DialogContentText>
          {t("extra_info_done1")}
          {nameAgent}
          {t("extra_info_done2")}
          <Link href={"mailto:" + emailAgent} color="inherit" target="_blank">
            {emailAgent}
          </Link>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

const ExtraInfo = ({ url }) => {
  const [extraInfoSubmitted, setExtraInfoSubmitted] = useState(false);
  const [nameAgent, setNameAgent] = useState("");
  const [emailAgent, setEmailAgent] = useState("");
  const [whatToDo, setWhatToDo] = useState("");
  const [accountType, setAccountType] = useState("Lite")
  const [isOpenBanking, setIsOpenBanking] = useState(false);

  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);

  const [checkBoxBank, setCheckBoxBank] = useState(false);
  const [checkBoxDoc, setCheckBoxDoc] = useState(false);

  const [other, setOther] = useState("");

  const [searchParams] = useSearchParams();
  const properize_id = searchParams.get("properize_id");

  const { t } = useTranslation();

  useEffect(() => {
    const payload = {
      id: properize_id,
      properize_id: properize_id,
      database: "Tenants",
    };

    axios.post(url + "/get_data_from_airtable_table?step=extra_info", payload).then((response) => {
      setExtraInfoSubmitted('Extra link' in response.data);
      setNameAgent(response.data["Name agent"]);
      setEmailAgent(response.data["Email agent"]);
      setWhatToDo(response.data["WhatToDo"])
      setAccountType(response.data["Account Type"])
      setIsOpenBanking(response.data["Income source"] === "Open Banking")
    });

  }, []);

  function addFile(event, id) {
    if (event.target.files[0].size > 1048576 * 20)
      return setError(t("This file is too big!"));

    setFiles(files.map(f => f.id === id ? { ...f, file: event.target.files[0] } : f));
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitClicked(true);

    if (files.filter((file) => file.file === null).length > 0) {
      setSubmitClicked(false);
      return setError(t("You forgot to add attachments"));
    }

    if (files.filter((file) => file.type === "").length > 0) {
      setSubmitClicked(false);
      return setError(t("You forgot to specify document type"));
    }

    await uploadFilesToS3Directly(properize_id, files, "properize-miscellaneous", setProgress);

    const payload = {
      properize_id: properize_id,
      miscellaneousText: other,
      fileNames: files.map((file) => unidecode(file.file.name.replaceAll(" ", "_").replaceAll(",", "_"))),
      documentTypes: files.map((file) => file.type),
    };

    axios
      .post(url + "/extra_docs_pipeline", payload, { timeout: 1500 })
      .then((response) => {
        window.location.assign("/completed?properize_id=" + properize_id);
      })
      .catch((err) => {
        window.location.assign("/completed?properize_id=" + properize_id);
      });

  };

  const documentTypes = [
    "Bank statement",
    "Payslip",
    "Proof of employment",
    "Other",
  ].map((documentType) => (
    <MenuItem key={documentType} value={documentType}>
      {t(documentType)}
    </MenuItem>
  ));

  return (
    <div>
      <br />
      <SectionHeader Icon={ListIcon} text={t("extra_info")} />
      <br />
      <Typography>
        {t("you_have_completed_the_process")}
      </Typography>
      <form onSubmit={handleSubmit}>
        {accountType !== 'Lite' && !['Changing Jobs', 'No income'].includes(whatToDo) && <Box color="black" maxWidth="800px" mt={3}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkBoxBank}
                  onChange={() => setCheckBoxBank(!checkBoxBank)}
                />
              }
              label={isOpenBanking ? t("Connect a bank account again") : t("I want to connect a bank account")}
            />
          </FormGroup>
        </Box>}

        {checkBoxBank && <Nordigen properize_id={properize_id} url={url} whatToDo={whatToDo} />}


        {!checkBoxBank && whatToDo !== 'No income' && <Box color="black" maxWidth="800px" mt={1}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkBoxDoc}
                  onChange={() => setCheckBoxDoc(!checkBoxDoc)}
                />
              }
              label={t("upload_miscellaneous")}
            />
          </FormGroup>
        </Box>}
        {!checkBoxBank && checkBoxDoc && (
          <div>
            <Alert
              severity="info"
              color="warning"
              sx={{
                marginTop: 2,
                marginBottom: 2,
              }}
            >
              <AlertTitle>{t("Please Read")}</AlertTitle>
              {t("extra_info_warning")}
            </Alert>

            {files.map((file) => (
              <Grid
                container
                mt={1}
                ml={1}
                spacing={2}
                wrap="nowrap"
                alignItems="center"
                key={file}
              >
                <Grid item xs={4}>
                  <TextField
                    label={t("Document type")}
                    size="small"
                    value={files.find(f => f.id === file.id).type}
                    onChange={(event) => {
                      setFiles(files.map(f => f.id === file.id ? { ...f, type: event.target.value } : f));
                    }}
                    variant="outlined"
                    style={{ backgroundColor: "#F5F5F5" }}
                    fullWidth
                    select
                  >
                    {documentTypes}
                  </TextField>
                </Grid>

                <Grid item xs={7}>
                  <Button
                    variant={
                      files.find((f) => f.id === file.id).file ? "outlined" : "contained"
                    }
                    component="label"
                    color="primary"
                    fullWidth
                  >
                    {!files.find((f) => f.id === file.id).file && t("ATTACH FILE")}
                    {files.find((f) => f.id === file.id).file &&
                      files.find((f) => f.id === file.id).file.name}

                    <input
                      type="file"
                      hidden
                      onChange={(event) => addFile(event, file.id)}
                    />
                  </Button>
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    onClick={() => {
                      setFiles([...files.filter(f => f.id !== file.id)]);
                    }}
                    style={{ borderRadius: 0 }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>

              </Grid>
            ))}

            {
              files.length < 10 &&
              (
                <Button
                  color="primary"
                  variant="outlined"
                  startIcon={<AttachFileIcon />}
                  style={{
                    borderRadius: 8,
                    backgroundColor: "white",
                    fontSize: "16px",
                    minWidth: "250px",
                    maxWidth: "400px",
                    marginTop: "20px",
                    marginLeft: "20px",
                  }}
                  onClick={() =>
                    setFiles([
                      ...files,
                      { id: makeid(16), type: "", file: null },
                    ])
                  }
                >
                  {t("ADD DOCUMENT")}
                </Button>
              )}


            <NoFileDialog
              fileName={files.length > 0}
              submitClicked={submitClicked}
              setSubmitClicked={setSubmitClicked}
            />
          </div>
        )}
        {!checkBoxBank && <Grid container direction="column" wrap="nowrap" p={1} spacing={1} mt={2}>
          <Grid item>

            {t("anything_else_to_add")}
          </Grid>


          <Grid item>
            <TextField
              value={other}
              onChange={(event) => setOther(event.target.value)}
              style={{ minWidth: "320px", maxWidth: "1000px" }}
              rows={4}
              multiline
              fullWidth
              variant="filled"
            /></Grid>
          <Grid item mt={2}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              type="submit"
              disabled={submitClicked}
              style={{ minWidth: "320px" }}
            >
              {!submitClicked && t("finalize")}
              {submitClicked && <CircularProgressWithLabel value={progress} />}
            </Button></Grid>
        </Grid>}
      </form>
      <MessagePopup state={error} setState={setError} message={error} />
      <IsExtraCompletedDialog extraInfoSubmitted={extraInfoSubmitted} nameAgent={nameAgent} emailAgent={emailAgent} />
    </div>
  );
};

export default ExtraInfo;
