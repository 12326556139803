import React from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";

const TooltipHelp = ({ json_flag }) => {
  const { t } = useTranslation();

  return (
    <Tooltip enterTouchDelay={0} title={t(json_flag)} placement="bottom">
      <HelpIcon fontSize={"small"} />
    </Tooltip>
  );
};

export default TooltipHelp;
