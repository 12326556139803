import React, { useState } from "react";

import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { getDroppedOrSelectedFiles } from "html5-file-selector";

import { useTranslation } from "react-i18next";

import { AttachFile, PictureAsPdf } from "@mui/icons-material";
import PhotoIcon from "@mui/icons-material/Photo";
import DeleteIcon from "@mui/icons-material/Delete";
import { Grid, Typography, IconButton } from "@mui/material";

const Input = ({ accept, onFiles, files, getFilesFromEvent }) => {
  const { t } = useTranslation();
  return (
    <Grid container justifyContent="center" alignItems="center" p={2} spacing={4}>
      <Grid item>
        <Typography
          color="primary"
          style={{ fontSize: 18, fontWeight: "bold" }}
        >
          {t("drag_and_drop_files_to_upload")}
        </Typography>
      </Grid>
      <Grid item>
        <label
          style={{
            color: "#fff",
            cursor: "pointer",
            padding: 15,
            borderRadius: 3,
          }}
        >
          <AttachFile color="primary" sx={{ fontSize: 80 }} />
          <input
            style={{ display: "none" }}
            type="file"
            accept={accept}
            multiple
            onChange={(e) => {
              getFilesFromEvent(e).then((chosenFiles) => {
                onFiles(chosenFiles);
              });
            }}
          />
        </label>
      </Grid>
    </Grid>
  );
};

const LayoutComponent = ({ previews, submitButton, dropzoneProps, files }) => {
  const [hoverFile, setHoverFile] = useState();

  function getIcon(type) {
    if (type.startsWith("image/")) {
      return <PhotoIcon />;
    }

    switch (type) {
      case "application/pdf":
        return <PictureAsPdf />;
      default:
        return <AttachFile />;
    }
  };

  return (
    <div {...dropzoneProps}>
      <Grid container direction="row" p={1} spacing={2}>
        {files.map((file) => (
          <Grid
            item
            xs={6}
            onMouseOver={() => setHoverFile(file.meta.name)}
            onMouseOut={() =>
              setHoverFile(hoverFile === file.meta.name ? null : hoverFile)
            }
          >
            <Grid
              container
              direction="column"
              wrap="nowrap"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <Grid
                  container
                  wrap="nowrap"
                  justifyContent="space-evenly"
                  spacing={4}
                  alignItems="center"
                >
                  {hoverFile === file.meta.name && <Grid item />}
                  <Grid item>
                    {getIcon(file.meta.type)}
                  </Grid>

                  {hoverFile === file.meta.name && (
                    <Grid item>
                      <IconButton
                        onClick={() =>
                          files
                            .find((f) => f.meta.name === file.meta.name)
                            .remove()
                        }
                        style={{ borderRadius: 0 }}
                      >
                        <DeleteIcon sx={{ color: "red" }} />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item>
                <Typography sx={{ fontSize: 12, fontWeight: "medium" }}>
                  {file.meta.name}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

const DropzoneReact = ({ files, setFiles, acceptedTypes = "*", maxFiles = 10 }) => {
  function handleFileAdded(fileWithMeta, status, currentFiles) {
    setFiles(currentFiles);
  }
  const getFilesFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
      });
    });
  };
  return (
    <Grid style={{ maxWidth: "600px" }}>
      <Dropzone
        onChangeStatus={handleFileAdded}
        styles={{ dropzone: { minHeight: 150, minWidth: 370, backgroundColor: "#FFFFFF" } }}
        InputComponent={Input}
        getFilesFromEvent={getFilesFromEvent}
        maxFiles={maxFiles}
        accept={acceptedTypes}
      />
    </Grid>
  );
};

export default DropzoneReact;
