import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
} from "@mui/material";

const MessagePopup = ({ state, setState, message }) => {
  return (
    <Dialog open={![null, undefined, false].includes(state)} onClose={() => setState(false)}>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>

        <Grid container justifyContent="center" mt={4}>
          <Button variant="contained" onClick={() => setState(false)}>
            OK
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default MessagePopup;
