import React from "react";
import { useTranslation } from "react-i18next";

import "./imagePreview.css";

import { Button, Grid } from "@mui/material";

export const ImagePreview = ({ file, setFile, isFullscreen, setInputFile }) => {
  const { t } = useTranslation();
  let classNameFullscreen = isFullscreen ? "demo-image-preview-fullscreen" : "";
  return (
    <div className={"demo-image-preview " + classNameFullscreen}>
      <img src={URL.createObjectURL(file)} alt="picture"/>
      <Grid
        container
        spacing={2}
        p={1}
        mt={1}
        direction="row"
        justifyContent="center"
      >
        <Grid item xs={6}>
          <Button variant="outlined" color="secondary" fullWidth onClick={() => setFile(null)}>
            {t("No, retake")}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button variant="contained" color="secondary" fullWidth onClick={() => setInputFile(file)}>
            {t("Yes, continue")}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default ImagePreview;
