import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import { Player } from "@lottiefiles/react-lottie-player";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import ProgressBar from "../components/ProgressBar";
import LoadingDataComponent from "../components/LoadingDataComponent";

const Completed = ({ url }) => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const properize_id = searchParams.get("properize_id");

  const [isTenantPending, setIsTenantPending] = useState(false);
  const [rows, setRows] = useState(null);
  const [alreadyRendered, setAlreadyRendered] = useState(false);

  const [nameAgent, setNameAgent] = useState("");
  const [emailAgent, setEmailAgent] = useState("");

  useEffect(() => {
    if (!properize_id || properize_id === "null") return setAlreadyRendered(true)
    const payload = {
      id: properize_id,
      properize_id: properize_id,
      database: "Tenants",
      location: window.location.href,
    };

    axios.post(url + "/get_data_from_airtable_table?step=completed", payload).then((response) => {
      setNameAgent(response.data['Name agent']);
      setEmailAgent(response.data['Email agent']);
    });

    axios.post(url + "/is_tenant_pending", payload).then((response) => {
      setRows(
        JSON.parse(response.data.tenantsTable).filter(
          (row) => row.status === "in progress"
        )
      );
      setIsTenantPending(response.data.isTenantPending);
      setAlreadyRendered(true);
    });
  }, []);

  const nameStyles = {
    fontWeight: "bold",
    fontSize: 16,
  };

  const emailStyles = {
    fontWeight: "bold",
    fontSize: 24,
  };

  return (
    <div>
      <ProgressBar completed={100} />
      <Grid container justifyContent="space-between" spacing={1}>
        <Grid item sm={6}>
          <Player
            autoplay
            loop
            src="https://assets9.lottiefiles.com/packages/lf20_01bh9ld2.json"
          ></Player>
        </Grid>
        <Grid item sm={6}>
          <br />
          <br />
          <Typography variant="h4" color="textSecondary">
            {t("completed")}!
          </Typography>
          <br />
          <br />
          <Typography variant="p" color="textSecondary">
            {t("completed_1")}
            <br />
          </Typography>

          {alreadyRendered && isTenantPending && (
            <Typography variant="p" color="textSecondary">
              <br />
              <br />
              {t("waiting_input_from_cotenants")}
              <br />
              <br />
              {rows.map((row) => (
                <li key={row.fullName}>{row.fullName}</li>
              ))}
            </Typography>
          )}
          <br />
          {alreadyRendered && !isTenantPending && properize_id !== "null" && (
            <Typography variant="p" color="textSecondary">
              {t("completed_all2")}
              <span style={nameStyles}>{nameAgent}</span> {t("completed_all3")}
              <br />
              <br />
              <span style={emailStyles}>{emailAgent}</span>
              <br />
            </Typography>
          )}
          <br />
          <br />
          <Typography variant="h6" color="textSecondary">
            {t("completed_all4")}
          </Typography>
        </Grid>
      </Grid>
      <LoadingDataComponent alreadyRendered={alreadyRendered} />
    </div>
  );
};

export default Completed;
