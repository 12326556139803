import { AppBar, Grid, Toolbar } from "@mui/material";

import LanguageSelection from "./LanguageSelection";
import { ReactComponent as ProperizeWhiteLogo } from "../svg_logos/properize-monochrome-white.svg";

const TopBar = ({ showLanguageSelection }) => {
  return (
    <AppBar position="static" color="primary">
      <Toolbar>
        <ProperizeWhiteLogo width="200" />
        <Grid container justifyContent="flex-end">
          {showLanguageSelection && <LanguageSelection />}
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
