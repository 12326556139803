import i18next from "i18next";
import React, { useState } from "react";
import { Grid, FormControl, MenuItem, Select } from "@mui/material";
import cookies from "js-cookie";

const languagesData = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "nl",
    name: "Nederlands",
    country_code: "nl",
  },
];

const LanguageSelection = () => {
  const navigatorLanguage =
    navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : navigator.language;
  let navigatorLanguageCode = navigatorLanguage.slice(0, 2);
  const languagesSupported = languagesData.map((language) => language.code);

  if (!languagesSupported.includes(navigatorLanguageCode)) {
    navigatorLanguageCode = "en";
  }

  const [language, setLanguage] = useState(
    cookies.get("i18next") || navigatorLanguageCode
  );

  function handleLanguageChange(event) {
    setLanguage(event.target.value);
    i18next.changeLanguage(event.target.value);
  }

  const languages = languagesData.map((language) => (
    <MenuItem key={language.country_code} value={language.code}>
      <Grid container direction="row" wrap="nowrap" spacing={1}>
        <Grid item>
          <span
            className={`flag-icon flag-icon-${language.country_code} mx-2`}
          ></span>
        </Grid>
        <Grid item>{language.name}</Grid>
      </Grid>
    </MenuItem>
  ));

  if (language == 'nl'){
    import('./futyScriptNL')
  } else {
    import('./futyScriptEN')
  }

  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <Select
          style={{ color: "black", backgroundColor: "#e7f3fc" }}
          color="secondary"
          value={language}
          onChange={handleLanguageChange}
        >
          {languages}
        </Select>
      </FormControl>
    </div>
  );
};

export default LanguageSelection;
