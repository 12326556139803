import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Player } from "@lottiefiles/react-lottie-player";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import axios from "axios";

const CancelRequest = ({ url }) => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const properize_id = searchParams.get("properize_id");
  const guarantor_id = searchParams.get("guarantor_id");

  const [areYouSureDialog, setAreYouSureDialog] = useState(true);
  const [requestCancelled, setRequestCancelled] = useState(false);

  function handleUpdateData() {
    let payload = {
      properize_id: properize_id,
      Status: "Failed",
      Log: "Request cancelled by tenant",
    };

    if (guarantor_id){
      payload['Log'] = 'Request cancelled by guarantor'
    }

    axios.post(url + "/update_single_record", payload);
    setRequestCancelled(true);
  }

  const AreYouSurePopUp = () => {
    return (
      <Dialog open={areYouSureDialog}>
        <DialogContent>
          <DialogContentText>
            {t("Are you sure?")} <br />
            <br />
            <Grid container justifyContent="space-between" spacing={0.5}>
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={() => setAreYouSureDialog(false)}
                >
                  {t("no")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleUpdateData();
                    setAreYouSureDialog(false);
                  }}
                >
                  {t("yes")}
                </Button>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <div>
      <br />
      {requestCancelled && (
        <Grid container justifyContent="space-between" spacing={1}>
          <Grid item sm={6}>
            <Player
              autoplay
              loop
              src="https://assets7.lottiefiles.com/private_files/lf30_2dlzxeim.json"
            ></Player>
          </Grid>
          <Grid item sm={6}>
            <br />
            <br />
            <Typography variant="h4" color="textSecondary">
              {t("Thank you")}
            </Typography>
            <br />
            <br />
            <Typography variant="h6" color="textSecondary">
              {t("You have cancelled your request successfully")}
            </Typography>
            <br />
            <br />
            <Typography variant="h6" color="textSecondary">
              {t("completed_all4")}
            </Typography>
          </Grid>
        </Grid>
      )}
      <AreYouSurePopUp />
    </div>
  );
};

export default CancelRequest;
