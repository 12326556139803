import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
} from "@mui/material";


const PopUpDialog = ( {nextClicked} ) => {
  return (
    <Dialog open={nextClicked}>
      <DialogContent>
        <DialogContentText>
          <div>
            <CircularProgress />
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default PopUpDialog;
