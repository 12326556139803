import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
} from "@mui/material";
import ProgressBar from "../components/ProgressBar";
import { useTranslation } from "react-i18next";

const Redirect = ({ url }) => {
  const [alreadyRendered, setAlreadyRendered] = useState(false);
  const [nextStepLink, setNextStepLink] = useState(false);

  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const properize_id = searchParams.get("properize_id");
  const errorFlag = searchParams.get("error") !== null;

  let payload = {
    id: properize_id,
    properize_id: properize_id,
    database: "Tenants",
  };

  if (errorFlag) {
    payload['result'] = searchParams.get("error")
  } else {
    payload['result'] = 'OK'
  }

  useEffect(() => {
    if (window.location.href.includes('amp;')) return window.location.href = window.location.href.replaceAll('amp;', '')
    axios.post(url + "/nordigen_redirect", payload)

    axios.post(url + "/get_data_from_airtable_table?step=openbanking_redirect", payload).then((response) => {
      if (["Failed", "In progress"].includes(response.data["Status"])) {
        setNextStepLink("optional_information")
      } else {
        setNextStepLink("completed")
      }
      setAlreadyRendered(true)
    });

  }, []);

  const ShowSuccess = () => {
    return (
      <Dialog open={alreadyRendered && !errorFlag}>
        <DialogContent>
          <DialogContentText>
            {t("transactions_received")}

          </DialogContentText>
          <Grid container justifyContent="center" mt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                window.location.assign(
                  "/" + nextStepLink + "?properize_id=" + properize_id
                )
              }
            >
              OK
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  const ShowError = () => {
    return (
      <Dialog open={alreadyRendered && errorFlag}>
        <DialogContent>
          <DialogContentText>
            {t("error_in_bank_screening")}
            <br />
            <br />
            <Grid container justifyContent="space-around">
              <Button
                variant="contained"
                color="primary"
                style={{ width: "47%" }}
                onClick={() =>
                  window.location.assign(
                    "/income_verification?properize_id=" + properize_id
                  )
                }
              >
                OK
              </Button>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <div>
      <ProgressBar completed={80} />
      <br />
      <ShowSuccess />
      <ShowError />
    </div>
  );
};

export default Redirect;
