import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import SelfReportedIncomeComponent from "../components/SelfReportedIncomeComponent";
import SelfReportedEmployerComponent from "../components/SelfReportedEmployerComponent";
import DropzoneReact from "../components/DropzoneReact";
import LoadingDataComponent from "../components/LoadingDataComponent";
import { uploadFilesToS3Directly } from "../utils/uploadToS3";
import unidecode from "unidecode"
import { useEffect } from "react";
import GuarantorDialog from "./GuarantorDialog";
import NoFileDialog from "./NoFileDialog";
import MessagePopup from "./MessagePopup";

function convertToDate(date) {
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${month < 10 ? `0${month}` : `${month}`}/${year}`;
}

function getDateXMonthsAgo(x) {
  // Get a date object for the current time
  var d = new Date();
  var m = d.getMonth() - x + 1;

  // Set it to x months ago
  d.setMonth(d.getMonth() - x);

  // If still in same month, set date to last day of previous month
  if (d.getMonth() === m) d.setDate(0);

  // Zero the time component
  d.setHours(0, 0, 0, 0);

  return d;
}

const month_1 = getDateXMonthsAgo(1);
const month_2 = getDateXMonthsAgo(2);
const month_3 = getDateXMonthsAgo(3);

const mandatoryFiles = {
  Work: [
    "Employment contract",
    "Recent employer's declaration",
    "Salary slip 1 (example: " + convertToDate(month_1) + ")",
    "Salary slip 2 (example: " + convertToDate(month_2) + ")",
    "Salary slip 3 (example: " + convertToDate(month_3) + ")",
  ],
  "Recently Changed Jobs": ["Recently signed employment contract"],
  "Changing Jobs": ["Recently signed employment contract"],
  "Self-employed": [
    "Certified Chamber of Commerce extract",
    "Most recent personal tax filing",
    "Most recent set of financial statements (example: " +
    String(new Date().getFullYear() - 1) +
    ")",
  ],
  Entrepreneur: [
    "Certified Chamber of Commerce extract",
    "Most recent personal tax filing",
    "Most recent set of financial statements (example: " +
    String(new Date().getFullYear() - 1) +
    ")",
  ],
  Study: ["Employment contract"],
  Retired: [],
  Subsidies: [],
  Savings: [],
  Other: [],
};

const optionalfilesToAdd = {
  Work: [],
  "Recently Changed Jobs": [
    "Salary slip 1 (example: " + convertToDate(month_1) + ")",
    "Salary slip 2 (example: " + convertToDate(month_2) + ")",
    "Salary slip 3 (example: " + convertToDate(month_3) + ")",
  ],
  "Changing Jobs": [],
  "Self-employed": [],
  Entrepreneur: [],
  Study: [
    "Salary slip 1 (example: " + convertToDate(month_1) + ")",
    "Salary slip 2 (example: " + convertToDate(month_2) + ")",
    "Salary slip 3 (example: " + convertToDate(month_3) + ")",
  ],
};

function replaceLabels(x, t) {
  x = x.replace("Salary slip", t("Salary slip"));
  x = x.replace("example", t("example"));
  x = x.replace(
    "Most recent set of financial statements",
    t("Most recent set of financial statements")
  );

  return x;
}

const MissingFilesDialog = ({
  missingFilesDialogOpen,
  setMissingFilesDialogOpen,
  missingFiles,
  setWhyNotFiles,
  handleSubmit,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={missingFilesDialogOpen}
      onClose={() => setMissingFilesDialogOpen(false)}
    >
      <DialogTitle>
        <Grid align="center" p={1}>
          {t("There are missing files title")}
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ maxWidth: "450px", marginTop: "20px" }}>
        <form onSubmit={handleSubmit}>
          <Typography>{t("There are missing files paragraphs")}:</Typography>
          <ul>
            {missingFiles.map((filename) => (
              <li key={filename}>{replaceLabels(filename, t)}</li>
            ))}
          </ul>
          <Grid mt={4}>
            <Typography>{t("why not uploading files")}</Typography>
            <TextField
              onChange={(event) => setWhyNotFiles(event.target.value)}
              style={{ maxWidth: "450px", marginTop: 10 }}
              fullWidth
              rows={4}
              multiline
              variant="filled"
              required
              InputLabelProps={{ required: false }}
            />
          </Grid>

          <Grid container direction="column" mt={7} spacing={1}>
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => setMissingFilesDialogOpen(false)}
                fullWidth
                size="large"
              >
                {t("cancel")}
              </Button>
            </Grid>
            <Grid item>
              <Button type="submit" variant="contained" fullWidth size="large">
                {t("submit")}
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const LowIncomeDialog = ({
  incomePopup,
  setIncomePopup,
  setIncomeConfirmed,
  selfReportedIncome,
  handleSubmit,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog open={incomePopup}>
      <DialogContent sx={{ maxWidth: "450px", marginTop: "20px" }}>
        <form onSubmit={handleSubmit}>
          <Typography>
            {t("You have self reported income")}
            {Math.round(Number(selfReportedIncome) + Number.EPSILON)}
            {t("You have self reported income 2")}
          </Typography>

          <Grid container direction="column" mt={7} spacing={1}>
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => setIncomePopup(false)}
                fullWidth
                size="large"
              >
                {t("cancel")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                fullWidth
                size="large"
                onClick={() => setIncomeConfirmed(true)}
              >
                {t("submit")}
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const WhereIsYourIncomeSourceBased = ({
  sectionCounter,
  whatToDo,
  incomeSourceWhere,
  setIncomeSourceWhere,
}) => {
  const { t } = useTranslation();

  let question;
  let local;
  let abroad;

  if (["Work", "Changing Jobs", "Recently Changed Jobs"].includes(whatToDo)) {
    question =
      whatToDo === "Changing Jobs"
        ? "Where is your next employer based?"
        : "Where is your employer based?";
    local = "My employer is based in the Netherlands";
    abroad =
      "I am working remotely for an employer based outside the Netherlands";
  } else if (["Self-employed", "Entrepreneur"].includes(whatToDo)) {
    question =
      "Is your business registered in the Dutch Chamber of Commerce (KvK)?";
    local = "yes";
    abroad = "No, my business is registered outside the Netherlands";
  }

  return (
    <Grid mt={5}>
      <Typography variant="p" color="black" fontWeight="bold">
        {sectionCounter}. {t(question)}
      </Typography>

      <RadioGroup
        sx={{ marginTop: 1 }}
        value={incomeSourceWhere}
        onChange={(event) => setIncomeSourceWhere(event.target.value)}
      >
        {null && (
          <FormControlLabel
            value=""
            control={<Radio required />}
            label="default"
          />
        )}

        <FormControlLabel
          value="Local"
          control={<Radio required />}
          label={t(local)}
        />

        <FormControlLabel
          value="Abroad"
          control={<Radio required />}
          label={t(abroad)}
        />
      </RadioGroup>
    </Grid>
  );
};

const StudyLoan = ({ sectionCounter, isLoan, setIsLoan, setLoanExp }) => {
  const { t } = useTranslation();

  return (
    <Grid mt={5}>
      <Typography variant="p" color="black" fontWeight="bold">
        {sectionCounter}. {t("Do you receive student loan")}
      </Typography>

      <RadioGroup
        sx={{ marginTop: 1 }}
        value={isLoan}
        onChange={(event) => setIsLoan(event.target.value)}
      >
        <FormControlLabel
          value="No"
          control={<Radio required />}
          label={t("no")}
        />
        <FormControlLabel
          value="Yes"
          control={<Radio required />}
          label={t("yes")}
        />
      </RadioGroup>

      {isLoan === "Yes" && (
        <div>
          <br />
          <TextField
            label={t("explain")}
            onChange={(event) => setLoanExp(event.target.value)}
            style={{ minWidth: "320px", maxWidth: "600px" }}
            fullWidth
            rows={4}
            multiline
            variant="filled"
            required
            InputLabelProps={{ required: false }}
          />
        </div>
      )}
    </Grid>
  );
};

const StudySideJob = ({ sectionCounter, isSideJob, setIsSideJob }) => {
  const { t } = useTranslation();

  return (
    <Grid mt={5}>
      <Typography variant="p" color="black" fontWeight="bold">
        {sectionCounter}. {t("Do you have a side-job")}
      </Typography>

      <RadioGroup
        sx={{ marginTop: 1 }}
        value={isSideJob}
        onChange={(event) => setIsSideJob(event.target.value)}
      >
        <FormControlLabel
          value="No"
          control={<Radio required />}
          label={t("no")}
        />
        <FormControlLabel
          value="Yes"
          control={<Radio required />}
          label={t("yes")}
        />
      </RadioGroup>
    </Grid>
  );
};

const StudyFinancialSupport = ({
  sectionCounter,
  properize_id,
  url,
  wantsGuarantor,
  setWantsGuarantor,
  guarantorName,
  guarantorLastname,
  guarantorRequestSent,
  isSupportLocal,
  setIsSupportLocal,
  setGuarantorName,
  setGuarantorLastname,
  setGuarantorEmail,
  setGuarantorRequestSent,
  guarantorIdFile,
  setGuarantorIdFile,
  guarantorFiles,
  setGuarantorFiles,
}) => {
  const { t } = useTranslation();

  const [financialSupport, setFinancialSupport] = useState("");

  useEffect(() => {
    let payload = {
      id: properize_id,
      database: "Tenants",
    };
    axios
      .post(url + "/get_data_from_airtable_table", payload)
      .then((response) => {
        payload = {
          properize_id: response.data.properize_id,
          database: "Guarantors",
        };
        axios
          .post(url + "/get_data_from_airtable_table_by_fields", payload)
          .then((response) => {
            if (response.data.length > 0) {
              setGuarantorName(response.data[0]["fields"]["First name"]);
              setGuarantorLastname(response.data[0]["fields"]["Last name"]);
              setGuarantorRequestSent(true);
            }
          });
      });
  }, []);

  return (
    <Grid mt={5}>
      {!guarantorRequestSent && (
        <Grid>
          <Typography variant="p" color="black" fontWeight="bold">
            {sectionCounter}. {t("Do you require financial support")}
          </Typography>
          <RadioGroup
            sx={{ marginTop: 1 }}
            value={financialSupport}
            onChange={(event) => setFinancialSupport(event.target.value)}
          >
            <FormControlLabel
              value="No"
              control={<Radio required />}
              label={t("no")}
            />
            <FormControlLabel
              value="Yes"
              control={<Radio required />}
              label={t("yes")}
            />
          </RadioGroup>
          {financialSupport === "Yes" && (
            <Grid mt={5}>
              <Typography variant="p" color="black" fontWeight="bold">
                {sectionCounter + 1}. {t("Is financial support local?")}
              </Typography>

              <RadioGroup
                sx={{ marginTop: 1 }}
                value={isSupportLocal}
                onChange={(event) => setIsSupportLocal(event.target.value)}
              >
                <FormControlLabel
                  value="No"
                  control={<Radio required />}
                  label={t(
                    "No, I receive financial support from outside the Netherlands"
                  )}
                />
                <FormControlLabel
                  value="Yes"
                  control={<Radio required />}
                  label={t("yes")}
                />
              </RadioGroup>
            </Grid>
          )}
          {financialSupport === "Yes" && isSupportLocal === "Yes" && (
            <Grid mt={5}>
              <Typography variant="p" color="black" fontWeight="bold">
                {sectionCounter + 2}. {t("Would you like to add a guarantor")}
              </Typography>

              <RadioGroup
                sx={{ marginTop: 1 }}
                value={wantsGuarantor}
                onChange={(event) => setWantsGuarantor(event.target.value)}
              >
                <FormControlLabel
                  value="sendEmailToGuarantor"
                  control={<Radio required />}
                  label={t("guarantor e-mail invitation")}
                />
                <FormControlLabel
                  value="uploadGuarantorDocs"
                  control={<Radio required />}
                  label={t("upload guarantor docs myself")}
                />
                <FormControlLabel
                  value="No"
                  control={<Radio required />}
                  label={t("I would not like to add a guarantor")}
                />
              </RadioGroup>
            </Grid>
          )}
          {financialSupport === "Yes" &&
            (isSupportLocal === "No" ||
              (isSupportLocal === "Yes" &&
                wantsGuarantor === "uploadGuarantorDocs")) && (
              <GuarantorFileUpload
                setGuarantorName={setGuarantorName}
                setGuarantorLastname={setGuarantorLastname}
                setGuarantorEmail={setGuarantorEmail}
                guarantorIdFile={guarantorIdFile}
                setGuarantorIdFile={setGuarantorIdFile}
                guarantorFiles={guarantorFiles}
                setGuarantorFiles={setGuarantorFiles}
              />
            )}
        </Grid>
      )}
      {guarantorRequestSent && (
        <Grid mt={2} ml={1}>
          <Typography>
            {t("guarantor_added_succesfully")}{" "}
            <strong>
              {guarantorName} {guarantorLastname}
            </strong>{" "}
            {t("guarantor_added_succesfully2")}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

const PensionLocalProvider = ({
  sectionCounter,
  whatToDo,
  incomeSourceWhere,
  setIncomeSourceWhere,
}) => {
  const { t } = useTranslation();

  return (
    <Grid mt={5}>
      <Typography variant="p" color="black" fontWeight="bold">
        {sectionCounter}.{" "}
        {whatToDo === "Retired" &&
          t("Do you receive your pension from a Dutch provider")}
        {whatToDo === "Subsidies" &&
          t("Do you receive your subsidy from a Dutch provider")}
      </Typography>

      <RadioGroup
        sx={{ marginTop: 1 }}
        value={incomeSourceWhere}
        onChange={(event) => setIncomeSourceWhere(event.target.value)}
      >
        <FormControlLabel
          value="Local"
          control={<Radio required />}
          label={t("yes")}
        />
        <FormControlLabel
          value="Abroad"
          control={<Radio required />}
          label={t(
            "No, I receive pension payments from outside the Netherlands"
          )}
        />
      </RadioGroup>
    </Grid>
  );
};

const FileUpload = ({
  sectionCounter,
  groupSettings,
  paragraph,
  startDate,
  whatToDo,
  files,
  setFiles,
  dropzoneFiles,
  setDropzoneFiles,
  setFilesToAskFinal,
  isSideJob,
  setMissingFiles,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [optionalFilenames, setOptionalFilenames] = useState([]);
  const [showDropzone, setShowDropZone] = useState(false);

  const oneYearAgo = new Date(
    new Date().setFullYear(new Date().getFullYear() - 1)
  );

  useEffect(() => {
    setFiles([]);
    setOptionalFilenames([]);
    if (["Retired", "Subsidies", "Savings", "Other"].includes(whatToDo)) {
      setShowDropZone(true);
    }
  }, [whatToDo]);

  let filesToAsk = { ...mandatoryFiles };

  function removeItemAll(arr, value) {
    var i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  // Change filesToAsk based on conditions
  if (
    !groupSettings.force_employment_contract &&
    startDate !== "" &&
    new Date(startDate) < oneYearAgo
  ) {
    filesToAsk["Work"] = removeItemAll(
      filesToAsk["Work"],
      "Employment contract"
    );
  }

  if (groupSettings.force_employer_declaration) {
    filesToAsk["Recently Changed Jobs"] = [
      ...filesToAsk["Recently Changed Jobs"],
      "Recent employer's declaration (less than 90 days old)",
    ];
  }

  useEffect(() => {
    setFilesToAskFinal(filesToAsk);
  }, [whatToDo]);

  useEffect(() => {
    if (whatToDo !== "Study" || isSideJob === "Yes") {
      const missingFilesStatus = filesToAsk[whatToDo].filter(
        (x) => !files.map((file) => file.id).includes(x)
      );
      setMissingFiles(missingFilesStatus);
    } else setMissingFiles([]);
  }, [files, isSideJob, whatToDo]);

  function addFile(event, id) {
    if (event.target.files[0].size > 1048576 * 20)
      return setError(t("This file is too big!"));

    if (event.target.files[0].type !== "application/pdf")
      return setError(t("Only PDF is admitted popup"));

    setFiles([
      ...files.filter((f) => f.id !== id),
      { id: id, file: event.target.files[0] },
    ]);
  }

  if (whatToDo === "Study" && isSideJob !== "Yes") return null;

  return (
    <Grid mt={5}>
      <Typography variant="p" color="black" fontWeight="bold">
        {sectionCounter}. {t(paragraph)}
      </Typography>

      <Alert
        severity="info"
        color="error"
        sx={{
          marginTop: 2,
        }}
      >
        <AlertTitle>{t("Please Read")}</AlertTitle>
        {t("only_pdf_is_allowed_10MB_no_pictures_accepted")}
      </Alert>

      {filesToAsk[whatToDo].map((document) => (
        <Grid
          container
          mt={1}
          ml={1}
          spacing={2}
          wrap="nowrap"
          alignItems="center"
          key={document}
        >
          <Grid item xs={6}>
            <Typography>{t(replaceLabels(document, t))}</Typography>
          </Grid>

          <Grid item xs={5}>
            <Button
              variant={
                files.find((f) => f.id === document) ? "outlined" : "contained"
              }
              component="label"
              color="primary"
              fullWidth
            >
              {!files.find((f) => f.id === document) && t("ATTACH PDF FILE")}
              {files.find((f) => f.id === document) &&
                files.find((f) => f.id === document).file.name}
              <input
                type="file"
                accept="application/pdf"
                hidden
                onChange={(event) => addFile(event, document)}
              />
            </Button>
          </Grid>
        </Grid>
      ))}

      {[
        "Work",
        "Changing Jobs",
        "Recently Changed Jobs",
        "Self-employed",
        "Entrepreneur",
        "Study",
      ].includes(whatToDo) &&
        optionalFilenames.map((document) => (
          <Grid
            container
            mt={1}
            ml={1}
            spacing={2}
            wrap="nowrap"
            alignItems="center"
            key={document}
          >
            <Grid item xs={6}>
              <Typography>{replaceLabels(document, t)}</Typography>
            </Grid>

            <Grid item xs={5}>
              <Button
                variant={
                  files.find((f) => f.id === document)
                    ? "outlined"
                    : "contained"
                }
                component="label"
                color="primary"
                fullWidth
              >
                {!files.find((f) => f.id === document) && t("ATTACH PDF FILE")}
                {files.find((f) => f.id === document) &&
                  files.find((f) => f.id === document).file.name}
                <input
                  type="file"
                  accept="application/pdf"
                  hidden
                  onChange={(event) => addFile(event, document)}
                />
              </Button>
            </Grid>

            {optionalFilenames.at(-1) === document && (
              <Grid item xs={1}>
                <IconButton
                  onClick={() => {
                    setFiles([...files.filter((f) => f.id !== document)]);
                    setOptionalFilenames([...optionalFilenames.slice(0, -1)]);
                  }}
                  style={{ borderRadius: 0 }}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
        ))}

      {whatToDo === "Changing Jobs" && (
        <Grid mx={2} mt={5}>
          <Typography>
            {t("If you wish you can add additional documents")}
          </Typography>
        </Grid>
      )}
      {["Recently Changed Jobs", "Study"].includes(whatToDo) &&
        optionalfilesToAdd[whatToDo].length > 0 &&
        optionalfilesToAdd[whatToDo].length > optionalFilenames.length && (
          <Button
            color="primary"
            variant="outlined"
            startIcon={<AttachFileIcon />}
            style={{
              borderRadius: 8,
              backgroundColor: "white",
              fontSize: "16px",
              minWidth: "250px",
              maxWidth: "400px",
              marginTop: "20px",
              marginLeft: "20px",
            }}
            onClick={() =>
              setOptionalFilenames([
                ...optionalFilenames,
                optionalfilesToAdd[whatToDo][optionalFilenames.length],
              ])
            }
          >
            {t("ADD SALARY SLIP")}
          </Button>
        )}

      {whatToDo === "Changing Jobs" && !showDropzone && (
        <Button
          color="primary"
          variant="outlined"
          startIcon={<AttachFileIcon />}
          style={{
            borderRadius: 8,
            backgroundColor: "white",
            fontSize: "16px",
            minWidth: "250px",
            maxWidth: "400px",
            marginTop: "20px",
            marginLeft: "20px",
          }}
          onClick={() => setShowDropZone(true)}
        >
          {t("ADD SUPPORTING DOCUMENTS")}
        </Button>
      )}

      {showDropzone && (
        <Grid my={3} ml={1}>
          <DropzoneReact files={dropzoneFiles} setFiles={setDropzoneFiles} acceptedTypes="application/pdf" />
        </Grid>
      )}

      {whatToDo === "Retired" && (
        <Grid item m={1} mt={4}>
          <Typography>{t("Examples of retirement documents")}</Typography>
        </Grid>
      )}
      {whatToDo === "Subsidies" && (
        <Grid item m={1} mt={4}>
          <Typography>{t("Examples of subsidy documents")}</Typography>
        </Grid>
      )}
      <MessagePopup state={error} setState={setError} message={error} />
    </Grid>
  );
};

const GuarantorFileUpload = ({
  guarantorIdFile,
  setGuarantorIdFile,
  guarantorFiles,
  setGuarantorFiles,
  setGuarantorName,
  setGuarantorLastname,
  setGuarantorEmail,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState(false);

  return (
    <Grid mt={3}>
      <Typography>{t("Write your guarantor contact details")}</Typography>

      <Grid container style={{ maxWidth: "415px" }}>
        <Grid item xs={12} md={6} p={1}>
          <TextField
            onChange={(event) => setGuarantorName(event.target.value)}
            label={t("firstname")}
            variant="standard"
            InputLabelProps={{ required: false }}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6} p={1}>
          <TextField
            onChange={(event) => setGuarantorLastname(event.target.value)}
            label={t("lastname")}
            variant="standard"
            InputLabelProps={{ required: false }}
            required
            fullWidth
          />
        </Grid>
      </Grid>

      <Grid p={1}>
        <TextField
          onChange={(event) => setGuarantorEmail(event.target.value)}
          label={t("email")}
          type="email"
          variant="standard"
          InputLabelProps={{ required: false }}
          style={{ maxWidth: "400px" }}
          fullWidth
          required
        />
      </Grid>

      {["Guarantor's ID document"].map((document) => (
        <Grid
          container
          my={2}
          spacing={2}
          wrap="nowrap"
          alignItems="center"
          key={document}
        >
          <Grid item xs={6}>
            <Typography>{t(document)}</Typography>
          </Grid>

          <Grid item xs={5}>
            <Button
              variant={guarantorIdFile ? "outlined" : "contained"}
              component="label"
              color="primary"
              fullWidth
            >
              {!guarantorIdFile && t("ATTACH FILE")}
              {guarantorIdFile && guarantorIdFile.name}
              <input
                type="file"
                accept="image/*, application/pdf"
                hidden
                onChange={(event) =>
                  event.target.files[0].size > 1048576 * 20
                    ? setError(t("This file is too big!"))
                    : setGuarantorIdFile(event.target.files[0])
                }
              />
            </Button>
          </Grid>
        </Grid>
      ))}

      <Grid my={3}>
        <Typography>
          {t("Provide proof of income of your guarantor")}
        </Typography>
      </Grid>

      <DropzoneReact files={guarantorFiles} setFiles={setGuarantorFiles} acceptedTypes="application/pdf" />

      <MessagePopup state={error} setState={setError} message={error} />
    </Grid>
  );
};

const WhatIsKvKNumber = ({ sectionCounter, kvkNumber, setKvkNumber }) => {
  const { t } = useTranslation();
  return (
    <Grid mt={5}>
      <Typography variant="p" color="black" fontWeight="bold">
        {sectionCounter}.{" "}
        {t("What is the company registration number (KvK number)?")}
      </Typography>
      <br />
      <TextField
        label={t("KvK number")}
        value={kvkNumber}
        onChange={(event) => setKvkNumber(event.target.value)}
        variant="standard"
        style={{ maxWidth: "300px", margin: "5px" }}
        InputLabelProps={{ required: false }}
        required
      />
    </Grid>
  );
};

const SelfEmployedFixedAssignment = ({
  sectionCounter,
  supportingDocs,
  setSupportingDocs,
  hasFixedAssignment,
  sethasFixedAssigment,
}) => {
  const { t } = useTranslation();

  return (
    <Grid mt={5}>
      <Typography variant="p" color="black" fontWeight="bold">
        {sectionCounter}. {t("Do you have a fixed assignment")}
      </Typography>

      <RadioGroup
        sx={{ marginTop: 1 }}
        onChange={(event) => sethasFixedAssigment(event.target.value)}
      >
        <FormControlLabel
          value={"Yes"}
          control={<Radio required />}
          label={t("Yes, I am usually working for the same client")}
        />
        <FormControlLabel
          value={"No"}
          control={<Radio required />}
          label={t("No, I work for many different clients")}
        />
      </RadioGroup>

      {hasFixedAssignment === "Yes" && (
        <div style={{ margin: 10, marginTop: 20 }}>
          <Typography variant="p" color="black">
            {t("Documents for this business relationship")}
          </Typography>
          <br />
          <br />

          <DropzoneReact files={supportingDocs} setFiles={setSupportingDocs} acceptedTypes="application/pdf" />
        </div>
      )}
    </Grid>
  );
};

const WealthSourceExp = ({
  sectionCounter,
  whatToDo,
  setSourceOfWealthExp,
}) => {
  const { t } = useTranslation();
  return (
    <Grid mt={5}>
      <Typography variant="p" color="black" fontWeight="bold">
        {sectionCounter + 1}.{" "}
        {whatToDo === "Entrepreneur" &&
          t("Explanation of your business activities")}
        {["Savings", "Other"].includes(whatToDo) &&
          t("Explanation of your wealth source")}
      </Typography>
      <br />
      <TextField
        onChange={(event) => setSourceOfWealthExp(event.target.value)}
        style={{ minWidth: "320px", maxWidth: "600px", margin: "20px" }}
        fullWidth
        rows={4}
        multiline
        variant="filled"
        required
      />
    </Grid>
  );
};

const TotalLiquidAssets = ({
  sectionCounter,
  liquidAssetsValue,
  setLiquidAssetsValue,
}) => {
  const { t } = useTranslation();
  return (
    <Grid mt={5}>
      <Typography variant="p" color="black" fontWeight="bold">
        {sectionCounter}. {t("What is the value of liquid assets")}
      </Typography>
      <br />
      <TextField
        label={t("Total liquid assets")}
        value={liquidAssetsValue}
        onChange={(event) => setLiquidAssetsValue(event.target.value)}
        variant="standard"
        type="number"
        style={{ maxWidth: "300px", margin: "5px" }}
        InputLabelProps={{ required: false }}
        required
      />
    </Grid>
  );
};

const TimeoutDialog = ({ isTimeoutDialogOpen, setIsTimeoutDialogOpen }) => {
  const { t } = useTranslation();
  return (
    <Dialog open={isTimeoutDialogOpen}>
      <DialogTitle align="center">
        <Grid p={1}>{t("Connection timeout")}</Grid>
      </DialogTitle>
      <DialogContent>
        <Grid sx={{ maxWidth: "350px" }}>
          {t("Connection timeout paragraph")}
        </Grid>
        <Grid mt={10}>
          <Button
            color="secondary"
            variant="contained"
            fullWidth
            size="large"
            onClick={() => setIsTimeoutDialogOpen(false)}
          >
            OK
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const SubmitEmploymentProof = ({
  properize_id,
  url,
  whatToDo,
  tenant,
  groupSettings,
}) => {
  const { t } = useTranslation();

  const [incomeSourceWhere, setIncomeSourceWhere] = useState("");

  const [selfReportedIncome, setSelfReportedIncome] = useState("");
  const [selfReportedEmployer, setSelfReportedEmployer] = useState("");
  const [contractType, setContractType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [otherContract, setOtherContract] = useState("");
  const [frequency, setFrequency] = useState("Monthly");

  const [kvkNumber, setKvkNumber] = useState("");
  const [sourceOfWealthExp, setSourceOfWealthExp] = useState("");

  const [isLoan, setIsLoan] = useState("");
  const [loanExp, setLoanExp] = useState("");
  const [isSideJob, setIsSideJob] = useState(false);
  const [wantsGuarantor, setWantsGuarantor] = useState("");
  const [isSupportLocal, setIsSupportLocal] = useState("");
  const [guarantorName, setGuarantorName] = useState("");
  const [guarantorLastname, setGuarantorLastname] = useState("");
  const [guarantorEmail, setGuarantorEmail] = useState("");
  const [guarantorRequestSent, setGuarantorRequestSent] = useState(false);

  const [liquidAssetsValue, setLiquidAssetsValue] = useState("");

  const [files, setFiles] = useState([]);
  const [filesToAskFinal, setFilesToAskFinal] = useState([]);
  const [guarantorIdFile, setGuarantorIdFile] = useState(null);
  const [guarantorFiles, setGuarantorFiles] = useState([]);

  const [doNotHaveAllCheckbox, setDoNotHaveAllCheckbox] = useState(false);
  const [missingFiles, setMissingFiles] = useState([]);
  const [missingFilesDialogOpen, setMissingFilesDialogOpen] = useState(false);
  const [whyNotFiles, setWhyNotFiles] = useState("");
  const [dropzoneFiles, setDropzoneFiles] = useState([]);
  const [checkBoxFileOther, setCheckBoxFileOther] = useState(false);

  const [hasFixedAssignment, sethasFixedAssigment] = useState(null);
  const [supportingDocsSelfEmployed, setSupportingDocsSelfEmployed] = useState(
    []
  );

  const [progress, setProgress] = useState(0);

  const [submitClicked, setSubmitClicked] = useState(false);
  const [noFileDialogOpen, setNoFileDialogOpen] = useState(false);
  const [incomePopup, setIncomePopup] = useState(false);
  const [incomeConfirmed, setIncomeConfirmed] = useState(false);

  const [isTimeoutDialogOpen, setIsTimeoutDialogOpen] = useState(false);

  let sectionCounter = 0;
  let paragraph = "";
  let employerParagraph = "";

  if (whatToDo === "Work") {
    paragraph = "proof_of_employment";
    employerParagraph = "employer_paragraph";
  } else if (whatToDo === "Recently Changed Jobs") {
    paragraph = "proof_of_employment";
    employerParagraph = "employer_paragraph";
  } else if (whatToDo === "Changing Jobs") {
    paragraph = "proof_of_employment";
    employerParagraph = "employer_changing_jobs_paragraph";
  } else if (whatToDo === "Self-employed") {
    paragraph = "proof_of_income";
    employerParagraph = "selfemployed_paragraph";
  } else if (whatToDo === "Entrepreneur") {
    paragraph = "proof_of_income";
    employerParagraph = "entrepreneur_paragraph";
  } else if (whatToDo === "Study") {
    paragraph = "proof_of_employment";
    employerParagraph = "employer_paragraph";
  } else if (whatToDo === "Retired") {
    paragraph = "proof_of_retirement";
  } else if (whatToDo === "Subsidies") {
    paragraph = "proof_of_subsidy";
  } else if (whatToDo === "Savings") {
    paragraph = "proof_of_financial_situation_savings";
  } else if (whatToDo === "Other") {
    paragraph = "proof_of_financial_situation";
  }

  useEffect(() => {
    if (whatToDo === "Work" && tenant["Flow type"] === "Expat") {
      setIncomeSourceWhere("Abroad");
    }
  }, [whatToDo]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setNoFileDialogOpen(false);
    setSubmitClicked(true);
    setIncomePopup(false);

    if (
      selfReportedIncome !== "" &&
      Number(selfReportedIncome) >= 0 &&
      Number(selfReportedIncome) < 100 &&
      !incomeConfirmed
    ) {
      setSubmitClicked(false);
      return setIncomePopup(true);
    }

    if ((whatToDo !== "Study" || isSideJob === "Yes") && filesToAskFinal[whatToDo]) {
      const missingFilesStatus = filesToAskFinal[whatToDo].filter(
        (x) => !files.map((file) => file.id).includes(x)
      );

      if (missingFilesStatus.length > 0 && !whyNotFiles) {
        setSubmitClicked(false);
        return setMissingFilesDialogOpen(true);
      }
    }

    if (
      ["Retired", "Subsidies", "Savings"].includes(whatToDo) &&
      dropzoneFiles.length === 0
    )
      return setNoFileDialogOpen(true);

    const payload = {
      properize_id: properize_id,
      self_reported_income: selfReportedIncome,
      self_reported_employer: selfReportedEmployer,
      what_to_do: whatToDo,
      kvk_number: kvkNumber,
      end_date: endDate,
      frequency: frequency,
      employer_docs_filenames: files
        .filter((file) => !file.id.includes("Salary slip"))
        .concat(supportingDocsSelfEmployed)
        .map((file) =>
          unidecode(file.file.name.replaceAll(" ", "_").replaceAll(",", "_"))
        ),
      payslips_docs_filenames: files
        .filter((file) => file.id.includes("Salary slip"))
        .map((file) =>
          unidecode(file.file.name.replaceAll(" ", "_").replaceAll(",", "_"))
        ),
      miscellaneous_filenames: dropzoneFiles.map((file) =>
        unidecode(file.file.name.replaceAll(" ", "_").replaceAll(",", "_"))
      ),
      file_ids_array: files
        .filter((file) => file.id !== null)
        .map((file) => file.id),
      guarantor_first_name: guarantorName,
      guarantor_last_name: guarantorLastname,
      guarantor_email: guarantorEmail,
      guarantor_identity_doc: guarantorIdFile
        ? guarantorIdFile.name.replaceAll(" ", "_").replaceAll(",", "_")
        : null,
      guarantor_proof_of_income_docs: guarantorFiles.map((file) =>
        unidecode(file.file.name.replaceAll(" ", "_").replaceAll(",", "_"))
      ),
      income_source_where: incomeSourceWhere,
      source_of_wealth_explanation: sourceOfWealthExp,
      student_is_loan: isLoan === "Yes",
      loan_explanation: loanExp,
      student_has_sidejob: isSideJob === "Yes",
      is_guarantor_from_abroad: isSupportLocal === "No",
      self_employed_has_fixed_assignment: hasFixedAssignment === "Yes",
      liquid_assets_value: liquidAssetsValue,
      start_date: startDate,
      contract_type: contractType,
      other_contract: otherContract,
      why_not_files: whyNotFiles,
    };

    axios.post(url + "/personal_situation_json", payload)

    const timeoutId = setTimeout(() => {
      setSubmitClicked(false);
      setIsTimeoutDialogOpen(true);
    }, 40000);

    await uploadFilesToS3Directly(
      properize_id,
      files.filter((file) => !file.id.includes("Salary slip")),
      "properize-employer-docs",
      setProgress
    );

    await uploadFilesToS3Directly(
      properize_id,
      supportingDocsSelfEmployed,
      "properize-employer-docs",
      setProgress
    );

    await uploadFilesToS3Directly(
      properize_id,
      files.filter((file) => file.id.includes("Salary slip")),
      "properize-payslips",
      setProgress
    );

    await uploadFilesToS3Directly(
      properize_id,
      dropzoneFiles,
      "properize-miscellaneous",
      setProgress
    );

    if (guarantorIdFile)
      await uploadFilesToS3Directly(
        "prop_id_" + properize_id,
        [{ file: guarantorIdFile }],
        "properize-guarantor-docs",
        setProgress
      );

    await uploadFilesToS3Directly(
      "prop_id_" + properize_id,
      guarantorFiles,
      "properize-guarantor-docs",
      setProgress
    );

    clearTimeout(timeoutId)

    axios
      .post(url + "/personal_situation_pipeline", payload, { timeout: 7000 })
      .then((response) => {
        window.location.assign(
          "/additional_questions?properize_id=" + properize_id
        );
      })
      .catch((err) => {
        window.location.assign(
          "/additional_questions?properize_id=" + properize_id
        );
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {whatToDo === "Work" && tenant["Flow type"] !== "Expat" && (
          <div>
            {(sectionCounter = sectionCounter + 1) && null}
            <WhereIsYourIncomeSourceBased
              sectionCounter={sectionCounter}
              whatToDo={whatToDo}
              incomeSourceWhere={incomeSourceWhere}
              setIncomeSourceWhere={setIncomeSourceWhere}
            />
          </div>
        )}
        {["Changing Jobs", "Recently Changed Jobs"].includes(whatToDo) && (
          <div>
            {(sectionCounter = sectionCounter + 1) && null}
            <WhereIsYourIncomeSourceBased
              sectionCounter={sectionCounter}
              whatToDo={whatToDo}
              incomeSourceWhere={incomeSourceWhere}
              setIncomeSourceWhere={setIncomeSourceWhere}
            />
          </div>
        )}
        {["Work", "Changing Jobs", "Recently Changed Jobs", "Other"].includes(
          whatToDo
        ) && (
            <div>
              {(sectionCounter = sectionCounter + 1) && null}
              <SelfReportedIncomeComponent
                sectionCounter={sectionCounter}
                selfReportedIncome={selfReportedIncome}
                setSelfReportedIncome={setSelfReportedIncome}
                whatToDo={whatToDo}
                frequency={frequency}
                setFrequency={setFrequency}
              />
            </div>
          )}

        {[
          "Work",
          "Changing Jobs",
          "Recently Changed Jobs",
          "Self-employed",
          "Entrepreneur",
        ].includes(whatToDo) && (
            <div>
              {(sectionCounter = sectionCounter + 1) && null}
              <SelfReportedEmployerComponent
                sectionCounter={sectionCounter}
                setSelfReportedEmployer={setSelfReportedEmployer}
                contractType={contractType}
                setContractType={setContractType}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                employerParagraph={employerParagraph}
                whatToDo={whatToDo}
                setOtherContract={setOtherContract}
              />
            </div>
          )}

        {["Self-employed", "Entrepreneur"].includes(whatToDo) && (
          <div>
            {(sectionCounter = sectionCounter + 1) && null}
            <WhereIsYourIncomeSourceBased
              sectionCounter={sectionCounter}
              whatToDo={whatToDo}
              incomeSourceWhere={incomeSourceWhere}
              setIncomeSourceWhere={setIncomeSourceWhere}
            />
          </div>
        )}

        {["Self-employed", "Entrepreneur"].includes(whatToDo) &&
          incomeSourceWhere === "Local" && (
            <div>
              {(sectionCounter = sectionCounter + 1) && null}
              <WhatIsKvKNumber
                sectionCounter={sectionCounter}
                kvkNumber={kvkNumber}
                setKvkNumber={setKvkNumber}
              />
            </div>
          )}

        {["Self-employed", "Entrepreneur", "Retired", "Subsidies"].includes(
          whatToDo
        ) && (
            <div>
              {(sectionCounter = sectionCounter + 1) && null}
              <SelfReportedIncomeComponent
                sectionCounter={sectionCounter}
                selfReportedIncome={selfReportedIncome}
                setSelfReportedIncome={setSelfReportedIncome}
                whatToDo={whatToDo}
                frequency={frequency}
                setFrequency={setFrequency}
                typeOfSalary={"gross"}
              />
            </div>
          )}

        {["Retired", "Subsidies"].includes(whatToDo) && (
          <div>
            {(sectionCounter = sectionCounter + 1) && null}
            <PensionLocalProvider
              sectionCounter={sectionCounter}
              whatToDo={whatToDo}
              incomeSourceWhere={incomeSourceWhere}
              setIncomeSourceWhere={setIncomeSourceWhere}
            />
          </div>
        )}

        {[
          "Work",
          "Changing Jobs",
          "Recently Changed Jobs",
          "Self-employed",
          "Entrepreneur",
          "Retired",
          "Subsidies",
          "Savings",
        ].includes(whatToDo) && (
            <div>
              {(sectionCounter = sectionCounter + 1) && null}
              <FileUpload
                sectionCounter={sectionCounter}
                groupSettings={groupSettings}
                paragraph={paragraph}
                startDate={startDate}
                whatToDo={whatToDo}
                files={files}
                setFiles={setFiles}
                dropzoneFiles={dropzoneFiles}
                setDropzoneFiles={setDropzoneFiles}
                setFilesToAskFinal={setFilesToAskFinal}
                isSideJob={isSideJob}
                setMissingFiles={setMissingFiles}
              />
            </div>
          )}

        {[
          "Work",
          "Changing Jobs",
          "Recently Changed Jobs",
          "Self-employed",
          "Entrepreneur",
        ].includes(whatToDo) && files.length > 0 && missingFiles.length > 0 && (
            <Grid ml={2} mt={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={doNotHaveAllCheckbox}
                    onChange={() =>
                      setDoNotHaveAllCheckbox(!doNotHaveAllCheckbox)
                    }
                  />
                }
                label={t("I do not have all documents")}
              />
            </Grid>
          )}

        {whatToDo === "Savings" && (
          <div>
            {(sectionCounter = sectionCounter + 1) && null}
            <TotalLiquidAssets
              sectionCounter={sectionCounter}
              liquidAssetsValue={liquidAssetsValue}
              setLiquidAssetsValue={setLiquidAssetsValue}
            />
          </div>
        )}

        {["Entrepreneur", "Savings", "Other"].includes(whatToDo) && (
          <WealthSourceExp
            sectionCounter={sectionCounter}
            whatToDo={whatToDo}
            setSourceOfWealthExp={setSourceOfWealthExp}
          />
        )}

        {whatToDo === "Other" && !checkBoxFileOther && (
          <Grid m={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkBoxFileOther}
                  onChange={() => setCheckBoxFileOther(!checkBoxFileOther)}
                />
              }
              label={t("upload_miscellaneous")}
            />
          </Grid>
        )}

        {whatToDo === "Other" && checkBoxFileOther && (
          <FileUpload
            sectionCounter={sectionCounter + 2}
            groupSettings={groupSettings}
            paragraph={paragraph}
            startDate={startDate}
            whatToDo={whatToDo}
            files={files}
            setFiles={setFiles}
            dropzoneFiles={dropzoneFiles}
            setDropzoneFiles={setDropzoneFiles}
            setFilesToAskFinal={setFilesToAskFinal}
            isSideJob={isSideJob}
            setMissingFiles={setMissingFiles}
          />
        )}

        {whatToDo === "Study" && (
          <div>
            {(sectionCounter = sectionCounter + 1) && null}
            <StudyLoan
              sectionCounter={sectionCounter}
              isLoan={isLoan}
              setIsLoan={setIsLoan}
              setLoanExp={setLoanExp}
            />
            {(sectionCounter = sectionCounter + 1) && null}
            <StudySideJob
              sectionCounter={sectionCounter}
              isSideJob={isSideJob}
              setIsSideJob={setIsSideJob}
            />
          </div>
        )}

        {whatToDo === "Study" && (
          <div>
            {(sectionCounter =
              isSideJob === "Yes" ? sectionCounter + 1 : sectionCounter) &&
              null}

            {isSideJob === "Yes" && <SelfReportedIncomeComponent
              sectionCounter={sectionCounter}
              selfReportedIncome={selfReportedIncome}
              setSelfReportedIncome={setSelfReportedIncome}
              whatToDo={whatToDo}
              frequency={frequency}
              setFrequency={setFrequency}
            />}

            {(sectionCounter =
              isSideJob === "Yes" ? sectionCounter + 1 : sectionCounter) &&
              null}

            {isSideJob === "Yes" && <SelfReportedEmployerComponent
              sectionCounter={sectionCounter}
              setSelfReportedEmployer={setSelfReportedEmployer}
              contractType={contractType}
              setContractType={setContractType}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              employerParagraph={employerParagraph}
              whatToDo={whatToDo}
              setOtherContract={setOtherContract}
            />}

            <FileUpload
              sectionCounter={sectionCounter + 1}
              groupSettings={groupSettings}
              paragraph={paragraph}
              startDate={startDate}
              whatToDo={whatToDo}
              files={files}
              setFiles={setFiles}
              dropzoneFiles={dropzoneFiles}
              setDropzoneFiles={setDropzoneFiles}
              setFilesToAskFinal={setFilesToAskFinal}
              isSideJob={isSideJob}
              setMissingFiles={setMissingFiles}
            />
          </div>
        )}

        {whatToDo === "Study" && isSideJob === "Yes" && files.length > 0 && missingFiles.length > 0 && (
          <Grid ml={2} mt={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={doNotHaveAllCheckbox}
                  onChange={() =>
                    setDoNotHaveAllCheckbox(!doNotHaveAllCheckbox)
                  }
                />
              }
              label={t("I do not have all documents")}
            />
          </Grid>
        )}

        {whatToDo === "Study" && (
          <div>
            {(sectionCounter = sectionCounter + 1) && null}
            <StudyFinancialSupport
              sectionCounter={sectionCounter}
              properize_id={properize_id}
              url={url}
              wantsGuarantor={wantsGuarantor}
              setWantsGuarantor={setWantsGuarantor}
              guarantorName={guarantorName}
              guarantorLastname={guarantorLastname}
              guarantorRequestSent={guarantorRequestSent}
              isSupportLocal={isSupportLocal}
              setIsSupportLocal={setIsSupportLocal}
              setGuarantorName={setGuarantorName}
              setGuarantorLastname={setGuarantorLastname}
              setGuarantorEmail={setGuarantorEmail}
              setGuarantorRequestSent={setGuarantorRequestSent}
              guarantorIdFile={guarantorIdFile}
              setGuarantorIdFile={setGuarantorIdFile}
              guarantorFiles={guarantorFiles}
              setGuarantorFiles={setGuarantorFiles}
            />
          </div>
        )}

        {whatToDo === "Self-employed" && (
          <div>
            {(sectionCounter = sectionCounter + 1) && null}
            <SelfEmployedFixedAssignment
              sectionCounter={sectionCounter}
              supportingDocs={supportingDocsSelfEmployed}
              setSupportingDocs={setSupportingDocsSelfEmployed}
              hasFixedAssignment={hasFixedAssignment}
              sethasFixedAssigment={sethasFixedAssigment}
            />
          </div>
        )}

        {whatToDo && (
          <Button
            variant="contained"
            color="secondary"
            size="large"
            type="submit"
            disabled={
              submitClicked ||
              (!doNotHaveAllCheckbox && missingFiles.length > 0)
            }
            sx={{ m: 2, mt: 7, minWidth: 350 }}
          >
            {t("Submit")}
          </Button>
        )}
        <LoadingDataComponent alreadyRendered={!submitClicked} />
      </form>

      <MissingFilesDialog
        missingFilesDialogOpen={missingFilesDialogOpen}
        setMissingFilesDialogOpen={setMissingFilesDialogOpen}
        missingFiles={missingFiles}
        setWhyNotFiles={setWhyNotFiles}
        handleSubmit={handleSubmit}
      />

      <LowIncomeDialog
        incomePopup={incomePopup}
        setIncomePopup={setIncomePopup}
        setIncomeConfirmed={setIncomeConfirmed}
        selfReportedIncome={selfReportedIncome}
        handleSubmit={handleSubmit}
      />

      <GuarantorDialog
        properize_id={properize_id}
        url={url}
        guarantorDialogOpen={wantsGuarantor === "sendEmailToGuarantor"}
        setGuarantorDialogOpen={setWantsGuarantor}
        guarantorName={guarantorName}
        setGuarantorName={setGuarantorName}
        guarantorLastname={guarantorLastname}
        setGuarantorLastname={setGuarantorLastname}
        setGuarantorRequestSent={setGuarantorRequestSent}
      />

      <NoFileDialog
        fileName={!noFileDialogOpen}
        submitClicked={submitClicked}
        setSubmitClicked={setSubmitClicked}
      />

      <TimeoutDialog
        isTimeoutDialogOpen={isTimeoutDialogOpen}
        setIsTimeoutDialogOpen={setIsTimeoutDialogOpen}
      />
    </div>
  );
};

export default SubmitEmploymentProof;
