import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  Link,
  Typography,
} from "@mui/material";

import SkipNextIcon from "@mui/icons-material/SkipNext";
import cookies from "js-cookie";

import { useLocation, useSearchParams } from "react-router-dom";
import axios from "axios";

import LoadingDataComponent from "../components/LoadingDataComponent";
import ProgressBar from "../components/ProgressBar";

import { Player } from "@lottiefiles/react-lottie-player";

const GuarantorWelcomePage = ({ setShowLanguageSelection, url }) => {
  const { t } = useTranslation();
  const { search } = useLocation();

  const [isFlowCompleted, setIsFlowCompleted] = useState(false);

  const [firstName, setFirstName] = useState(false);

  const [alreadyRendered, setAlreadyRendered] = useState(false);

  const [searchParams] = useSearchParams();
  const guarantor_id = searchParams.get("guarantor_id");

  setShowLanguageSelection(true);

  const currentLanguage = cookies.get("i18next");
  let endpoint = "tenant-information";

  if (currentLanguage === "nl") {
    endpoint = "huurders";
  } else {
    endpoint = "tenant-information";
  }

  const payload = {
    guarantor_id: guarantor_id,
  };

  useEffect(() => {
    axios.post(url + "/get_guarantor_data_from_airtable", payload).then((response) => {
      setFirstName(response.data.first_name);
      setIsFlowCompleted(response.data.isFlowCompleted);
      setAlreadyRendered(true);   
    });
  }, []);

  const IsFlowCompletedDialog = () => {
    return (
      <Dialog open={isFlowCompleted}>
        <DialogContent>
          <DialogContentText>
            {t("you_have_completed_the_process")}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  };

  const nameStyles = {
    fontWeight: "bold",
  };

  return (
    <div>
      <IsFlowCompletedDialog />
      <LoadingDataComponent alreadyRendered={alreadyRendered} />
      <br />
      <ProgressBar completed={0} />
      <br />
      <Typography variant="h6" color="textSecondary">
        {t("welcome_to_guarantor_consent_flow")}
      </Typography>
      <br />
      <Grid container direction="row" spacing={6}>
        <Grid item sm={6}>
          <Typography variant="p" color="textSecondary">
            {t("Hi")}
            <span style={nameStyles}>{firstName}</span>!
          </Typography>
          <br />
          <br />

          <Player
            autoplay
            loop
            src="https://assets8.lottiefiles.com/packages/lf20_puciaact.json"
          ></Player>
        </Grid>
        <Grid item sm={6}>
          <Grid container direction="row" alignItems="center">

            <Typography variant="p" color="textSecondary">
              {t("you_need")}
            </Typography>
            <ul>
              <li>
                <Typography variant="p" color="textSecondary">
                  {t("you_need_passport")}
                </Typography>
              </li>
              <li>
                <Typography variant="p" color="textSecondary">
                  {t("you_need_proof_of_income")}
                </Typography>
              </li>
              
            </ul>
          </Grid>

          <br />
          <br />
          <Link
            href={"https://properize.com/" + endpoint + "/"}
            color="inherit"
            target="_blank"
          >
            {t("welcome_data_use")}
          </Link>
          <br />
          <br />
          <Button
            variant="contained"
            endIcon={<SkipNextIcon />}
            size="large"
            color="secondary"
            onClick={() => window.location.assign(`/guarantor_id_verification${search}`)}
          >
            {t("start")}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default GuarantorWelcomePage;
