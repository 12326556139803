import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import { Player } from "@lottiefiles/react-lottie-player";

import ProgressBar from "../components/ProgressBar";

const GuarantorCompleted = () => {
  const { t } = useTranslation();

  return (
    <div>
      <br />
      <ProgressBar completed={100} />
      <Grid container justifyContent="space-between" spacing={1}>
        <Grid item sm={6}>
          <Player
            autoplay
            loop
            src="https://assets9.lottiefiles.com/packages/lf20_01bh9ld2.json"
          ></Player>
        </Grid>
        <Grid item sm={6}>
          <br /><br />
          <Typography variant="h4" color="textSecondary">
            {t("completed")}!
          </Typography>
          <br /><br />
          <Typography variant="p" color="textSecondary">
            {t("completed_all4")}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default GuarantorCompleted;
