import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

import CircularProgressWithLabel from "../components/CircularProgressWithLabel";
import NoFileDialog from "../components/NoFileDialog";
import DropzoneReact from "../components/DropzoneReact";
import { uploadFilesToS3Directly } from "../utils/uploadToS3";
import unidecode from "unidecode"
import LoadingDataComponent from "./LoadingDataComponent";

const ScanDialog = ({
  isScanDialogOpen,
  setIsScanDialogOpen,
  handleSubmit,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog open={isScanDialogOpen}>
      <DialogTitle align="center">
        <Grid p={1}>{t("Are you sure you want to proceed?")}</Grid>
      </DialogTitle>
      <DialogContent>
        <Grid sx={{ maxWidth: "350px" }}>
          {t("At least one file consists of images only")}
        </Grid>

        <Grid container direction="column" mt={7} spacing={1}>
          <Grid item>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => setIsScanDialogOpen(false)}
              fullWidth
              size="large"
            >
              {t("cancel")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              fullWidth
              size="large"
              onClick={(event) => handleSubmit(event, true)}
            >
              {t("submit")}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const TimeoutDialog = ({ isTimeoutDialogOpen, setIsTimeoutDialogOpen }) => {
  const { t } = useTranslation();
  return (
    <Dialog open={isTimeoutDialogOpen}>
      <DialogTitle align="center">
        <Grid p={1}>{t("Connection timeout")}</Grid>
      </DialogTitle>
      <DialogContent>
        <Grid sx={{ maxWidth: "350px" }}>
          {t("Connection timeout paragraph")}
        </Grid>
        <Grid mt={10}>
          <Button
            color="secondary"
            variant="contained"
            fullWidth
            size="large"
            onClick={() => setIsTimeoutDialogOpen(false)}
          >
            OK
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const SubmitBankStatement = ({ properize_id, url, whatToDo }) => {
  const [files, setFiles] = useState([]);
  const [bankAccountType, setBankAccountType] = useState("");
  const [isScanDialogOpen, setIsScanDialogOpen] = useState(false);
  const [isTimeoutDialogOpen, setIsTimeoutDialogOpen] = useState(false);

  const [progress, setProgress] = useState(0);
  const [submitClicked, setSubmitClicked] = useState(false);

  const { t } = useTranslation();

  async function handleSubmit(event, sendScanAnyway) {
    event.preventDefault();

    setSubmitClicked(true);
    setIsScanDialogOpen(false);

    if (files.length === 0) return 0;

    const timeoutId = setTimeout(() => {
      setSubmitClicked(false);
      setIsTimeoutDialogOpen(true);
    }, 30000);

    await uploadFilesToS3Directly(
      properize_id,
      files,
      "properize-bank-statements",
      setProgress
    );

    clearTimeout(timeoutId)

    let payload = {
      properize_id: properize_id,
      fileNames: files.map((file) =>
        unidecode(file.file.name.replaceAll(" ", "_").replaceAll(",", "_"))
      ),
      send_scan_anyway: sendScanAnyway,
    };

    axios
      .post(url + "/bank_statement_pipeline", payload, { timeout: 12000 })
      .then((response) => {
        if (response.data.isScan) {
          setIsScanDialogOpen(true);
          setSubmitClicked(false);
        } else {
          window.location.assign(
            "/optional_information?properize_id=" + properize_id
          );
        }
      })
      .catch((err) => {
        window.location.assign(
          "/optional_information?properize_id=" + properize_id
        );
      });
  }

  return (
    <div>
      <form onSubmit={(event) => handleSubmit(event, false)}>
        {t("manual_upload")}
        <br />
        <br />

        <Alert
          severity="info"
          color="error"
          sx={{
            marginBottom: 2,
          }}
        >
          <AlertTitle>{t("Please Read")}</AlertTitle>
          {t("manual_upload2")}
        </Alert>

        {["Self-employed", "Entrepreneur"].includes(whatToDo) && (
          <Grid mt={1} mb={3}>
            <Typography variant="p" color="black">
              {t("What type of bank account")}
            </Typography>

            <RadioGroup
              value={bankAccountType}
              onChange={(event) => setBankAccountType(event.target.value)}
              style={{ marginTop: 5 }}
            >
              <FormControlLabel
                value="Personal"
                control={<Radio />}
                label={t("My personal bank account")}
              />
              <FormControlLabel
                value="Business"
                control={<Radio />}
                label={t("My business' bank account")}
              />
            </RadioGroup>

            {bankAccountType === "Business" && (
              <Alert
                severity="info"
                color="error"
                sx={{
                  marginTop: 2,
                }}
              >
                <AlertTitle>{t("Please Read")}</AlertTitle>
                {t("We recommend that you upload a personal bank statement")}
              </Alert>
            )}
          </Grid>
        )}

        <DropzoneReact files={files} setFiles={setFiles} acceptedTypes="application/pdf" maxFiles={3} />
        
        <br />
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            type="submit"
            disabled={
              submitClicked ||
              (!bankAccountType &&
                ["Self-employed", "Entrepreneur"].includes(whatToDo))
            }
          >
            {(!submitClicked || files.length === 0) && t("Submit")}
            {submitClicked && files.length > 0 && (
              <CircularProgressWithLabel value={progress} />
            )}
          </Button>
        </FormControl>
        <NoFileDialog
          fileName={files.length > 0}
          submitClicked={submitClicked}
          setSubmitClicked={setSubmitClicked}
        />
      </form>
      <ScanDialog
        isScanDialogOpen={isScanDialogOpen}
        setIsScanDialogOpen={setIsScanDialogOpen}
        handleSubmit={handleSubmit}
      />

      <TimeoutDialog
        isTimeoutDialogOpen={isTimeoutDialogOpen}
        setIsTimeoutDialogOpen={setIsTimeoutDialogOpen}
      />

      <LoadingDataComponent alreadyRendered={!submitClicked} />
    </div>
  );
};

export default SubmitBankStatement;
