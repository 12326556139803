import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import cookies from "js-cookie";
import {
  Alert,
  AlertTitle,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
  Table,
  TableBody,
} from "@mui/material";

import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";

import ApartmentIcon from "@mui/icons-material/Apartment";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import LoadingDataComponent from "../components/LoadingDataComponent";
import ProgressBar from "../components/ProgressBar";
import SectionHeader from "../components/SectionHeader";
import MessagePopup from "../components/MessagePopup";

const FamilyCompositionTable = ({
  properize_id,
  url,
  setAlreadyRendered,
  setFillDataComponent,
  numberOfAdults,
  setNumberOfAdults,
  setAdultsArray,
  numberOfChildren,
  setNumberOfChildren,
  numberOfPets,
  setNumberOfPets,
  pets,
  setPets,
  backClicked,
}) => {
  const { t } = useTranslation();

  function decreaseAdultsByOne() {
    if (numberOfAdults <= 1) return 0;
    setNumberOfAdults(numberOfAdults - 1);
    let adults = [];
    for (let i = 1; i < numberOfAdults - 1; i++) {
      adults = adults.concat([
        {
          id: i,
          firstName: "",
          lastName: "",
          email: "",
        },
      ]);
    }
    setAdultsArray(adults);
  }

  function increaseAdultsByOne() {
    setNumberOfAdults(numberOfAdults + 1);
    let adults = [];
    for (let i = 1; i < numberOfAdults + 1; i++) {
      adults = adults.concat([
        {
          id: i,
          firstName: "",
          lastName: "",
          email: "",
        },
      ]);
    }
    setAdultsArray(adults);
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (numberOfAdults !== 1) {
      setFillDataComponent(true);
    } else {
      setAlreadyRendered(false);
    }

    const payload = {
      properize_id: properize_id,
      "Number of children": String(numberOfChildren),
      Pets: pets,
      hidingCotenant: backClicked && numberOfAdults === 1,
    };

    await axios.post(url + "/update_single_record", payload);

    if (numberOfAdults === 1) {
      window.location.assign("/about_yourself?properize_id=" + properize_id);
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="p" color="black">
        {t("family_composition_paragraph")}
      </Typography>

      <Alert
        severity="info"
        color="error"
        sx={{
          maxWidth: "1000px",
          marginTop: 2,
          marginBottom: 2,
        }}
      >
        <AlertTitle>{t("Please Read")}</AlertTitle>
        {t("family_composition_warning")}
      </Alert>
      <Table
        size="small"
        sx={{
          maxWidth: "250px",
          [`& .${tableCellClasses.root}`]: {
            border: "none",
            padding: 0.5,
          },
          [`& .${tableRowClasses.root}`]: {
            height: "55px",
          },
          marginTop: 1,
        }}
      >
        <TableBody>
          <TableRow key={"Adults"}>
            <TableCell component="th" scope="row">
              <Typography sx={{ fontWeight: "bold" }}>{t("Adults")}</Typography>
            </TableCell>
            <TableCell>
              {" "}
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
                wrap="nowrap"
                spacing={1}
              >
                <Grid item>
                  <IconButton
                    onClick={decreaseAdultsByOne}
                    style={{ borderRadius: 0 }}
                  >
                    <RemoveCircleIcon color="primary" />
                  </IconButton>
                </Grid>
                <Grid item>{numberOfAdults}</Grid>
                <Grid item>
                  <IconButton
                    onClick={increaseAdultsByOne}
                    style={{ borderRadius: 0 }}
                  >
                    <AddCircleIcon color="primary" />
                  </IconButton>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
          <TableRow key={"Children"}>
            <TableCell component="th" scope="row">
              <Typography sx={{ fontWeight: "bold" }}>
                {t("Children")}
              </Typography>
            </TableCell>
            <TableCell>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
                wrap="nowrap"
                spacing={1}
              >
                <Grid item>
                  <IconButton
                    onClick={() => {
                      if (numberOfChildren > 0) {
                        setNumberOfChildren(numberOfChildren - 1);
                      }
                    }}
                    style={{ borderRadius: 0 }}
                  >
                    <RemoveCircleIcon color="primary" />
                  </IconButton>
                </Grid>
                <Grid item>{numberOfChildren}</Grid>
                <Grid item>
                  <IconButton
                    onClick={() => setNumberOfChildren(numberOfChildren + 1)}
                    style={{ borderRadius: 0 }}
                  >
                    <AddCircleIcon color="primary" />
                  </IconButton>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
          <TableRow key={"Pets"}>
            <TableCell component="th" scope="row">
              <Typography sx={{ fontWeight: "bold" }}>{t("Pets")}</Typography>
            </TableCell>
            <TableCell>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
                wrap="nowrap"
                spacing={1}
              >
                <Grid item>
                  <IconButton
                    onClick={() => {
                      if (numberOfPets > 0) {
                        setNumberOfPets(numberOfPets - 1);
                      }
                    }}
                    style={{ borderRadius: 0 }}
                  >
                    <RemoveCircleIcon color="primary" />
                  </IconButton>
                </Grid>
                <Grid item>{numberOfPets}</Grid>
                <Grid item>
                  <IconButton
                    onClick={() => setNumberOfPets(numberOfPets + 1)}
                    style={{ borderRadius: 0 }}
                  >
                    <AddCircleIcon color="primary" />
                  </IconButton>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      {numberOfPets > 0 && (
        <Grid container direction="column" mt={3}>
          <Typography variant="p" color="black">
            {t("please_specify_pets")}
          </Typography>
          <TextField
            label={t("Pets")}
            value={pets}
            onChange={(event) => setPets(event.target.value)}
            variant="standard"
            InputLabelProps={{ required: false }}
            required
            sx={{ maxWidth: "400px", marginTop: "10px" }}
          />
        </Grid>
      )}
      <Button
        variant="contained"
        color="secondary"
        size="large"
        type="submit"
        sx={{ minWidth: "150px", marginTop: "40px" }}
      >
        {t("next")}
      </Button>
    </form>
  );
};

const FamilyForm = ({
  properize_id,
  url,
  setAlreadyRendered,
  adultsArray,
  setAdultsArray,
  setFillDataComponent,
  numberOfChildren,
  pets,
  firstName,
  lastName,
  email,
  setBackClicked,
}) => {
  const { t } = useTranslation();

  const [message, setMessage] = useState(false)

  function changeValue(id, attribute, value) {
    setAdultsArray(
      adultsArray.map((element) =>
        element.id === id ? { ...element, [attribute]: value } : element
      )
    );
  }

  async function handleSubmit(event) {
    event.preventDefault();

    // Check for email duplicates
    const emailArray = adultsArray.map(function (item) {
      return item.email;
    });
    emailArray.push(email)
    const isDuplicate = emailArray.some(function (item, idx) {
      return emailArray.indexOf(item) !== idx;
    });

    if (isDuplicate) return setMessage(t("email_duplicated"))

    setAlreadyRendered(false);

    let payload = {
      id: properize_id,
      database: "Tenants",
    };

    await axios
      .post(
        url + "/get_data_from_airtable_table?step=family_composition_handle_submit",
        payload
      )
      .then(async (response) => {
        if (response.data['family_composition_done']) {
          window.location.assign("/about_yourself?properize_id=" + properize_id)
        }
        else {
          payload = {
            properize_id: properize_id,
            family_composition_done: true,
          };
          axios.post(url + "/update_airtable_row", payload);

          payload = {
            forceDossierCreation: true,
            fields: {
              User_id: Number(response.data['User_id']),
              family_composition_done: true,
              "Parent request": response.data["Parent request"],
              "Number of children": String(numberOfChildren),
              Pets: pets,
              Language: cookies.get("i18next"),
              "Property address": response.data["Property address"],
              "Property number": response.data["Property number"],
              "Property address line 2": response.data["Property address line 2"],
              "Property city": response.data["Property city"],
              "Post code": response.data["Post code"],
              "Notes (private)": "Added by co-tenant from tenant flow",
              "Name agent": response.data["Name agent"],
              "Email agent": response.data["Email agent"],
              "Cost of the screen (EUR)": Number(response.data["Cost of the screen (EUR)"]),
              "Property price (EUR)": Number(response.data["Property price (EUR)"]),
              "Days deadline": Number(response.data["Days deadline"]),
              ArchiveRemove: 0,
              Status: "In progress",
              CheckA_Status: "In progress",
              CheckB_Status: "In progress",
              CheckC_Status: "In progress",
              "Initiated by": response.data["Initiated by"],
              "Account Type": response.data["Account Type"],
              "Flow Completed": "No",
              "is_demo": response.data["is_demo"] === true,
              "parent_leadflow_id": response.data["leadflow_id"],
              "leadflow_url": response.data["leadflow_url"]
            }
          }
        };

        Promise.all(
          adultsArray.map(async (adult) => {
            payload["fields"]["First name"] = adult.firstName;
            payload["fields"]["Last name"] = adult.lastName;
            payload["fields"]["Email"] = adult.email;

            await axios.post(url + "/create_airtable_record_api", payload);
          })
        ).then((values) => {
          window.location.assign(
            "/about_yourself?properize_id=" + properize_id
          );
        });
      });
  }

  return (
    <form onSubmit={handleSubmit}>
      <Typography sx={{ marginTop: 2, fontWeight: "bold" }}>
        {t("Adult #")}1
      </Typography>

      <Table
        size="small"
        sx={{
          maxWidth: "800px",
          marginTop: 2,
        }}
      >
        <TableBody>
          {[
            { key: "firstname", value: firstName },
            { key: "lastname", value: lastName },
            { key: "email", value: email },
          ].map((element) => (
            <TableRow key={element.key}>
              <TableCell component="th" scope="row">
                <Typography sx={{ fontWeight: "bold" }}>
                  {t(element.key)}
                </Typography>
              </TableCell>
              <TableCell component="th" scope="row">
                {element.value}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {adultsArray.map((adult) => (
        <div key={adult.id}>
          <Typography sx={{ marginTop: 5, fontWeight: "bold" }}>
            {t("Adult #") + (adult.id + 1)}
          </Typography>
          <TextField
            onChange={(event) =>
              changeValue(adult.id, "firstName", event.target.value)
            }
            label={t("firstname")}
            variant="standard"
            style={{ minWidth: "120px", width: "30%" }}
            InputLabelProps={{ required: false }}
            required
          />
          {"\u00A0\u00A0\u00A0"}
          <TextField
            onChange={(event) =>
              changeValue(adult.id, "lastName", event.target.value)
            }
            label={t("lastname")}
            variant="standard"
            style={{ minWidth: "200px", width: "50%" }}
            InputLabelProps={{ required: false }}
            required
          />
          <br />
          <TextField
            onChange={(event) =>
              changeValue(adult.id, "email", event.target.value)
            }
            label={t("email")}
            type="email"
            variant="standard"
            style={{ minWidth: "300px", width: "50%" }}
            InputLabelProps={{ required: false }}
            required
          />
        </div>
      ))}

      <Alert
        severity="info"
        color="error"
        sx={{
          maxWidth: "1000px",
          marginTop: 5,
        }}
      >
        <AlertTitle>{t("Please Read")}</AlertTitle>
        {t("personal_link_warning")}
      </Alert>

      <Grid container direction="row" spacing={2}>
        <Grid item>
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            onClick={() => {
              setBackClicked(true);
              setFillDataComponent(false);
            }}
            sx={{ minWidth: "150px", marginTop: "40px" }}
          >
            {t("back")}
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            type="submit"
            color="secondary"
            size="large"
            sx={{ minWidth: "150px", marginTop: "40px" }}
          >
            {t("add_tenant")}
          </Button>
        </Grid>
      </Grid>
      <MessagePopup state={message} setState={setMessage} message={message} />
    </form>
  );
};

const AddMoreTenants = ({ url }) => {
  const [alreadyRendered, setAlreadyRendered] = useState(true);

  const [numberOfAdults, setNumberOfAdults] = useState(1);
  const [numberOfChildren, setNumberOfChildren] = useState(0);
  const [numberOfPets, setNumberOfPets] = useState(0);
  const [pets, setPets] = useState("");

  const [adultsArray, setAdultsArray] = useState([]);
  const [backClicked, setBackClicked] = useState(false);

  const [fillDataComponent, setFillDataComponent] = useState(false);

  const [searchParams] = useSearchParams();
  const properize_id = searchParams.get("properize_id");

  const { t } = useTranslation();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    let payload = {
      id: properize_id,
      database: "Tenants",
    };
    axios
      .post(
        url + "/get_data_from_airtable_table?step=family_composition_start",
        payload
      )
      .then((response) => {
        setFirstName(response.data["First name"]);
        setLastName(response.data["Last name"]);
        setEmail(response.data["Email"]);
      });
  }, []);

  return (
    <div>
      <ProgressBar completed={5} />
      <br />
      <SectionHeader Icon={ApartmentIcon} text={t("add_tenants_title")} />
      <br />
      {!fillDataComponent && (
        <FamilyCompositionTable
          properize_id={properize_id}
          url={url}
          setAlreadyRendered={setAlreadyRendered}
          setFillDataComponent={setFillDataComponent}
          numberOfAdults={numberOfAdults}
          setNumberOfAdults={setNumberOfAdults}
          setAdultsArray={setAdultsArray}
          numberOfChildren={numberOfChildren}
          setNumberOfChildren={setNumberOfChildren}
          numberOfPets={numberOfPets}
          setNumberOfPets={setNumberOfPets}
          pets={pets}
          setPets={setPets}
          backClicked={backClicked}
        />
      )}
      {fillDataComponent && (
        <FamilyForm
          properize_id={properize_id}
          url={url}
          setAlreadyRendered={setAlreadyRendered}
          adultsArray={adultsArray}
          setAdultsArray={setAdultsArray}
          setFillDataComponent={setFillDataComponent}
          numberOfChildren={numberOfChildren}
          pets={pets}
          firstName={firstName}
          lastName={lastName}
          email={email}
          setBackClicked={setBackClicked}
        />
      )}
      <LoadingDataComponent alreadyRendered={alreadyRendered} />
    </div>
  );
};

export default AddMoreTenants;
