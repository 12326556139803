import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Button, FormControl } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";

import ProgressBar from "../components/ProgressBar";
import CircularProgressWithLabel from "../components/CircularProgressWithLabel";
import NoFileDialog from "../components/NoFileDialog";
import DropzoneReact from "../components/DropzoneReact";
import { uploadFilesToS3Directly } from "../utils/uploadToS3";
import unidecode from "unidecode"
import SectionHeader from "../components/SectionHeader";

const GuarantorProofOfIncome = ({ url, setShowLanguageSelection }) => {
  const [files, setFiles] = useState([]);

  const [progress, setProgress] = useState(0);
  const [submitClicked, setSubmitClicked] = useState(false);

  const [searchParams] = useSearchParams();
  const guarantor_id = searchParams.get("guarantor_id");

  const { t } = useTranslation();

  setShowLanguageSelection(true);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitClicked(true);

    await uploadFilesToS3Directly(
      guarantor_id,
      files,
      "properize-guarantor-docs",
      setProgress
    );

    const payload = {
      guarantor_id: guarantor_id,
      fileNames: files.map((file) => unidecode(file.file.name.replaceAll(" ", "_").replaceAll(",", "_"))),
    };

    await axios.post(url + "/guarantor_proof_of_income_pipeline", payload);

    window.location.assign("/guarantor_completed?guarantor_id=" + guarantor_id);
  };

  return (
    <div>
      <br />
      <ProgressBar completed={70} />
      <br />
      <SectionHeader
        Icon={PermIdentityOutlinedIcon}
        text={t("step_proof_of_income")}
      />
      <br />
      {t("proof_of_income_paragraph")}
      <br />
      <br />
      <form onSubmit={handleSubmit}>
        <DropzoneReact files={files} setFiles={setFiles} acceptedTypes="application/*, image/*" />

        <br />
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            type="submit"
          >
            {(!submitClicked || files.length === 0) && t("Submit")}
            {submitClicked && files.length > 0 && (
              <CircularProgressWithLabel value={progress} />
            )}
          </Button>
        </FormControl>
        <NoFileDialog
          fileName={files.length > 0}
          submitClicked={submitClicked}
          setSubmitClicked={setSubmitClicked}
        />
      </form>
    </div>
  );
};

export default GuarantorProofOfIncome;
