import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Alert,
  AlertTitle,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";

function decimalNotationInCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const SelfReportedIncomeComponent = ({
  sectionCounter,
  selfReportedIncome,
  setSelfReportedIncome,
  whatToDo,
  frequency,
  setFrequency,
}) => {
  const { t } = useTranslation();

  const [otherComp, seOtherComp] = useState("");
  const [frequencyOtherComp, setFrequencyOtherComp] = useState("Monthly");

  const [thirteenthSalary, setThirteenthSalary] = useState(false);
  const [holidayAllowance, setHolidayAllowance] = useState(false);

  let monthlyTotalComp;
  let annualTotalComp;
  let frequencyOtherMultiplierMonthly;
  let frequencyOtherMultiplierYearly;

  if (frequencyOtherComp === "Weekly") {
    frequencyOtherMultiplierMonthly = 4.25;
    frequencyOtherMultiplierYearly = 52 + 4.25 * thirteenthSalary;
  } else if (frequencyOtherComp === "Monthly") {
    frequencyOtherMultiplierMonthly = 1;
    frequencyOtherMultiplierYearly = 12 + thirteenthSalary;
  } else {
    frequencyOtherMultiplierMonthly = 1 / (12 + thirteenthSalary);
    frequencyOtherMultiplierYearly = 1;
  }

  if (frequency === "Weekly") {
    monthlyTotalComp =
      Math.round(
        (Number(selfReportedIncome) * (4.25 / (1 + 0.08 * holidayAllowance)) +
          Number(otherComp) * frequencyOtherMultiplierMonthly +
          Number.EPSILON) *
          100
      ) / 100;
    annualTotalComp =
      Math.round(
        (Number(selfReportedIncome) * (52 + 4.25 * thirteenthSalary) +
          Number(otherComp) * frequencyOtherMultiplierYearly +
          Number.EPSILON) *
          100
      ) / 100;
  } else if (frequency === "Monthly") {
    monthlyTotalComp =
      Math.round(
        (Number(selfReportedIncome) * (1 / (1 + 0.08 * holidayAllowance)) +
          Number(otherComp) * frequencyOtherMultiplierMonthly +
          Number.EPSILON) *
          100
      ) / 100;
    annualTotalComp =
      Math.round(
        (Number(selfReportedIncome) * (12 + thirteenthSalary) +
          Number(otherComp) * frequencyOtherMultiplierYearly +
          Number.EPSILON) *
          100
      ) / 100;
  } else if (frequency === "Annually") {
    monthlyTotalComp =
      Math.round(
        ((Number(selfReportedIncome) * (1 / (1 + 0.08 * holidayAllowance))) /
          12 +
          Number(otherComp) * frequencyOtherMultiplierMonthly +
          Number.EPSILON) *
          100
      ) / 100;
    annualTotalComp =
      Math.round(
        (Number(selfReportedIncome) * (1 + thirteenthSalary / 12) +
          Number(otherComp) * frequencyOtherMultiplierYearly +
          Number.EPSILON) *
          100
      ) / 100;
  }

  let salary_paragraph;

  if (["Other", "Self-employed", "Retired", "Subsidies"].includes(whatToDo)) {
    salary_paragraph = "other_monthly_income_paragraph";
  } else if (whatToDo === "Changing Jobs") {
    salary_paragraph = "changing_monthly_salary_paragraph";
  } else if (whatToDo === "Entrepreneur") {
    salary_paragraph = "Income as a business owner";
  } else {
    salary_paragraph = "monthly_salary_paragraph";
  }
  return (
    <Grid mt={5}>
      <Typography variant="p" color="black" fontWeight="bold">
        {sectionCounter}. {t(salary_paragraph)}
      </Typography>

      {whatToDo === "Entrepreneur" && (
        <Alert
          severity="info"
          color="error"
          sx={{
            marginTop: 2,
          }}
        >
          <AlertTitle>{t("Please Read")}</AlertTitle>
          {t("Your personal income, not your business revenue entrepreneur")}
        </Alert>
      )}

      {whatToDo === "Self-employed" && (
        <Alert
          severity="info"
          color="error"
          sx={{
            marginTop: 2,
          }}
        >
          <AlertTitle>{t("Please Read")}</AlertTitle>
          {t("Your personal income, not your business revenue self-employed")}
        </Alert>
      )}

      <Grid container spacing={1} mt={1} direction="row">
        <Grid item>
          <TextField
            type="number"
            inputProps={{
              step: 0.01,
              min: 0,
            }}
            label={
              [
                "Work",
                "Changing Jobs",
                "Recently Changed Jobs",
                "Study",
              ].includes(whatToDo)
                ? t("base_salary")
                : t("monthly_income")
            }
            onChange={(event) => setSelfReportedIncome(event.target.value)}
            variant="standard"
            InputLabelProps={{ required: false }}
            style={{ maxWidth: "160px" }}
            required
          />
        </Grid>
        {["Work", "Changing Jobs", "Recently Changed Jobs", "Study"].includes(
          whatToDo
        ) && (
          <Grid item>
            <TextField
              label={t("Frequency")}
              value={frequency}
              select
              onChange={(event) => setFrequency(event.target.value)}
              style={{ backgroundColor: "#F5F5F5", minWidth: "150px" }}
              InputLabelProps={{ required: false, shrink: true }}
            >
              <MenuItem key="Weekly" value="Weekly">
                {t("Weekly")}
              </MenuItem>
              <MenuItem key="Monthly" value="Monthly">
                {t("Monthly")}
              </MenuItem>
              <MenuItem key="Annually" value="Annually">
                {t("Annually")}
              </MenuItem>
            </TextField>
          </Grid>
        )}
      </Grid>

      {["Work", "Changing Jobs", "Recently Changed Jobs", "Study"].includes(
        whatToDo
      ) && (
        <div style={{ marginBottom: 20 }}>
          <Grid container spacing={1} mt={1} direction="row">
            <Grid item>
              <TextField
                type="number"
                inputProps={{
                  step: 0.01,
                  min: 0,
                }}
                label={t("other_compensation")}
                value={otherComp}
                onChange={(event) => seOtherComp(event.target.value)}
                variant="standard"
                style={{ maxWidth: "160px" }}
              />
            </Grid>
            <Grid item>
              <TextField
                label={t("Frequency")}
                value={frequencyOtherComp}
                select
                onChange={(event) => setFrequencyOtherComp(event.target.value)}
                style={{ backgroundColor: "#F5F5F5", minWidth: "150px" }}
                InputLabelProps={{ required: false, shrink: true }}
              >
                <MenuItem key="Weekly" value="Weekly">
                  {t("Weekly")}
                </MenuItem>
                <MenuItem key="Monthly" value="Monthly">
                  {t("Monthly")}
                </MenuItem>
                <MenuItem key="Annually" value="Annually">
                  {t("Annually")}
                </MenuItem>
              </TextField>
            </Grid>
          </Grid>

          <FormGroup sx={{ marginTop: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={holidayAllowance}
                  onChange={() => setHolidayAllowance(!holidayAllowance)}
                />
              }
              label={t("8% extra holiday allowance")}
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={thirteenthSalary}
                  onChange={() => setThirteenthSalary(!thirteenthSalary)}
                />
              }
              label={t("I have 13th salary")}
            />
          </FormGroup>

          <Typography fontWeight="bold" fontSize={14} sx={{ marginTop: 3 }}>
            {t("Total monthly compensation") +
              "€ " +
              decimalNotationInCommas(monthlyTotalComp)}
          </Typography>
          <Typography fontWeight="bold" fontSize={14} sx={{ marginTop: 1 }}>
            {t("Total annual compensation") +
              "€ " +
              decimalNotationInCommas(annualTotalComp)}
          </Typography>
        </div>
      )}
    </Grid>
  );
};

export default SelfReportedIncomeComponent;
