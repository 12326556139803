import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, MenuItem, TextField, Typography } from "@mui/material";

const SelfReportedEmployerComponent = ({
  sectionCounter,
  setSelfReportedEmployer,
  contractType,
  setContractType,
  setStartDate,
  setEndDate,
  employerParagraph,
  whatToDo,
  setOtherContract,
}) => {
  const { t } = useTranslation();

  const today = new Date(Date.now()).toISOString().slice(0, 10)

  return (
    <Grid mt={5}>
      <Typography variant="p" color="black" fontWeight="bold">
        {sectionCounter}. {t(employerParagraph)}
      </Typography>

      <Grid
        container
        spacing={1}
        mt={1}
        direction="row"
        sx={{ maxWidth: "800px" }}
      >
        <Grid item xs={12} md={6}>
          <TextField
            label={
              ["Work", "Changing Jobs", "Recently Changed Jobs", "Study"].includes(
                whatToDo
              )
                ? t("employer")
                : t("business_name")
            }
            onChange={(event) => setSelfReportedEmployer(event.target.value)}
            variant="standard"
            fullWidth
            InputLabelProps={{ required: false }}
            required
          />
        </Grid>
        {["Work", "Changing Jobs", "Recently Changed Jobs", "Study"].includes(
          whatToDo
        ) && (
          <Grid item xs={12} md={6}>
            <TextField
              label={t("Contract Type")}
              select
              required
              value={contractType}
              onChange={(event) => setContractType(event.target.value)}
              style={{ backgroundColor: "#F5F5F5" }}
              fullWidth
              InputLabelProps={{ shrink: true, required: false }}
            >
              <MenuItem key="Indefinite" value="Indefinite">
                {t("Permanent")}
              </MenuItem>
              <MenuItem key="Fixed Term" value="Fixed Term">
                {t("Fixed Term")}
              </MenuItem>
              <MenuItem key="Other Term" value="Other Term">
                {t("Other Term")}
              </MenuItem>
            </TextField>
          </Grid>
        )}

        {["Work", "Changing Jobs", "Recently Changed Jobs", "Study"].includes(
          whatToDo
        ) && (
          <Grid item xs={12} md={6}>
            <TextField
              label={t("Start date")}
              type="date"
              onChange={(event) => setStartDate(event.target.value)}
              style={{ backgroundColor: "#F5F5F5" }}
              fullWidth
              InputLabelProps={{ shrink: true, required: false }}
              inputProps={{
                min: whatToDo === "Changing Jobs" ? today : null,
                max: whatToDo === "Changing Jobs" ? null : today,
              }}
              required
            />
          </Grid>
        )}

        {contractType === "Fixed Term" &&
          ["Work", "Changing Jobs", "Recently Changed Jobs", "Study"].includes(
            whatToDo
          ) && (
            <Grid item xs={12} md={6}>
              <TextField
                label={t("contract_end_date")}
                type="date"
                onChange={(event) => setEndDate(event.target.value)}
                style={{ backgroundColor: "#F5F5F5" }}
                fullWidth
                InputLabelProps={{ shrink: true, required: false }}
                required
              />
            </Grid>
          )}
      </Grid>
      {contractType === "Other Term" &&
        ["Work", "Changing Jobs", "Recently Changed Jobs", "Study"].includes(
          whatToDo
        ) && (
          <Grid mt={2}>
            <TextField
              label={t("explain")}
              onChange={(event) => setOtherContract(event.target.value)}
              style={{ backgroundColor: "#F5F5F5", maxWidth: "390px" }}
              fullWidth
              InputLabelProps={{ shrink: true, required: false }}
              required
            />
          </Grid>
        )}
      {["Self-employed", "Entrepreneur"].includes(whatToDo) && (
        <Grid item>
          <div style={{ marginTop: 20, marginBottom: 15 }}>
            <Typography variant="p" color="black">
              {whatToDo === "Self-employed" &&
                t("When did you start with this business?")}
              {whatToDo === "Entrepreneur" &&
                t("When did you incorporate this business?")}
            </Typography>
          </div>
          <TextField
            label={t("Start date")}
            type="date"
            onChange={(event) => setStartDate(event.target.value)}
            style={{ backgroundColor: "#F5F5F5", width: "250px" }}
            InputLabelProps={{ shrink: true, required: false }}
            required
          />
        </Grid>
      )}
    </Grid>
  );
};

export default SelfReportedEmployerComponent;
