import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";

import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";

import ProgressBar from "../../components/ProgressBar";
import TooltipHelp from "../../components/TooltipHelp";
import LoadingDataComponent from "../../components/LoadingDataComponent";

import NoFileDialog from "../../components/NoFileDialog";
import DropzoneReact from "../../components/DropzoneReact";

import { uploadFilesToS3Directly } from "../../utils/uploadToS3";
import unidecode from "unidecode"

import AutoCompleteAddress from "../../components/AutoCompleteAddress";
import MessagePopup from "../../components/MessagePopup";
import SectionHeader from "../../components/SectionHeader";

const PermanentAddress = ({
  address,
  setAddress,
  streetNumber,
  setStreetNumber,
  postCode,
  setPostCode,
  city,
  setCity,
  countryCode,
  setCountryCode,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <TextField
        value={address}
        onChange={(event) => setAddress(event.target.value)}
        label={t("Street Name")}
        variant="standard"
        style={{ minWidth: "200px", width: "50%" }}
        InputLabelProps={{ required: false }}
        required
      />
      {"\u00A0\u00A0\u00A0"}
      <TextField
        value={streetNumber}
        onChange={(event) => setStreetNumber(event.target.value)}
        label={t("Number")}
        variant="standard"
        style={{ maxWidth: "25%" }}
        InputLabelProps={{ required: false }}
        required
      />
      <br />
      <TextField
        value={postCode}
        onChange={(event) => setPostCode(event.target.value)}
        label={t("Post Code")}
        variant="standard"
        style={{ minWidth: "150px", width: "25%" }}
        InputLabelProps={{ required: false }}
        required
      />
      {"\u00A0\u00A0\u00A0"}
      <TextField
        value={city}
        onChange={(event) => setCity(event.target.value)}
        label={t("City")}
        variant="standard"
        style={{ minWidth: "170px", width: "40%" }}
        InputLabelProps={{ required: false }}
        required
      />
      {"\u00A0\u00A0\u00A0"}
      <TextField
        value={countryCode}
        onChange={(event) => setCountryCode(event.target.value)}
        label={t("Country")}
        variant="standard"
        style={{ minWidth: "170px", width: "40%" }}
        InputLabelProps={{ required: false }}
        required
      />
    </div>
  );
};

const PhoneNumberBox = ({ phoneNumber, setPhoneNumber }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Typography variant="p" color="black">
        {t("phone_number_paragraph")}
      </Typography>
      <br />
      <TextField
        value={phoneNumber}
        onChange={(event) => setPhoneNumber(event.target.value)}
        label={t("Phone Number")}
        variant="standard"
        style={{ minWidth: "200px", width: "50%" }}
        InputLabelProps={{ required: false }}
        required
      />{" "}
    </div>
  );
};

const BioComponent = ({ bio, setBio }) => {
  const { t } = useTranslation();
  return (
    <div>
      <Typography variant="p" color="black">
        {t("bio_paragraph")} {"\u00A0\u00A0"}
        <TooltipHelp json_flag={"bio_tooltip"} />
      </Typography>
      <br />
      <br />
      <TextField
        value={bio}
        onChange={(event) => setBio(event.target.value)}
        style={{ minWidth: "320px", width: "90%" }}
        inputProps={{ maxLength: 1500 }}
        rows={4}
        multiline
        variant="filled"
        required
      />
    </div>
  );
};

const UploadSupportingDocuments = ({
  files,
  setFiles,
  submitClicked,
  setSubmitClicked,
  checkBoxDoc,
  setCheckBoxDoc,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      {!checkBoxDoc && (
        <div>
          <br />
          <br />
          <Typography variant="h6" color="black">
            {t("upload_supporting_documents_title")}
          </Typography>
          <br />
          <Typography>{t("upload_supporting_documents_paragraph")}</Typography>
          <br />
          <DropzoneReact files={files} setFiles={setFiles} acceptedTypes="application/*, image/*" />

          <NoFileDialog
            fileName={files.length > 0}
            submitClicked={submitClicked}
            setSubmitClicked={setSubmitClicked}
          />
        </div>
      )}
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <FormGroup sx={{ marginTop: 3 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkBoxDoc}
                onChange={() => setCheckBoxDoc(!checkBoxDoc)}
              />
            }
            label={t("dont_want_supporting_documents")}
          />
        </FormGroup>
      </FormControl>
    </div>
  );
};

const AdditionalQuestionsLite = ({ url, setShowLanguageSelection }) => {
  const [searchParams] = useSearchParams();
  const properize_id = searchParams.get("properize_id");

  const [address, setAddress] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [postCode, setPostCode] = useState("");
  const [city, setCity] = useState("");
  const [countryCode, setCountryCode] = useState("");

  const [isExpat, setIsExpat] = useState(false);
  const [livedInNLBefore, setLivedInNLBefore] = useState(false);
  const [youAreInNLNow, setYouAreInNLNow] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [bio, setBio] = useState("");

  const [checkBoxDoc, setCheckBoxDoc] = useState(false);
  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState(0);

  const [submitClicked, setSubmitClicked] = useState(false);

  const [alreadyRendered, setAlreadyRendered] = useState(false);
  const [message, setMessage] = useState(false);

  const { t } = useTranslation();
  const { search } = useLocation();

  setShowLanguageSelection(true);

  useEffect(() => {
    const payload = {
      id: properize_id,
      database: "Tenants",
    };

    axios
      .post(
        url + "/get_data_from_airtable_table?step=lite_additional_questions",
        payload
      )
      .then((response) => {
        if (response.data["Flow type"] === "Netherlands") {
          setAddress(response.data["Current address"]);
          setStreetNumber(response.data["Current number"]);
          setPostCode(response.data["Current post code"]);
          setCity(response.data["Current city"]);
        }

        setPhoneNumber(response.data["Mobile phone"]);
        setBio(response.data["Bio"]);
        setIsExpat(response.data["Flow type"] === "Expat");
        setAlreadyRendered(true);
      });
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitClicked(true);

    if (!address) {
      setSubmitClicked(false);
      return setMessage(t("invalid_address"));
    }

    if ((streetNumber === "X" || !streetNumber) && (!isExpat || livedInNLBefore === "yes")) {
      setSubmitClicked(false);
      return setMessage(t("no_number_in_address"));
    }

    if (!checkBoxDoc && files.length === 0) {
      return setSubmitClicked(true);
    }

    setAlreadyRendered(false);

    await uploadFilesToS3Directly(
      properize_id,
      files,
      "properize-employer-docs",
      setProgress
    );

    const payload = {
      properize_id: properize_id,
      address: address,
      housenumber: streetNumber,
      postcode: postCode,
      city: city,
      country_code: countryCode,
      lived_in_nl_before: livedInNLBefore === "yes",
      phoneNumber: phoneNumber,
      bio: bio,
      fileNames: files
        .map((file) =>
          unidecode(file.file.name.replaceAll(" ", "_").replaceAll(",", "_"))
        ),
    };

    axios
      .post(url + "/lite_additional_questions", payload, {
        timeout: 4000,
      })
      .then((response) => {
        window.location.assign("/completed" + search)
      })
      .catch((err) => {
        window.location.assign("/completed" + search);
      });
  };

  return (
    <div>
      <ProgressBar completed={80} />
      <br />
      <SectionHeader Icon={HomeOutlinedIcon} text={t("step_additional_info")} />

      <form onSubmit={handleSubmit}>
        {isExpat && (
          <div style={{ marginTop: "35px" }}>
            <Typography>
              {t("Have you lived in the Netherlands before?")}
            </Typography>

            <RadioGroup
              value={livedInNLBefore}
              onChange={(event) => setLivedInNLBefore(event.target.value)}
            >
              <FormControlLabel
                value="no"
                control={<Radio />}
                label={t("no")}
              />
              <FormControlLabel
                value="yes"
                control={<Radio />}
                label={t("yes")}
              />
            </RadioGroup>

            {livedInNLBefore === "no" && (
              <div style={{ marginTop: "20px" }}>
                <Typography>
                  {t("Are you currently in the Netherlands?")}
                </Typography>

                <RadioGroup
                  value={youAreInNLNow}
                  onChange={(event) => setYouAreInNLNow(event.target.value)}
                >
                  <FormControlLabel
                    value="no"
                    control={<Radio />}
                    label={t("no")}
                  />
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label={t("yes")}
                  />
                </RadioGroup>
              </div>
            )}
          </div>
        )}
        {(!isExpat || livedInNLBefore === "yes" || youAreInNLNow !== false) && (
          <div
            style={{ minWidth: "320px", maxWidth: "800px", marginTop: "20px" }}
          >
            <Typography variant="p" color="black">
              {youAreInNLNow === "yes" && t("enter_current_address_in_NL")}
              {livedInNLBefore === "yes" &&
                youAreInNLNow !== "yes" &&
                t("enter_last_known_address_in_NL")}
              {(!isExpat ||
                (livedInNLBefore === "no" && youAreInNLNow === "no")) &&
                t("enter_current_address")}
            </Typography>
          </div>
        )}

        {((!isExpat || livedInNLBefore === "yes") || (isExpat && livedInNLBefore === "no" && youAreInNLNow === "yes")) && (
          <AutoCompleteAddress
            setStreet={setAddress}
            setHouseNumber={setStreetNumber}
            setPostCode={setPostCode}
            setCity={setCity}
            setCountryCode={setCountryCode}
            countryRestrictions={["nl"]}
          />
        )}

        {isExpat && livedInNLBefore === "no" && youAreInNLNow === "no" && (
          <PermanentAddress
            address={address}
            setAddress={setAddress}
            streetNumber={streetNumber}
            setStreetNumber={setStreetNumber}
            postCode={postCode}
            setPostCode={setPostCode}
            city={city}
            setCity={setCity}
            countryCode={countryCode}
            setCountryCode={setCountryCode}
          />
        )}
        <br />
        <br />
        <PhoneNumberBox
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
        />
        <br />
        <br />
        <BioComponent bio={bio} setBio={setBio} />

        <UploadSupportingDocuments
          files={files}
          setFiles={setFiles}
          submitClicked={submitClicked}
          setSubmitClicked={setSubmitClicked}
          checkBoxDoc={checkBoxDoc}
          setCheckBoxDoc={setCheckBoxDoc}
        />
        <Grid container justifyContent="space-between" m={1} pt={1}>
          <Button
            variant="contained"
            type="submit"
            color="secondary"
            size="large"
            style={{
              minWidth: "320px",
              fontSize: "16px",
              marginTop: "20px",
            }}
          >
            {t("finalize")}
          </Button>
        </Grid>
      </form>

      <LoadingDataComponent alreadyRendered={alreadyRendered} />
      <MessagePopup state={message} setState={setMessage} message={message} />
    </div>
  );
};

export default AdditionalQuestionsLite;
