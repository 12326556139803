import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";

import { useSearchParams } from "react-router-dom";
import axios from "axios";

const ShowHouseholdDialog = ({
  showHouseholdDialogOpen,
  setShowHouseholdDialogOpen,
  name,
  tenantsTable,
}) => {
  const { t } = useTranslation();

  function compare(a, b) {
    if (a.fullName === name) return -1;
    else return 1;
  }

  return (
    <Dialog
      open={showHouseholdDialogOpen}
      onClose={() => setShowHouseholdDialogOpen(false)}
    >
      <Grid p={1} style={{ maxWidth: "450px" }}>
        <DialogTitle>
          <Grid align="center">
            {t("This rental dossier has multiple persons")}
          </Grid>
        </DialogTitle>

        <DialogContent>
          <Grid align="center" my={4}>
            {t("Please make sure you are completing your process")}
          </Grid>

          <TableContainer sx={{ marginY: "20px" }}>
            <Table>
              <TableBody>
                {tenantsTable.sort(compare).map((tenant) => (
                  <TableRow key={tenant.fullName}>
                    <TableCell component="th" scope="row">
                      {tenant.fullName}
                    </TableCell>
                    {tenant.fullName === name && (
                      <TableCell align="center">
                        {t("Active request")}
                      </TableCell>
                    )}
                    {tenant.fullName !== name && (
                      <TableCell align="center">
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={() =>
                            window.location.assign(tenant["Flow link"])
                          }
                        >
                          {t("THIS IS ME")}
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            variant="outlined"
            onClick={() => setShowHouseholdDialogOpen(false)}
            fullWidth
            size="large"
            sx={{ margin: "7px", marginTop: "40px" }}
          >
            {t("cancel")}
          </Button>
        </DialogContent>
      </Grid>
    </Dialog>
  );
};

const NotYouClickHere = ({ url }) => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const properize_id = searchParams.get("properize_id");

  const [tenantsTable, setTenantsTable] = useState([]);
  const [name, setName] = useState("");
  const [showHouseholdDialogOpen, setShowHouseholdDialogOpen] = useState(false);


  useEffect(() => {
    const payload = {
      id: properize_id,
      properize_id: properize_id,
      database: "Tenants",
      location: window.location.href,
    };

    const noShowEndpoints = ['signing_completed', 'id_verification_mobile', 'completed', 'admin_panel', 'guarantor', 'null', 'resend_email', 'amp;']

    if (window.location.href.includes('amp;')) window.location.href = window.location.href.replaceAll('amp;', '')

    if (noShowEndpoints.some(v => window.location.href.includes(v)) || window.location.href === 'https://tenant.properize.com/') {
      return (
        <div>
          <br />
        </div>
      );
    }

    axios.post(url + "/is_tenant_pending", payload).then((response) => {
      setTenantsTable(JSON.parse(response.data["tenantsTable"]));
      if (JSON.parse(response.data["tenantsTable"]).length > 1) {
        axios
          .post(url + "/get_data_from_airtable_table", payload)
          .then((response) => {
            setName(
              response.data["First name"] + " " + response.data["Last name"]
            );
          });
      }
    });
  }, []);

  if (tenantsTable.length <= 1 || !name || window.location.href === 'https://tenant.properize.com/')
    return (
      <div>
        <br />
      </div>
    );

  return (
    <div>
      {name && (
        <Grid mt={2} mx={2}>
          {t("This is the tenant verification process for")}{" "}
          <strong>{name}</strong>.
          <Button
            variant="text"
            style={{ fontWeight: "bold" }}
            onClick={() => setShowHouseholdDialogOpen(true)}
          >
            {t("Not you? Click here")}
          </Button>
          <ShowHouseholdDialog
            showHouseholdDialogOpen={showHouseholdDialogOpen}
            setShowHouseholdDialogOpen={setShowHouseholdDialogOpen}
            name={name}
            tenantsTable={tenantsTable}
          />
        </Grid>
      )}
    </div>
  );
};

export default NotYouClickHere;
