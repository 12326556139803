import React, {useState} from "react";

import { Button } from "@mui/material";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import DoneIcon from '@mui/icons-material/Done';

const CopyToClipboard = ({ message }) => {

  const [isCopied, setIsCopied] = useState(false);

  return (
    <>
      <Button
        color="secondary"
        variant="standard"
        sx={{ marginLeft: 1 }}
        onClick={() => {
          navigator.clipboard.writeText(message);
          setIsCopied(true);
        }}
      >
        {!isCopied && <CopyAllIcon fontSize="small" />}
        {isCopied && <DoneIcon fontSize="small" />}

      </Button>
    </>
  );
};

export default CopyToClipboard;
