import axios from "axios";
import AWS from "aws-sdk";
import unidecode from "unidecode"

const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_SERVER_PUBLIC_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SERVER_SECRET_KEY,
  region: "eu-central-1",
});


export async function uploadFilesToS3Directly(
  properize_id,
  files,
  bucket,
  setProgress
) {
  for (let i = 0; i < files.length; i++) {
    const presignedS3Url = s3.getSignedUrl("putObject", {
      Bucket: bucket,
      Key: properize_id + "/" + unidecode(files[i].file.name.replaceAll(" ", "_").replaceAll(",", "_")),
      Expires: 3600,
      ContentType: files[i].file.type,
    });

    await axios.put(presignedS3Url, files[i].file, {
      headers: {
        "Content-Type": files[i].file.type,
      },
      onUploadProgress: (progressEvent) => {
        let percentComplete = i / files.length + progressEvent.loaded / progressEvent.total  / files.length;
        percentComplete = parseInt(percentComplete * 100);
        setProgress(percentComplete);
      },
    });
  }
}

export async function uploadPictureToS3Directly(properize_id, side, url, file) {

  const presignedS3Url = s3.getSignedUrl("putObject", {
    Bucket: 'properize-id-cards',
    Key: properize_id + "-" + side + ".jpg",
    Expires: 3600,
    ContentType: file.type,
  });

  await axios.put(presignedS3Url, file, {
    headers: {
      "Content-Type": file.type,
    },
  });
}
