import React from "react";
import { useTranslation } from "react-i18next";
import { Player } from "@lottiefiles/react-lottie-player";
import { Grid, Typography } from "@mui/material";

import SignatureLottie from "../lottie_files/108655-signature.json";

const ThankYouForSigning = () => {
  const { t } = useTranslation();

  return (
    <div>
      <br />
      <Grid container justifyContent="space-evenly" spacing={1}>
        <Grid item sm={6}>
          <Player
            autoplay
            loop
            style={{ height: "300px", width: "300px" }}
            src={SignatureLottie}
          ></Player>
        </Grid>
        <Grid item sm={6}>
          <br />
          <br />
          <Typography variant="h4" color="textSecondary">
            {t("thank_you_for_signing")}
          </Typography>
          <br />
          <br />
          <Typography variant="h6" color="textSecondary">
            {t("thank_you_for_signing_paragraph")}
          </Typography>
          <br />
          <br />

        </Grid>
      </Grid>
    </div>
  );
};

export default ThankYouForSigning;
