import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

import { useSearchParams } from "react-router-dom";

import WorkIcon from "@mui/icons-material/Work";

import ProgressBar from "../components/ProgressBar";
import SubmitEmploymentProof from "../components/SubmitEmploymentProof";
import LoadingDataComponent from "../components/LoadingDataComponent";
import SaveAndContinueLater from "../components/SaveAndContinueLater";

import { ReactComponent as Work } from "../svg_logos/work.svg";
import { ReactComponent as Globe } from "../svg_logos/globe.svg";
import { ReactComponent as ThreeDots } from "../svg_logos/dots-3.svg";
import SectionHeader from "../components/SectionHeader";
import { IsFlowDeletedDialog } from "./WelcomePage";

const theme = createTheme({
  palette: {
    primary: {
      main: "#257cff",
    },
    secondary: {
      main: "#f45a2a",
    },
  },
  typography: {
    footer: {
      fontSize: 14,
    },
    title: {
      fontSize: 16,
      fontWeight: "bold",
    },
    subtitle: {
      fontSize: 14,
    },
  },
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        asterisk: { display: "none" }
      }
    },
  },
});

const DescribeYourPersonalSituation = ({ url }) => {
  const [isDeleted, setIsDeleted] = useState(false);
  const [nameAgent, setNameAgent] = useState("");
  const [emailAgent, setEmailAgent] = useState("");
  const [tenant, setTenant] = useState({});
  const [groupSettings, setGroupSettings] = useState(null);

  const [workClicked, setWorkClicked] = useState(false);
  const [otherClicked, setOtherClicked] = useState(false);
  const [noClicked, setNoClicked] = useState(false);

  const [whatToDo, setWhatToDo] = useState("");
  const [alreadyRendered, setAlreadyRendered] = useState(false);

  const [searchParams] = useSearchParams();
  const properize_id = searchParams.get("properize_id");

  const { t } = useTranslation();

  const defaultProps = {
    direction: "column",
    alignItems: "center",
    justifyContent: "space-around",
    bgcolor: "background.paper",
    m: 2,
    border: 2,
    borderColor: "#257cff",
    borderRadius: 8,
    style: { width: "15rem", height: "15rem" },
    px: { xs: 0, sm: 4 },
    py: { xs: 0, sm: 5 },
    sx: [
      {
        "&:hover": {
          color: "#f45a2a",
          cursor: "pointer",
        },
      },
    ],
  };

  useEffect(() => {
    let payload = {
      id: properize_id,
      database: "Tenants",
    };
    axios
      .post(
        url + "/get_data_from_airtable_table?step=personal_situation",
        payload
      )
      .then((response) => {
        setIsDeleted(response.data["ArchiveRemove"] === "2");
        setNameAgent(response.data["Name agent"]);
        setEmailAgent(response.data["Email agent"]);
        setTenant(response.data);
        payload = {
          key: "group_id",
          value: String(response.data.User_id),
        };
        axios
          .post(
            url + "/get_properize_groups_data?step=personal_situation",
            payload
          )
          .then((response) => {
            setGroupSettings(response.data);
            setAlreadyRendered(true);
          });
      });
  }, []);

  const handleWork = () => {
    setWorkClicked(!workClicked);
    setOtherClicked(false);
    setWhatToDo("");
  };

  const handleOther = () => {
    setWorkClicked(false);
    setOtherClicked(!otherClicked);
    setWhatToDo("");
  };

  const handleNoIncome = (event) => {
    event.preventDefault();
    setAlreadyRendered(false)
    setNoClicked(false)

    const payload = {
      properize_id: properize_id,
    };

    axios.post(url + "/no_income_api", payload).then((response) => {
      window.location.assign(
        "/additional_questions?properize_id=" + properize_id
      );
    });
  };

  const ItemBox = ({ handleEvent, Title, Picture }) => {
    return (
      <Grid container {...defaultProps} onClick={handleEvent}>
        <Typography variant="title" align="center">
          {t(Title)}
        </Typography>
        <Picture
          width="100"
          height="100"
          style={{
            marginTop: 5,
          }}
        />
      </Grid>
    );
  };

  const WorkSource = () => {
    return (
      <div style={{ marginTop: 20 }}>
        <RadioGroup
          value={whatToDo}
          onChange={(event) => setWhatToDo(event.target.value)}
        >
          {null && (
            <FormControlLabel
              value=""
              control={<Radio required />}
              label="default"
            />
          )}
          {tenant["Flow type"] !== "Expat" && (
            <FormControlLabel
              value="Work"
              control={<Radio />}
              label={t("more_than_3_months_employed")}
            />
          )}
          <FormControlLabel
            value="Recently Changed Jobs"
            control={<Radio />}
            label={t("changed_jobs_recently")}
          />

          <FormControlLabel
            value="Changing Jobs"
            control={<Radio />}
            label={t("I_am_changing_jobs")}
          />
          <FormControlLabel
            value="Self-employed"
            control={<Radio />}
            label={t("I_am_selfemployed")}
          />
          <FormControlLabel
            value="Entrepreneur"
            control={<Radio />}
            label={t("I_am_an_entrepreneur")}
          />
          {tenant["Flow type"] === "Expat" && (
            <FormControlLabel
              value="Work"
              control={<Radio />}
              label={t(
                "I work remotely for a business located outside the Netherlands"
              )}
            />
          )}
        </RadioGroup>

        <SubmitEmploymentProof
          properize_id={properize_id}
          url={url}
          whatToDo={whatToDo}
          tenant={tenant}
          groupSettings={groupSettings}
        />
      </div>
    );
  };

  const OtherIncomeSource = () => {
    return (
      <div style={{ marginTop: 20 }}>
        <RadioGroup
          name="radio-buttons-group"
          value={whatToDo}
          onChange={(event) => setWhatToDo(event.target.value)}
        >
          <FormControlLabel
            value="Study"
            control={<Radio />}
            label={t("I am a student")}
          />
          <FormControlLabel
            value="Retired"
            control={<Radio />}
            label={t("I_have_retirement_pension")}
          />

          <FormControlLabel
            value="Subsidies"
            control={<Radio />}
            label={t("I receive disability payments")}
          />

          <FormControlLabel
            value="Savings"
            control={<Radio />}
            label={t("I live off personal savings")}
          />

          <FormControlLabel
            value="Other"
            control={<Radio />}
            label={t("other")}
          />
        </RadioGroup>

        {whatToDo && (
          <SubmitEmploymentProof
            properize_id={properize_id}
            url={url}
            whatToDo={whatToDo}
            tenant={tenant}
            groupSettings={groupSettings}
          />
        )}
      </div>
    );
  };

  const WorkBox = () => {
    return (
      <div>
        <Grid container alignItems="center" direction="column">
          {!otherClicked && (
            <ItemBox
              handleEvent={handleWork}
              Title={"come_for_work"}
              Picture={Work}
            />
          )}
          {workClicked && (
            <Button
              variant="contained"
              color="primary"
              style={{ width: "47%" }}
              onClick={handleWork}
            >
              {t("back")}
            </Button>
          )}
        </Grid>
      </div>
    );
  };

  const OthersBox = () => {
    return (
      <div>
        <Grid container alignItems="center" direction="column">
          {!workClicked && (
            <ItemBox
              handleEvent={handleOther}
              Title={"come_for_other"}
              Picture={Globe}
            />
          )}
          {otherClicked && (
            <Button
              variant="contained"
              color="primary"
              style={{ width: "47%" }}
              onClick={handleOther}
            >
              {t("back")}
            </Button>
          )}
        </Grid>
      </div>
    );
  };

  const NoIncomeBox = () => {
    const showItem = !workClicked && !otherClicked;

    return (
      <div>
        {showItem && (
          <ItemBox
            handleEvent={() => setNoClicked(true)}
            Title={"no_income_title"}
            Picture={ThreeDots}
          />
        )}
      </div>
    );
  };

  const NoIncomeDialog = () => {
    return (
      <Dialog open={noClicked}>
        <DialogContent>
          <DialogContentText>
            {t("no_income_popup")}
          </DialogContentText>
          <Grid container justifyContent="space-around" mt={5}>
            <Button
              variant="outlined"
              style={{ width: "47%" }}
              onClick={() => setNoClicked(false)}
            >
              {t("no")}
            </Button>
            <Button
              variant="contained"
              style={{ width: "47%" }}
              color="primary"
              onClick={handleNoIncome}
            >
              {t("yes")}
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        <ProgressBar completed={50} />
        <br />
        <SectionHeader Icon={WorkIcon} text={t("step_reason")} />
        <br />
        {!(workClicked || otherClicked) && (
          <Typography variant="p" color="textSecondary">
            {t("step_reason_manual_paragraph1")}
            <br />
            <br />
          </Typography>
        )}

        <Typography variant="p" color="textSecondary">
          {t("step_reason_manual_paragraph2")}
        </Typography>
        <br />
        <br />
        <Grid container alignItems="center">
          <WorkBox />
          <OthersBox />
          <NoIncomeBox />
        </Grid>
        <br />
        {workClicked && <WorkSource />}
        {otherClicked && <OtherIncomeSource />}
      </ThemeProvider>
      <NoIncomeDialog />
      <SaveAndContinueLater properize_id={properize_id} url={url} />
      <LoadingDataComponent alreadyRendered={alreadyRendered} />
      <IsFlowDeletedDialog isDeleted={isDeleted} nameAgent={nameAgent} emailAgent={emailAgent} />
    </div>
  );
};

export default DescribeYourPersonalSituation;
