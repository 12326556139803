import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
} from "@mui/material";


const ClickedDialog = ({ dataSaved, setDataSaved }) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={dataSaved}
      onClose={() => setDataSaved(false)}
    >
      <DialogContent>
        <DialogContentText>
          {t("data_saved_succesfully")} <br />
          <br />
          <Grid container justifyContent="center">
            <Button
              variant="contained"
              onClick={() => setDataSaved(false)}
            >
              OK
            </Button>
          </Grid>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};


const SaveAndContinueLater = ({ properize_id=null, url, address, streetNumber, postCode, city, phoneNumber, bio}) => {

  const { t } = useTranslation();

  const [submitClicked, setSubmitClicked] = useState(false);
  const [dataSaved, setDataSaved] = useState(false);

  const handleSubmit = () => {

    setSubmitClicked(true);

    if (!properize_id){
      setSubmitClicked(false);
      setDataSaved(true);
    }
    const payload = {
      properize_id: properize_id,
      address: address,
      streetNumber: streetNumber,
      postCode: postCode,
      city: city,
      phoneNumber: phoneNumber,
      bio: bio
    };

    axios.post(url + "/save_data_to_airtable", payload).then((response) => {
      setSubmitClicked(false);
      setDataSaved(true);
    });
  };

  return (
    <div>
      <br />
      <Button
        variant="text"
        color="primary"
        size="large"
        type="submit"
        onClick={handleSubmit}
      >
        {!submitClicked && t("save_and_continue_later")}
        {submitClicked && <CircularProgress />}
      </Button>
      <ClickedDialog dataSaved={dataSaved} setDataSaved={setDataSaved}/>
    </div>
  );
};

export default SaveAndContinueLater;
