import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Link,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import DoneIcon from "@mui/icons-material/Done";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ClearIcon from "@mui/icons-material/Clear";
import LaunchIcon from "@mui/icons-material/Launch";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import { useSearchParams } from "react-router-dom";
import axios from "axios";

import LoadingDataComponent from "../components/LoadingDataComponent";
import { uploadPictureToS3Directly } from "../utils/uploadToS3";

const cardStyle = {
  padding: 5,
  bgcolor: "#ffffff",
  borderRadius: 2,
  boxShadow: 5,
  marginTop: 1,
  marginBottom: 2,
};

const accordionStyle = {
  padding: 0.5,
  bgcolor: "#ffffff",
  borderRadius: 2,
  boxShadow: 5,
  marginY: 1,
};

const AccordionNoBorder = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: null,
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const RenderStatus = ({ status }) => {
  if (!status) return null;
  let color, Icon;

  if (status === "Completed") {
    color = "green";
    Icon = DoneIcon;
  } else if (status === "Processing") {
    color = "#e47200";
    Icon = RestartAltIcon;
  } else if (status === "In progress") {
    color = "blue";
    Icon = AccessTimeIcon;
  } else if (status === "Failed") {
    color = "red";
    Icon = ClearIcon;
  }

  return (
    <div>
      <Button
        variant="outlined"
        disabled
        style={{
          color: color,
          borderRadius: 50,
          borderColor: color,
        }}
      >
        <Grid container spacing={1} wrap="nowrap" sx={{ minWidth: "150px" }}>
          <Grid item>{<Icon fontSize="small" />}</Grid>
          <Grid item>{status}</Grid>
        </Grid>
      </Button>
    </div>
  );
};

const DownloadReportButton = ({ reportURL }) => {
  const onDownload = () => {
    const link = document.createElement("a");
    link.href = reportURL;
    link.click();
  };
  return (
    <strong>
      <Button
        variant="contained"
        color="primary"
        size="large"
        style={{ marginLeft: 16 }}
        onClick={onDownload}
      >
        Download
      </Button>
    </strong>
  );
};

const ratings = [5, 4, 3, 2, 1].map((status) => (
  <MenuItem key={status} value={status}>
    {status}
  </MenuItem>
));

const statuses = ["Failed", "In progress", "Processing", "Completed"].map(
  (status) => (
    <MenuItem key={status} value={status}>
      {status}
    </MenuItem>
  )
);

const verificationTypes = [
  "Bank account connected",
  "Documents",
  "Submitted bank statement",
  "No income reported",
].map((incomeSource) => (
  <MenuItem key={incomeSource} value={incomeSource}>
    {incomeSource}
  </MenuItem>
));

const incomeSources = [
  "Work",
  "Recently Changed Jobs",
  "Changing Jobs",
  "Study",
  "Entrepreneur",
  "Self-employed",
  "Retired",
  "Subsidies",
  "Savings",
  "Other",
  "No income",
].map((incomeSource) => (
  <MenuItem key={incomeSource} value={incomeSource}>
    {incomeSource}
  </MenuItem>
));

const fraudIndicators = ["Low", "Medium", "High"].map(
  (status) => (
    <MenuItem key={status} value={status}>
      {status}
    </MenuItem>
  )
);

const genders = ["Male", "Female", "Unknown"].map((status) => (
  <MenuItem key={status} value={status}>
    {status}
  </MenuItem>
));

const documentTypes = ["Passport", "Identity Card", "Residence Permit"].map(
  (status) => (
    <MenuItem key={status} value={status}>
      {status}
    </MenuItem>
  )
);

const AdminPanelLogged = ({ url }) => {
  const { t } = useTranslation();
  const [tenant, setTenant] = useState("");

  const [inviteSent, setInviteSent] = useState(false);
  const [flowResetting, setFlowResetting] = useState(false);

  const [houseHoldExpanded, setHouseHoldExpanded] = useState(false);
  const [idExpanded, setIdExpanded] = useState(false);
  const [incomeExpanded, setIncomeExpanded] = useState(false);
  const [creditCheckExpanded, setCreditCheckExpanded] = useState(false);

  const [documentsExpanded, setDocumentsExpanded] = useState(false);
  const [fraudExpanded, setFraudExpanded] = useState(false);

  const [parentRequest, setParentRequest] = useState("");

  const [address, setAddress] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [postCode, setPostCode] = useState("");
  const [city, setCity] = useState("");
  const [bio, setBio] = useState("");
  const [miscellaneous, setMiscellaneous] = useState("");

  const [householdTenants, setHouseholdTenants] = useState([]);
  const [propertyAddress, setPropertyAddress] = useState("");
  const [propertyNumber, setPropertyNumber] = useState("");
  const [propertyCity, setPropertyCity] = useState("");
  const [propertyPostCode, setPropertyPostCode] = useState("");
  const [propertyPrice, setPropertyPrice] = useState("");
  const [rating, setRating] = useState("");
  const [status, setStatus] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [documentNumber, setDocumentNumber] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [placeOfBirth, setPlaceOfBirth] = useState("");
  const [nationality, setNationality] = useState("");

  const [documentType, setDocumentType] = useState("");
  const [dateofExpiry, setDateOfExpiry] = useState("");
  const [issuingAuthority, setIssuingAuthority] = useState("");
  const [CheckA_Status, setCheckA_Status] = useState("In progress");

  const [frontPhoto, setFrontPhoto] = useState(null);
  const [backPhoto, setBackPhoto] = useState(null);

  const [netSalary, setNetSalary] = useState("");
  const [isTaxRelief, setIsTaxRelief] = useState("");
  const [grossIncome, setGrossIncome] = useState("");

  const [employer, setEmployer] = useState("");
  const [trueIncome, setTrueIncome] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [CheckB_Status, setCheckB_Status] = useState("In progress");
  const [CheckB_Completed, setCheckB_Completed] = useState("");
  
  const [fraudIndicator, setFraudIndicator] = useState("");
  const [typeOfVerification, setTypeOfVerification] = useState("");
  const [whatToDo, setWhatToDo] = useState("");

  const [disHousehold, setDisHousehold] = useState(false);
  const [disIdentity, setDisIdentity] = useState(false);
  const [disUpdateIncome, setDisUpdateIncome] = useState(false);
  const [disFraudWarnings, setDisFraudWarnings] = useState(false);
  const [disUpdateAddress, setDisUpdateAddress] = useState(false);

  const [edrButtonDisabled, setEDRButtonDisabled] = useState(false);

  const [alreadyRendered, setAlreadyRendered] = useState(false);

  const [completionButtonDis, setCompletionButtonDis] = useState(false);

  const [reportURL, setReportURL] = useState(false);

  const urlToSignRef = useRef();
  const [presignedURL, setPresignedURL] = useState("");

  const clientMessageRef = useRef();
  const responseContentRef = useRef();
  const [gptResponse, SetGptResponse] = useState("");
  const [gptCompletionButtonDisabled, setGptCompletionButtonDisabled] =
    useState(false);

  const [searchParams] = useSearchParams();
  const properize_id = searchParams.get("properize_id");

  useEffect(() => {
    axios
      .post(url + "/get_household_data", { properize_id: properize_id })
      .then((response) => {
        setHouseholdTenants(response.data);
      });

    const payload = {
      id: properize_id,
      database: "Tenants",
    };

    axios
      .post(url + "/get_data_from_airtable_table?step=admin_panel", payload)
      .then(async (response) => {
        let tenantResponse = response.data;

        if ("ID card" in tenantResponse) {
          axios
            .post(url + "/create_presigned_url_api", {
              url: tenantResponse["ID card"],
            })
            .then((response) => {
              setFrontPhoto(response.data);
            });
        }
        if (tenantResponse["ID card back"]) {
          axios
            .post(url + "/create_presigned_url_api", {
              url: tenantResponse["ID card back"],
            })
            .then((response) => {
              setBackPhoto(response.data);
            });
        }
        const photoURL =
          "https://properize-id-cards.s3.eu-central-1.amazonaws.com/" +
          properize_id +
          "-selfie.jpg";
        const responseSelfie = await axios.post(
          url + "/create_presigned_url_api",
          {
            url: photoURL,
          }
        );

        tenantResponse["selfie"] = responseSelfie.data;

        setTenant(tenantResponse);

        setParentRequest(tenantResponse["Parent request"]);

        setAddress(tenantResponse["Current address"]);
        setHouseNumber(tenantResponse["Current number"]);
        setPostCode(tenantResponse["Current post code"]);
        setCity(tenantResponse["Current city"]);
        setBio(tenantResponse["Bio"]);
        setMiscellaneous(tenantResponse["Miscellaneous text"]);

        setPropertyAddress(tenantResponse["Property address"]);
        setPropertyNumber(tenantResponse["Property number"]);
        setPropertyCity(tenantResponse["Property city"]);
        setPropertyPostCode(tenantResponse["Post code"]);
        setPropertyPrice(tenantResponse["Property price (EUR)"]);
        setRating(tenantResponse["Rating"]);
        setStatus(tenantResponse["Status"]);

        setFirstName(tenantResponse["ID first name"]);
        setLastName(tenantResponse["ID last name"]);
        setGender(tenantResponse["ID gender"]);
        setDocumentNumber(tenantResponse["Document Number"]);
        setDateOfBirth(tenantResponse["ID Date of Birth"]);
        setPlaceOfBirth(tenantResponse["ID Place of Birth"]);
        setNationality(tenantResponse["Nationality"]);

        setDocumentType(tenantResponse["document_type"]);
        setDateOfExpiry(tenantResponse["date_of_expiry"]);
        setIssuingAuthority(tenantResponse["issuing_authority"]);
        setCheckA_Status(tenantResponse["CheckA_Status"]);

        setEmployer(tenantResponse["Employer"]);
        setTrueIncome(tenantResponse["True income median"]);
        setAccountNumber(tenantResponse["IBAN list"]);
        setCheckB_Status(tenantResponse["CheckB_Status"]);
        setCheckB_Completed(tenantResponse["CheckB_Completed"]);
        setFraudIndicator(tenantResponse["Fraud indicator"]);
        setTypeOfVerification(tenantResponse["type_of_verification"])
        setWhatToDo(tenantResponse["WhatToDo"])

        setAlreadyRendered(true);
      });
  }, []);

  async function sendInvitationEmail() {
    setInviteSent(true);
    let payload = {
      properize_id: properize_id,
    };

    await axios.post(
      url + "/resend_email_invitation_to_tenant",
      payload
    );
    setInviteSent(false);
  }

  async function resetFlow() {
    setFlowResetting(true);
    const objectsToModify = {
      "Resume link": "",
      "family_composition_done": false,
      "IDAnalyzer_API_call_sent": false,
    };

    for (const [key, value] of Object.entries(objectsToModify)) {
      let payload = {
        properize_id: tenant["properize_id"],
        field: key,
        value: value,
      };

      await axios.post(url + "/update_airtable_record", payload);

    }

    setTenant({
      ...tenant,
      "Resume link": "",
      "family_composition_done": false,
      "IDAnalyzer_API_call_sent": false,
    });

    setFlowResetting(false);
  }

  async function updateHouseholdData() {
    setDisHousehold(true);

    const houseHoldObjects = {
      "Property address": propertyAddress,
      "Property number": propertyNumber,
      "Property city": propertyCity,
      "Post code": propertyPostCode,
      "Property price (EUR)": Number(propertyPrice),
      Rating: rating,
      Status: status,
    };

    for (const [key, value] of Object.entries(houseHoldObjects)) {
      if (value) {
        let payload = {
          parentRequest: tenant["Parent request"],
          field: key,
          value: value,
        };

        await axios.post(url + "/update_airtable_record", payload);
      }
    }

    setDisHousehold(false);
  }

  async function archiveDossier() {
    const houseHoldObjects = {
      "ArchiveRemove": 1,
    };

    for (const [key, value] of Object.entries(houseHoldObjects)) {
      if (value) {
        let payload = {
          parentRequest: tenant["Parent request"],
          field: key,
          value: value,
        };

        await axios.post(url + "/update_airtable_record", payload);

        setTenant({
          ...tenant,
          "ArchiveRemove": 1,
        });
      }
    }
  }

  async function deleteDossier() {
    const houseHoldObjects = {
      "ArchiveRemove": 2,
    };

    for (const [key, value] of Object.entries(houseHoldObjects)) {
      if (value) {
        let payload = {
          parentRequest: tenant["Parent request"],
          field: key,
          value: value,
        };

        await axios.post(url + "/update_airtable_record", payload);

        setTenant({
          ...tenant,
          "ArchiveRemove": 2,
        });
      }
    }
  }

  async function updateIdentityData() {
    setDisIdentity(true);
    const CheckA_Completed = new Date().toISOString();

    // Update Airtable Values
    const idObjects = {
      properize_id: tenant["properize_id"],
      "ID first name": firstName,
      "ID last name": lastName,
      "ID gender": gender,
      "Document Number": documentNumber,
      "ID Date of Birth": dateOfBirth,
      "ID Place of Birth": placeOfBirth,
      Nationality: nationality,
      document_type: documentType,
      date_of_expiry: dateofExpiry,
      issuing_authority: issuingAuthority,
      CheckA_Status: CheckA_Status,
      CheckA_Completed: CheckA_Completed,
    };

    await axios.post(url + "/update_airtable_row", idObjects);

    setTenant({
      ...tenant,
      "ID first name": firstName,
      "ID last name": lastName,
      "ID gender": gender,
      "Document Number": documentNumber,
      "ID Date of Birth": dateOfBirth,
      "ID Place of Birth": placeOfBirth,
      Nationality: nationality,
      CheckA_Status: CheckA_Status,
      CheckA_Completed: CheckA_Completed,
    });

    setDisIdentity(false);
  }

  async function updateIncomeValues() {
    setDisUpdateIncome(true);
    const incomeObjects = {
      Employer: employer,
      "True income median": Number(trueIncome),
      "IBAN list": accountNumber,
      CheckB_Status: CheckB_Status,
      "Fraud indicator": fraudIndicator,
      "type_of_verification": typeOfVerification,
      "WhatToDo": whatToDo,
      "CheckB_Completed": CheckB_Completed ? CheckB_Completed : new Date().toISOString(),
    };

    for (const [key, value] of Object.entries(incomeObjects)) {
      if (value || value === 0) {
        let payload = {
          properize_id: tenant["properize_id"],
          field: key,
          value: value,
        };

        await axios.post(url + "/update_airtable_record", payload);
      }
    }
    setTenant({
      ...tenant,
      Employer: employer,
      "True income median": trueIncome,
      "IBAN list": accountNumber,
      CheckB_Status: CheckB_Status,
      "Fraud indicator": fraudIndicator,
      "type_of_verification": typeOfVerification,
      "WhatToDo": whatToDo
    });
    setDisUpdateIncome(false);
  }

  async function cleanFraudWarnings() {
    setDisFraudWarnings(true);
    let payload = {
      properize_id: tenant["properize_id"],
      field: "fraud_warnings",
      value: "[]",
    };

    await axios.post(url + "/update_airtable_record", payload);

    setTenant({
      ...tenant,
      "fraud_warnings": '[]',
    });

    setDisFraudWarnings(false);
  }


  async function updateAddress() {
    setDisUpdateAddress(true);
    const addressObjects = {
      properize_id: tenant["properize_id"],
      "Current address": address,
      "Current number": houseNumber,
      "Current post code": postCode,
      "Current city": city,
      "Current full address":
        address + " " + houseNumber + ", " + postCode + " " + city,
      Bio: bio,
      "Miscellaneous text": miscellaneous,
    };

    await axios.post(url + "/update_airtable_row", addressObjects);
    setDisUpdateAddress(false);
  }

  async function closeDossier() {
    setCompletionButtonDis(true);

    const payload = {
      properize_id: properize_id,
    };

    const response = await axios.post(
      url + "/close_dossier_if_completed_api",
      payload
    );

    setReportURL(response.data);

    setCompletionButtonDis(false);
  }

  async function generateReport() {
    setCompletionButtonDis(true);
    const payload = {
      parent_request: parentRequest,
      language: "nl",
    };
    const response = await axios.post(
      url + "/generate_report",
      payload
    );
    const link = document.createElement("a");
    link.setAttribute("target", "_blank");
    link.href = response.data;
    link.click();
    setCompletionButtonDis(false);
  }

  async function sendEDRRequest() {
    setEDRButtonDisabled(true);
    let payload = {
      properize_id: properize_id,
    };

    await axios.post(url + "/launch_edr_manually", payload);

    payload = {
      id: properize_id,
      database: "Tenants",
    };

    const response = await axios.post(
      url + "/get_data_from_airtable_table?step=admin_panel_launch_edr_manually",
      payload
    );

    setTenant({
      ...tenant,
      "EDR personal score": response.data["EDR personal score"],
      "EDR overall score": response.data["EDR overall score"],
      "EDR conclusion": response.data["EDR conclusion"],
      "EDR issues text": response.data["EDR issues text"],
      CheckC_Status: response.data["CheckC_Status"],
    });

    setEDRButtonDisabled(false);
  }

  function getPresignedURL(e) {
    setPresignedURL("");
    e.preventDefault();
    const payload = {
      url: urlToSignRef.current.value,
    };

    axios.post(url + "/create_presigned_url_api", payload).then((response) => {
      setPresignedURL(response.data);
    });
  }

  async function sendGPTCompletion() {
    setGptCompletionButtonDisabled(true);
    SetGptResponse("");

    const payload = {
      client_message: clientMessageRef.current.value,
      response_content: responseContentRef.current.value,
    };

    await axios
      .post(url + "/create_gpt_completion", payload)
      .then((response) => {
        SetGptResponse(response.data);
      });

    setGptCompletionButtonDisabled(false);
  }

  const personalDataObject = [
    { key: "Properize ID", value: tenant["properize_id"] },
    { key: "Status", value: tenant["Status"] },
    {
      key: "Resume link", value: tenant["Status"] !== 'Completed' && tenant["Resume link"] && <Link href={tenant["Resume link"]} color="inherit" target="_blank">
        {tenant["Resume link"]}
      </Link>
    },
    { key: "Agent", value: tenant["Name agent"] + " (" + tenant["Email agent"] + ")" },
  ];

  const OBIncomeVerificationObject = [
    { key: "Type of Verification", value: "Open Banking" },
    { key: "Account Holder", value: tenant["PSD2 Account Holder"] },
    { key: "IBAN list", value: tenant["IBAN list"] },
    { key: "Employer", value: tenant["Employer"] },
    { key: "True income median", value: tenant["True income median"] },
  ];

  const SRIncomeVerificationObject = [
    { key: "Type of Verification", value: "Self reported" },
    { key: "Document", value: tenant["WhatToDo"] },
    {
      key: "Is Bank Statement?",
      value:
        tenant["Bank_Statement_Link"] &&
          tenant["Bank_Statement_Link"].length > 0
          ? "Yes (" + tenant["bank_account_type"] + ")"
          : "No",
    },
    { key: "Self reported employer", value: tenant["Selfreported employer"] },
    {
      key: "Self reported raw income",
      value: tenant["Selfreported raw income"],
    },
    {
      key: "Self reported gross income",
      value: tenant["Selfreported gross income"],
    },
  ];

  const creditCheckObject = [
    { key: "EDR Personal Score", value: tenant["EDR personal score"] },
    { key: "EDR Overall Score", value: tenant["EDR overall score"] },
    { key: "EDR conclusion", value: tenant["EDR conclusion"] },
    { key: "EDR issues", value: tenant["EDR issues text"] },
  ];

  async function overWriteFront(event) {
    await uploadPictureToS3Directly(
      properize_id,
      "front-cropped",
      null,
      event.target.files[0]
    );

    const photoURL =
      "https://properize-id-cards.s3.eu-central-1.amazonaws.com/" +
      properize_id +
      "-front-cropped.jpg";
    const response = await axios.post(url + "/create_presigned_url_api", {
      url: photoURL,
    });

    setFrontPhoto(response.data);

    const payload = {
      properize_id: tenant["properize_id"],
      "ID card": photoURL,
    };
    axios.post(url + "/update_airtable_row", payload);
  }

  async function downloadOriginalFront() {
    const photoURL =
      "https://properize-id-cards.s3.eu-central-1.amazonaws.com/" +
      properize_id +
      "-front.jpg";
    const response = await axios.post(url + "/create_presigned_url_api", {
      url: photoURL,
    });
    window.open(response.data, "_blank");
  }

  async function overWriteBack(event) {
    await uploadPictureToS3Directly(
      properize_id,
      "back-cropped",
      null,
      event.target.files[0]
    );

    const photoURL =
      "https://properize-id-cards.s3.eu-central-1.amazonaws.com/" +
      properize_id +
      "-back-cropped.jpg";
    const response = await axios.post(url + "/create_presigned_url_api", {
      url: photoURL,
    });

    setBackPhoto(response.data);

    const payload = {
      properize_id: tenant["properize_id"],
      "ID card back": photoURL,
    };
    axios.post(url + "/update_airtable_row", payload);
  }

  async function downloadOriginalBack() {
    const photoURL =
      "https://properize-id-cards.s3.eu-central-1.amazonaws.com/" +
      properize_id +
      "-back.jpg";
    const response = await axios.post(url + "/create_presigned_url_api", {
      url: photoURL,
    });
    window.open(response.data, "_blank");
  }

  async function downloadTransactionsExcel() {
    const excelURL =
      "https://properize-yapily-transactions-sheets.s3.eu-central-1.amazonaws.com/" +
      properize_id +
      "-transactions.xlsx";
    const response = await axios.post(url + "/create_presigned_url_api", {
      url: excelURL,
    });
    window.location.assign(response.data);
  }

  async function calculateGross() {
    setGrossIncome(null);
    const payload = {
      netSalary: netSalary,
      isTaxRelief: isTaxRelief,
    };
    const response = await axios.post(url + "/calculate_gross_income", payload);

    setGrossIncome(response.data);
  }

  const DocumentsTable = ({ name, urls_string }) => {
    if (!urls_string) {
      return null;
    }

    const urlsArray = urls_string.split(", ");

    async function openDocument(urlToDownload) {
      const response = await axios.post(url + "/create_presigned_url_api", {
        url: urlToDownload,
      });
      window.open(response.data);
    }

    return (
      <Grid item xs={12} lg={6}>
        <Typography ml={2} mt={1} sx={{ fontSize: 14, fontWeight: "bold" }}>
          {t(name)}
        </Typography>
        <Table
          sx={{
            [`& .${tableCellClasses.root}`]: {
              padding: 0.5,
            },
            [`& .${tableRowClasses.root}`]: {
              height: "40px",
            },
            margin: 2,
            marginBottom: 5,
            maxWidth: "450px",
          }}
        >
          <TableBody>
            {urlsArray.map((urlToDownload) => (
              <TableRow key={urlToDownload}>
                <TableCell align="left">
                  {urlToDownload.split("/").slice(-1)[0]}
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    style={{ borderRadius: 0 }}
                    onClick={() => openDocument(urlToDownload)}
                  >
                    <LaunchIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    );
  };

  const isDocumentToDownload =
    tenant["Bank_Statement_Link"] ||
    tenant["Document_Payslips_link"] ||
    tenant["Document_Employer_links"] ||
    tenant["Miscellaneous link"] ||
    tenant["Extra link"];

  let name;

  switch (tenant["WhatToDo"]) {
    case "Other":
      name = t("Proof of income");
      break;
    default:
      name = t("Proof of employment");
  }

  const documentCategories = [
    { name: "Bank statements", urls_string: tenant["Bank_Statement_Link"] },
    { name: "Payslips", urls_string: tenant["Document_Payslips_link"] },
    { name: name, urls_string: tenant["Document_Employer_links"] },
    { name: "Miscellaneous", urls_string: tenant["Miscellaneous link"] },
    { name: "Extra document", urls_string: tenant["Extra link"] },
  ];

  const documentsCard = (
    <Grid style={{ maxWidth: "1500px", width: "100%" }} sx={accordionStyle}>
      <AccordionNoBorder
        expanded={documentsExpanded}
        onChange={() => setDocumentsExpanded(!documentsExpanded)}
        key={"documentsCard"}
      >
        <AccordionSummary sx={{ bgcolor: "#ffffff" }}>
          <Typography p={1} sx={{ fontSize: 20, fontWeight: "bold" }}>
            {t("Documents")}
          </Typography>
        </AccordionSummary>

        <AccordionDetails>

          <Button
            variant="contained"
            component="label"
            color="primary"
            sx={{
              minWidth: "300px",
              marginBottom: 2,
              marginLeft: 2
            }}
            onClick={() =>
              window.open("/extra_information?properize_id=" + tenant.properize_id)
            }

          >
            Add more documents
          </Button>

          {!isDocumentToDownload && (
            <Typography ml={2} sx={{ fontSize: 16 }}>
              "No documents provided"
            </Typography>
          )}

          {isDocumentToDownload && (
            <Grid container direction="row" ml={1}>
              {documentCategories.map((category) => (
                <DocumentsTable
                  name={category.name}
                  urls_string={category.urls_string}
                />
              ))}
            </Grid>
          )}
        </AccordionDetails>
      </AccordionNoBorder>
    </Grid>
  );

  const FraudDetectionCard = () => {
    if (!tenant["Fraud results"]) return null;

    const fraudResults = JSON.parse(tenant["Fraud results"]);
    return (
      <Grid style={{ maxWidth: "1500px", width: "100%" }} sx={accordionStyle}>
        <AccordionNoBorder
          expanded={fraudExpanded}
          onChange={() => setFraudExpanded(!fraudExpanded)}
          key={"fraudExpanded"}
        >
          <AccordionSummary
            sx={{ bgcolor: fraudIndicator === "High" ? "#FF5C5C" : "#ffffff" }}
          >
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography p={1} sx={{ fontSize: 20, fontWeight: "bold" }}>
                  Fraud Results
                </Typography>
              </Grid>
              <Grid item>
                <Typography p={1} sx={{ fontSize: 20, fontWeight: "bold" }}>
                  {fraudIndicator && fraudIndicator + " risk"}
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>

          <AccordionDetails>
            <Grid container spacing={2}>
              {fraudResults.map((file) => (
                <Grid item>
                  <Table
                    sx={{
                      [`& .${tableCellClasses.root}`]: {
                        padding: 1,
                      },
                      [`& .${tableRowClasses.root}`]: {
                        height: "40px",
                      },
                      margin: 2,
                      marginBottom: 5,
                      maxWidth: "450px",
                    }}
                  >
                    <TableBody>
                      <TableRow key={file.filename}>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Filename
                        </TableCell>
                        <TableCell>{file.filename}</TableCell>
                      </TableRow>
                      {file.metadata && (
                        <TableRow key={file.filename.metadata}>
                          <TableCell style={{ fontWeight: "bold" }}>
                            Metadata
                          </TableCell>
                          {file.metadata !==
                            "Document does not have any metadata" && (
                              <TableCell>
                                {Object.keys(file.metadata).map((key, index) => (
                                  <div>
                                    {key}: {file.metadata[key]}
                                  </div>
                                ))}
                              </TableCell>
                            )}
                          {file.metadata ===
                            "Document does not have any metadata" && (
                              <TableCell>No metadata available</TableCell>
                            )}
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>

                  {file.properize_internal_fraud_result &&
                    file.properize_internal_fraud_result.length > 0 && (
                      <Table
                        sx={{
                          [`& .${tableCellClasses.root}`]: {
                            padding: 0.5,
                          },
                          margin: 2,
                          marginBottom: 5,
                          maxWidth: "450px",
                        }}
                      >
                        <TableBody>
                          <TableRow key={file.filename + "resistant_metadata"}>
                            <TableCell>
                              PROPERIZE FRAUD ENGINE INDICATORS
                              <br />
                              ------------------------------------------------------
                              {file.properize_internal_fraud_result.map(
                                (indicator) => (
                                  <div key={indicator.Title}>
                                    <span style={{ fontWeight: "bold" }}>
                                      Title
                                    </span>
                                    : {indicator.Title}
                                    <br />
                                    <span style={{ fontWeight: "bold" }}>
                                      Fraud Risk
                                    </span>
                                    : {indicator["Fraud Risk"]}
                                    <br />
                                    <span style={{ fontWeight: "bold" }}>
                                      Description
                                    </span>
                                    : {indicator.Description}
                                    <br />
                                    -------------------------------------------------------
                                    <br />
                                  </div>
                                )
                              )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    )}

                  {file.fraud_result && (
                    <div>
                      <Typography sx={{ marginX: 5, fontWeight: "bold" }}>
                        RESISTANT AI Analysis
                      </Typography>

                      <Table
                        sx={{
                          [`& .${tableCellClasses.root}`]: {
                            padding: 1,
                          },
                          [`& .${tableRowClasses.root}`]: {
                            height: "40px",
                          },
                          margin: 2,
                          marginBottom: 5,
                          maxWidth: "450px",
                        }}
                      >
                        <TableBody>
                          <TableRow key={file.filename + "score"}>
                            <TableCell style={{ fontWeight: "bold" }}>
                              Score
                            </TableCell>
                            <TableCell>{file.fraud_result.score}</TableCell>
                          </TableRow>
                          <TableRow key={file.filename + "metadata"}>
                            <TableCell style={{ fontWeight: "bold" }}>
                              Metadata
                            </TableCell>
                            <TableCell>
                              {Object.keys(
                                file.fraud_result.sample_metadata
                              ).map((key, index) => (
                                <div>
                                  {key}:{" "}
                                  {file.fraud_result.sample_metadata[key]}
                                </div>
                              ))}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>
                  )}
                  {file.fraud_result && (
                    <Table
                      sx={{
                        [`& .${tableCellClasses.root}`]: {
                          padding: 0.5,
                        },
                        margin: 2,
                        marginBottom: 5,
                        maxWidth: "450px",
                      }}
                    >
                      <TableBody>
                        <TableRow key={file.filename + "metadata"}>
                          <TableCell>
                            INDICATORS
                            <br />
                            ------------------------------------------------------
                            {file.fraud_result.indicators.map((indicator) => (
                              <div key={indicator.indicator_id}>
                                <span style={{ fontWeight: "bold" }}>
                                  Title
                                </span>
                                : {indicator.title}
                                <br />
                                <span style={{ fontWeight: "bold" }}>
                                  Category
                                </span>
                                : {indicator.category}
                                <br />
                                <span style={{ fontWeight: "bold" }}>
                                  Origin
                                </span>
                                : {indicator.origin}
                                <br />
                                <span style={{ fontWeight: "bold" }}>
                                  Description
                                </span>
                                : {indicator.description}
                                <br />
                                -------------------------------------------------------
                                <br />
                              </div>
                            ))}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  )}
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </AccordionNoBorder>
      </Grid>
    );
  };

  return (
    <div>
      <LoadingDataComponent alreadyRendered={alreadyRendered} />
      <br />
      <Typography variant="h5">
        Admin Panel - {tenant["First name"] + " " + tenant["Last name"]}
      </Typography>
      <br />

      <Button
        variant="contained"
        color="secondary"
        style={{
          borderRadius: 4,
          marginBottom: 4
        }}
        onClick={sendInvitationEmail}
        disabled={inviteSent}
      >
        {t("RESEND EMAIL INVITE TO ") + tenant['Email']}
      </Button>
      <br />
      <Button
        variant="contained"
        color="secondary"
        style={{
          borderRadius: 4,
          marginBottom: 4,
        }}
        onClick={resetFlow}
        disabled={flowResetting}
      >
        {t("RESET FLOW")}
      </Button>

      {householdTenants.length > 1 && (
        <Card sx={cardStyle}>
          <Typography
            sx={{ fontSize: 18, fontWeight: "bold", marginBottom: 2 }}
          >
            Co-Tenants
          </Typography>

          <Table
            sx={{
              marginBottom: 2,
              padding: 5,
            }}
          >
            <TableBody>
              {householdTenants
                .filter((tenant) => tenant.properize_id !== properize_id)
                .map((tenant) => (
                  <TableRow>
                    <TableCell align="left">
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: 14,
                        }}
                      >
                        {tenant["First name"]}{" "}
                        {tenant["Last name"]}
                      </Typography>
                    </TableCell>

                    <TableCell align="right">
                      <Button
                        variant="contained"
                        component="label"
                        color="primary"
                        onClick={() =>
                          window.location.assign(
                            "/admin_panel?properize_id=" + tenant.properize_id
                          )
                        }
                      >
                        Admin Panel
                      </Button>
                    </TableCell>

                    <TableCell align="right">
                      <Button
                        variant="contained"
                        component="label"
                        color="secondary"
                        onClick={() =>
                          window.open("/admin_panel?properize_id=" + tenant.properize_id)
                        }
                      >
                        Admin Panel New Window
                      </Button>
                    </TableCell>

                    <TableCell align="right">
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: 14,
                        }}
                      >
                        <RenderStatus status={tenant["Status"]} />
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Card>
      )}

      <Card sx={cardStyle}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          spacing={5}
          wrap="nowrap"
        >
          <Grid item>
            <Typography
              sx={{ fontSize: 18, fontWeight: "bold", marginBottom: 2 }}
            >
              Personal Data
            </Typography>
            <Table
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  padding: 0.5,
                },
                [`& .${tableRowClasses.root}`]: {
                  height: "40px",
                },
                minWidth: "625px",
              }}
            >
              <TableBody>
                {personalDataObject.map((element) => (
                  <TableRow key={element.key}>
                    <TableCell align="left">
                      <Typography
                        sx={{
                          fontSize: 16,
                        }}
                      >
                        {element.key}
                      </Typography>
                    </TableCell>

                    <TableCell align="left">
                      <Typography
                        sx={{
                          fontSize: 16,
                        }}
                      >
                        {element.value}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell align="left">
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: 14,
                      }}
                    >
                      Parent request
                    </Typography>
                  </TableCell>

                  <TableCell align="left">
                    <TextField
                      value={parentRequest}
                      onChange={(e) => setParentRequest(e.target.value)}
                      fullWidth
                      error={!parentRequest}
                      helperText={parentRequest ? false : "It cannot be empty"}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: 14,
                      }}
                    >
                      Current Address
                    </Typography>
                  </TableCell>

                  <TableCell align="left">
                    <TextField
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      fullWidth
                      error={!address}
                      helperText={address ? false : "It cannot be empty"}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: 14,
                      }}
                    >
                      Current number
                    </Typography>
                  </TableCell>

                  <TableCell align="left">
                    <TextField
                      value={houseNumber}
                      onChange={(e) => setHouseNumber(e.target.value)}
                      fullWidth
                      error={!houseNumber}
                      helperText={houseNumber ? false : "It cannot be empty"}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: 14,
                      }}
                    >
                      Post Code
                    </Typography>
                  </TableCell>

                  <TableCell align="left">
                    <TextField
                      value={postCode}
                      onChange={(e) => setPostCode(e.target.value)}
                      fullWidth
                      error={!postCode}
                      helperText={postCode ? false : "It cannot be empty"}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: 14,
                      }}
                    >
                      City
                    </Typography>
                  </TableCell>

                  <TableCell align="left">
                    <TextField
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      fullWidth
                      error={!city}
                      helperText={city ? false : "It cannot be empty"}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <Grid item>
            <img
              loading="lazy"
              src={tenant["selfie"]}
              alt="selfie"
              height="400"
            />
          </Grid>
        </Grid>
        <TextField
          value={bio}
          onChange={(e) => setBio(e.target.value)}
          label="Bio"
          rows={4}
          multiline
          fullWidth
          sx={{ marginTop: 4 }}
        />

        <TextField
          value={miscellaneous}
          onChange={(e) => setMiscellaneous(e.target.value)}
          label="Miscellaneous"
          rows={4}
          multiline
          fullWidth
          sx={{ marginTop: 4 }}
        />

        <Button
          variant="contained"
          component="label"
          color="primary"
          sx={{
            minWidth: "300px",
            marginTop: 2,
          }}
          onClick={updateAddress}
          disabled={
            disUpdateAddress || !address || !houseNumber || !postCode || !city
          }
        >
          Update Personal Data
        </Button>
      </Card>

      <Grid style={{ maxWidth: "1500px", width: "100%" }} sx={accordionStyle}>
        <AccordionNoBorder
          expanded={houseHoldExpanded}
          onChange={() => setHouseHoldExpanded(!houseHoldExpanded)}
        >
          <AccordionSummary sx={{ bgcolor: "#ffffff" }}>
            <Typography p={1} sx={{ fontSize: 18, fontWeight: "bold" }}>
              Household data
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container direction="row" spacing={6} px={2} wrap="nowrap">
              <Grid item>
                <Table
                  sx={{
                    [`& .${tableCellClasses.root}`]: {
                      padding: 0.5,
                    },
                    [`& .${tableRowClasses.root}`]: {
                      height: "40px",
                    },
                    minWidth: "500px",
                  }}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell align="left">
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: 14,
                          }}
                        >
                          Property Address
                        </Typography>
                      </TableCell>

                      <TableCell align="left">
                        <TextField
                          value={propertyAddress}
                          onChange={(e) => setPropertyAddress(e.target.value)}
                          fullWidth
                          error={!propertyAddress}
                          helperText={
                            propertyAddress ? false : "It cannot be empty"
                          }
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: 14,
                          }}
                        >
                          Property Number
                        </Typography>
                      </TableCell>

                      <TableCell align="left">
                        <TextField
                          value={propertyNumber}
                          onChange={(e) => setPropertyNumber(e.target.value)}
                          fullWidth
                          error={!propertyNumber}
                          helperText={
                            propertyNumber ? false : "It cannot be empty"
                          }
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left">
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: 14,
                          }}
                        >
                          Property City
                        </Typography>
                      </TableCell>

                      <TableCell align="left">
                        <TextField
                          value={propertyCity}
                          onChange={(e) => setPropertyCity(e.target.value)}
                          fullWidth
                          error={!propertyCity}
                          helperText={
                            propertyCity ? false : "It cannot be empty"
                          }
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left">
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: 14,
                          }}
                        >
                          Post Code
                        </Typography>
                      </TableCell>

                      <TableCell align="left">
                        <TextField
                          value={propertyPostCode}
                          onChange={(e) => setPropertyPostCode(e.target.value)}
                          fullWidth
                          error={!propertyPostCode}
                          helperText={
                            propertyPostCode ? false : "It cannot be empty"
                          }
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
              <Grid item>
                <Table
                  sx={{
                    [`& .${tableCellClasses.root}`]: {
                      padding: 0.5,
                    },
                    [`& .${tableRowClasses.root}`]: {
                      height: "40px",
                    },
                    minWidth: "500px",
                  }}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell align="left">
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: 14,
                          }}
                        >
                          Property Price
                        </Typography>
                      </TableCell>

                      <TableCell align="left">
                        <TextField
                          value={propertyPrice}
                          onChange={(e) => setPropertyPrice(e.target.value)}
                          type="number"
                          fullWidth
                          error={!propertyPrice}
                          helperText={
                            propertyPrice ? false : "It cannot be empty or zero"
                          }
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left">
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: 14,
                          }}
                        >
                          Rating
                        </Typography>
                      </TableCell>

                      <TableCell align="left">
                        <TextField
                          value={rating}
                          onChange={(e) => setRating(e.target.value)}
                          select
                          fullWidth
                          style={{ margin: 1, backgroundColor: "#FBF4E9" }}
                        >
                          {ratings}
                        </TextField>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left">
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: 14,
                          }}
                        >
                          Status
                        </Typography>
                      </TableCell>

                      <TableCell align="left">
                        <TextField
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                          select
                          fullWidth
                          style={{ margin: 1, backgroundColor: "#FBF4E9" }}
                        >
                          {statuses}
                        </TextField>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </Grid>

            <Button
              variant="contained"
              component="label"
              color="primary"
              sx={{
                minWidth: "300px",
                marginTop: 3,
                marginX: 2,
              }}
              onClick={updateHouseholdData}
              disabled={disHousehold}
            >
              Update Household Values
            </Button>

            <Button
              variant="contained"
              component="label"
              color="secondary"
              sx={{
                minWidth: "300px",
                marginTop: 3,
                marginX: 2,
              }}
              onClick={archiveDossier}
              disabled={tenant['ArchiveRemove'] === 1 || tenant['ArchiveRemove'] === '1'}
            >
              Archive Dossier
            </Button>

            <Button
              variant="contained"
              component="label"
              color="secondary"
              sx={{
                minWidth: "300px",
                marginTop: 3,
                marginX: 2,
              }}
              onClick={deleteDossier}
              disabled={tenant['ArchiveRemove'] === 2 || tenant['ArchiveRemove'] === '2'}
            >
              Delete Dossier
            </Button>
          </AccordionDetails>
        </AccordionNoBorder>
      </Grid>

      <Grid style={{ maxWidth: "1500px", width: "100%" }} sx={accordionStyle}>
        <AccordionNoBorder
          expanded={idExpanded}
          onChange={() => setIdExpanded(!idExpanded)}
        >
          <AccordionSummary sx={{ bgcolor: "#ffffff" }}>
            {" "}
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography p={1} sx={{ fontSize: 18, fontWeight: "bold" }}>
                  ID verification
                </Typography>
              </Grid>
              <Grid item align="center">
                <RenderStatus status={tenant["CheckA_Status"]} />
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            {tenant["CheckA_Detail"] && tenant["CheckA_Detail"] !== "[]" && (
              <Grid
                align="center"
                mb={2}
                style={{ backgroundColor: "#ffc9bb" }}
              >
                <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                  There are warnings:
                </Typography>

                {JSON.parse(tenant["CheckA_Detail"]).map((warning) => (
                  <div>{warning}</div>
                ))}
              </Grid>
            )}
            <Grid container direction="row" spacing={6} px={2} wrap="nowrap">
              <Grid item>
                <Table
                  sx={{
                    [`& .${tableCellClasses.root}`]: {
                      padding: 0.5,
                    },
                    [`& .${tableRowClasses.root}`]: {
                      height: "40px",
                    },
                    minWidth: "500px",
                  }}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell align="left">
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: 14,
                          }}
                        >
                          ID First Name
                        </Typography>
                      </TableCell>

                      <TableCell align="left">
                        <TextField
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          fullWidth
                          error={!firstName}
                          helperText={firstName ? false : "It cannot be empty"}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: 14,
                          }}
                        >
                          ID Last Name
                        </Typography>
                      </TableCell>

                      <TableCell align="left">
                        <TextField
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          fullWidth
                          error={!lastName}
                          helperText={lastName ? false : "It cannot be empty"}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left">
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: 14,
                          }}
                        >
                          Gender
                        </Typography>
                      </TableCell>

                      <TableCell align="left">
                        <TextField
                          value={gender}
                          onChange={(e) => setGender(e.target.value)}
                          select
                          fullWidth
                          style={{ margin: 1, backgroundColor: "#FBF4E9" }}
                        >
                          {genders}
                        </TextField>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left">
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: 14,
                          }}
                        >
                          Date of Birth
                        </Typography>
                      </TableCell>

                      <TableCell align="left">
                        <TextField
                          value={dateOfBirth}
                          onChange={(e) => setDateOfBirth(e.target.value)}
                          type="date"
                          fullWidth
                          error={!dateOfBirth}
                          helperText={
                            dateOfBirth ? false : "It cannot be empty"
                          }
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left">
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: 14,
                          }}
                        >
                          Place of Birth
                        </Typography>
                      </TableCell>

                      <TableCell align="left">
                        <TextField
                          value={placeOfBirth}
                          onChange={(e) => setPlaceOfBirth(e.target.value)}
                          fullWidth
                          error={!placeOfBirth}
                          helperText={
                            placeOfBirth ? false : "It cannot be empty"
                          }
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: 14,
                          }}
                        >
                          Nationality
                        </Typography>
                      </TableCell>

                      <TableCell align="left">
                        <TextField
                          value={nationality}
                          onChange={(e) => setNationality(e.target.value)}
                          fullWidth
                          error={!nationality}
                          helperText={
                            nationality ? false : "It cannot be empty"
                          }
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left">
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: 14,
                          }}
                        >
                          Document Type
                        </Typography>
                      </TableCell>

                      <TableCell align="left">
                        <TextField
                          value={documentType}
                          onChange={(e) => setDocumentType(e.target.value)}
                          select
                          fullWidth
                          style={{ margin: 1, backgroundColor: "#FBF4E9" }}
                        >
                          {documentTypes}
                        </TextField>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left">
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: 14,
                          }}
                        >
                          Document Number
                        </Typography>
                      </TableCell>

                      <TableCell align="left">
                        <TextField
                          value={documentNumber}
                          onChange={(e) => setDocumentNumber(e.target.value)}
                          fullWidth
                          error={!documentNumber}
                          helperText={
                            documentNumber ? false : "It cannot be empty"
                          }
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left">
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: 14,
                          }}
                        >
                          Date of Expiry
                        </Typography>
                      </TableCell>

                      <TableCell align="left">
                        <TextField
                          value={dateofExpiry}
                          onChange={(e) => setDateOfExpiry(e.target.value)}
                          type="date"
                          fullWidth
                          error={!dateofExpiry}
                          helperText={
                            dateofExpiry ? false : "It cannot be empty"
                          }
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left">
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: 14,
                          }}
                        >
                          Issuing Authority
                        </Typography>
                      </TableCell>

                      <TableCell align="left">
                        <TextField
                          value={issuingAuthority}
                          onChange={(e) => setIssuingAuthority(e.target.value)}
                          fullWidth
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left">
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: 14,
                          }}
                        >
                          CheckA_Status
                        </Typography>
                      </TableCell>

                      <TableCell align="left">
                        <TextField
                          value={CheckA_Status}
                          onChange={(e) => setCheckA_Status(e.target.value)}
                          select
                          fullWidth
                          style={{ margin: 1, backgroundColor: "#FBF4E9" }}
                        >
                          {statuses}
                        </TextField>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
              <Grid item>
                <Grid container direction="column" wrap="nowrap">
                  ID Front:
                  <br />
                  <img
                    loading="lazy"
                    src={frontPhoto}
                    alt="front"
                    height="250"
                  />
                  <br />
                  <Button
                    variant="contained"
                    component="label"
                    color="secondary"
                    sx={{
                      minWidth: "250px",
                      maxWidth: "450px",
                    }}
                  >
                    Overwrite front
                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      onChange={overWriteFront}
                    />
                  </Button>
                  <br />
                  <Button
                    variant="outlined"
                    component="label"
                    color="primary"
                    sx={{
                      minWidth: "250px",
                      maxWidth: "450px",
                    }}
                    onClick={downloadOriginalFront}
                  >
                    Download Raw Picture
                  </Button>
                </Grid>

                {backPhoto && (
                  <Grid container direction="column" wrap="nowrap" mt={5}>
                    ID Back:
                    <br />
                    <img
                      loading="lazy"
                      src={backPhoto}
                      alt="back"
                      height="250"
                    />
                    <br />
                    <Button
                      variant="contained"
                      component="label"
                      color="secondary"
                    >
                      Overwrite back
                      <input
                        type="file"
                        accept="image/*"
                        hidden
                        onChange={overWriteBack}
                      />
                    </Button>
                    <br />
                    <Button
                      variant="outlined"
                      component="label"
                      color="primary"
                      onClick={downloadOriginalBack}
                    >
                      Download Raw Picture
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid p={2}>
              <Button
                variant="contained"
                component="label"
                color="primary"
                sx={{
                  minWidth: "300px",
                  marginTop: 4,
                }}
                onClick={updateIdentityData}
                disabled={
                  disIdentity ||
                  !firstName ||
                  !lastName ||
                  !gender ||
                  !documentNumber ||
                  !dateOfBirth ||
                  !placeOfBirth ||
                  !nationality ||
                  !documentType ||
                  !dateofExpiry
                }
              >
                Update Identity Values
              </Button>
            </Grid>
          </AccordionDetails>
        </AccordionNoBorder>
      </Grid>

      <Grid style={{ maxWidth: "1500px", width: "100%" }} sx={accordionStyle}>
        <AccordionNoBorder
          expanded={incomeExpanded}
          onChange={() => setIncomeExpanded(!incomeExpanded)}
        >
          <AccordionSummary sx={{ bgcolor: "#ffffff" }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography p={1} sx={{ fontSize: 18, fontWeight: "bold" }}>
                  Income verification
                </Typography>
              </Grid>
              <Grid item align="center">
                <RenderStatus status={tenant["CheckB_Status"]} />
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container direction="row" spacing={3} px={2} wrap="nowrap">
              <Grid item>
                {tenant["Income source"] === "No income" && (
                  <Typography>No income reported</Typography>
                )}
                {tenant["Income source"] === "Open Banking" && (
                  <div>
                    <Table
                      sx={{
                        [`& .${tableCellClasses.root}`]: {
                          padding: 0.5,
                        },
                        [`& .${tableRowClasses.root}`]: {
                          height: "40px",
                        },
                        minWidth: "500px",
                      }}
                    >
                      <TableBody>
                        {OBIncomeVerificationObject.map((element) => (
                          <TableRow key={element.key}>
                            <TableCell align="left">
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  fontSize: 14,
                                }}
                              >
                                {element.key}
                              </Typography>
                            </TableCell>

                            <TableCell align="left">
                              <Typography
                                sx={{
                                  fontSize: 14,
                                }}
                              >
                                {element.value}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>

                    <br />
                    <Button
                      variant="outlined"
                      component="label"
                      color="primary"
                      onClick={downloadTransactionsExcel}
                    >
                      Download Transactions Excel
                    </Button>

                    <Typography style={{ fontWeight: "bold" }} mt={8}>
                      Gross salary calculator
                    </Typography>

                    <TextField
                      value={netSalary}
                      onChange={(e) => setNetSalary(e.target.value)}
                      label="Monthly net income"
                      sx={{ marginTop: 3 }}
                    />

                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isTaxRelief}
                            onChange={() => setIsTaxRelief(!isTaxRelief)}
                          />
                        }
                        label={"30% tax ruling"}
                      />
                    </FormGroup>

                    <Button
                      variant="outlined"
                      component="label"
                      color="primary"
                      onClick={calculateGross}
                      sx={{ marginTop: 3 }}
                    >
                      Calculate Gross
                    </Button>

                    {grossIncome && (
                      <Typography sx={{ marginTop: 3 }}>
                        Monthly gross income is: {grossIncome}
                      </Typography>
                    )}
                  </div>
                )}

                {tenant["Income source"] === "Self reported" && (
                  <div>
                    <Table
                      sx={{
                        [`& .${tableCellClasses.root}`]: {
                          padding: 0.5,
                        },
                        [`& .${tableRowClasses.root}`]: {
                          height: "40px",
                        },
                        minWidth: "500px",
                      }}
                    >
                      <TableBody>
                        {SRIncomeVerificationObject.map((element) => (
                          <TableRow key={element.key}>
                            <TableCell align="left">
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  fontSize: 14,
                                }}
                              >
                                {element.key}
                              </Typography>
                            </TableCell>

                            <TableCell align="left">
                              <Typography
                                sx={{
                                  fontSize: 14,
                                  backgroundColor:
                                    element.value === "Yes (Business)"
                                      ? "#ffc9bb"
                                      : null,
                                }}
                              >
                                {element.value}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>

                    <Typography style={{ fontWeight: "bold" }} mt={8}>
                      Gross salary calculator
                    </Typography>

                    <TextField
                      value={netSalary}
                      onChange={(e) => setNetSalary(e.target.value)}
                      label="Monthly net income"
                      sx={{ marginTop: 3 }}
                    />

                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isTaxRelief}
                            onChange={() => setIsTaxRelief(!isTaxRelief)}
                          />
                        }
                        label={"30% tax ruling"}
                      />
                    </FormGroup>

                    <Button
                      variant="outlined"
                      component="label"
                      color="primary"
                      onClick={calculateGross}
                      sx={{ marginTop: 3 }}
                    >
                      Calculate Gross
                    </Button>

                    {grossIncome && (
                      <Typography sx={{ marginTop: 3 }}>
                        Monthly gross income is: {grossIncome}
                      </Typography>
                    )}
                  </div>
                )}

                {tenant["GPT response"] && (
                  <Grid>
                    <Typography sx={{ marginTop: 5, fontWeight: "bold" }}>
                      ChatGPT Response
                    </Typography>
                    {Object.entries(JSON.parse(tenant["GPT response"])).map(
                      ([key, value]) => (
                        <div>
                          <Typography
                            sx={{
                              marginTop: 3,
                              fontSize: 14,
                              fontWeight: "bold",
                            }}
                          >
                            - {key}
                          </Typography>
                          <Typography
                            sx={{
                              marginTop: 1,
                              whiteSpace: "pre-line",
                              fontSize: 14,
                            }}
                          >
                            {value}
                          </Typography>
                        </div>
                      )
                    )}
                  </Grid>
                )}

                {tenant["income_conclusion_en"] && (
                  <Grid mt={5}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Income Conclusion (English)
                    </Typography>
                    <Typography sx={{ marginTop: 1, fontSize: 14 }}>
                      {tenant["income_conclusion_en"]}
                    </Typography>
                  </Grid>
                )}
                {tenant["income_conclusion_nl"] && (
                  <Grid mt={5}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Income Conclusion (Dutch)
                    </Typography>
                    <Typography sx={{ marginTop: 1, fontSize: 14 }}>
                      {tenant["income_conclusion_nl"]}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              {tenant["Income source"] !== "No income" && (
                <Grid item>
                  <Table
                    sx={{
                      [`& .${tableCellClasses.root}`]: {
                        padding: 0.5,
                      },
                      [`& .${tableRowClasses.root}`]: {
                        height: "40px",
                      },
                      minWidth: "625px",
                    }}
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell align="left">
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: 14,
                            }}
                          >
                            Type of verification
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <TextField
                            value={typeOfVerification}
                            onChange={(e) => setTypeOfVerification(e.target.value)}
                            select
                            fullWidth
                            style={{ margin: 1, backgroundColor: "#FBF4E9" }}
                          >
                            {verificationTypes}
                          </TextField>
                        </TableCell>

                      </TableRow>

                      <TableRow>
                        <TableCell align="left">
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: 14,
                            }}
                          >
                            WhatToDo
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <TextField
                            value={whatToDo}
                            onChange={(e) => setWhatToDo(e.target.value)}
                            select
                            fullWidth
                            style={{ margin: 1, backgroundColor: "#FBF4E9" }}
                          >
                            {incomeSources}
                          </TextField>
                        </TableCell>

                      </TableRow>

                      <TableRow>
                        <TableCell align="left">
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: 14,
                            }}
                          >
                            Employer
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <TextField
                            value={employer}
                            onChange={(e) => setEmployer(e.target.value)}
                            fullWidth
                            error={!employer}
                            helperText={employer ? false : "It cannot be empty"}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: 14,
                            }}
                          >
                            True income median
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <TextField
                            value={trueIncome}
                            onChange={(e) => setTrueIncome(e.target.value)}
                            fullWidth
                            error={
                              trueIncome === "" || trueIncome === undefined
                            }
                            helperText={
                              trueIncome === "" || trueIncome === undefined
                                ? "It cannot be empty"
                                : false
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: 14,
                            }}
                          >
                            IBAN list
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <TextField
                            value={accountNumber}
                            onChange={(e) => setAccountNumber(e.target.value)}
                            fullWidth
                          />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell align="left">
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: 14,
                            }}
                          >
                            CheckB_Status
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <TextField
                            value={CheckB_Status}
                            onChange={(e) => setCheckB_Status(e.target.value)}
                            select
                            fullWidth
                            style={{ margin: 1, backgroundColor: "#FBF4E9" }}
                          >
                            {statuses}
                          </TextField>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell align="left">
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: 14,
                            }}
                          >
                            Fraud Risk
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <TextField
                            value={fraudIndicator}
                            onChange={(e) => setFraudIndicator(e.target.value)}
                            select
                            fullWidth
                            style={{ margin: 1, backgroundColor: "#FBF4E9" }}
                          >
                            {fraudIndicators}
                          </TextField>
                        </TableCell>

                      </TableRow>
                    </TableBody>
                  </Table>

                  <Button
                    variant="contained"
                    component="label"
                    color="primary"
                    sx={{
                      minWidth: "300px",
                      marginTop: 2,
                    }}
                    onClick={updateIncomeValues}
                    disabled={disUpdateIncome || trueIncome === "" || !employer}
                  >
                    Update Values
                  </Button>

                  <br /><br />
                  <Button
                    variant="contained"
                    component="label"
                    color="secondary"
                    sx={{
                      minWidth: "300px",
                      marginTop: 2,
                    }}
                    onClick={cleanFraudWarnings}
                    disabled={disFraudWarnings}
                  >
                    Clean fraud warnings
                  </Button>

                </Grid>
              )}
            </Grid>
          </AccordionDetails>
        </AccordionNoBorder>
      </Grid>

      <Grid style={{ maxWidth: "1500px", width: "100%" }} sx={accordionStyle}>
        <AccordionNoBorder
          expanded={creditCheckExpanded}
          onChange={() => setCreditCheckExpanded(!creditCheckExpanded)}
        >
          <AccordionSummary sx={{ bgcolor: "#ffffff" }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography p={1} sx={{ fontSize: 18, fontWeight: "bold" }}>
                  Credit Check
                </Typography>
              </Grid>
              <Grid item align="center">
                <RenderStatus status={tenant["CheckC_Status"]} />
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container direction="row" spacing={3} px={2} wrap="nowrap">
              <Grid item>
                <Table
                  sx={{
                    [`& .${tableRowClasses.root}`]: {
                      height: "40px",
                    },
                    minWidth: "500px",
                  }}
                >
                  <TableBody>
                    {creditCheckObject.map((element) => (
                      <TableRow key={element.key}>
                        <TableCell align="left">
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: 14,
                            }}
                          >
                            {element.key}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography
                            sx={{
                              fontSize: 14,
                            }}
                          >
                            {element.value}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>

            {tenant["EDR personal score"] === 0 &&
              !tenant["EDR conclusion"].includes("expat status") && (
                <Alert
                  severity="info"
                  color="error"
                  sx={{
                    marginX: 3,
                    marginTop: 3,
                  }}
                >
                  EDR Personal Score is 0. Odds are there is a mismatch between
                  Address and Post Code. Check in Google if {postCode} is the
                  correct post code for "{address} {houseNumber}, {city}". If
                  not, go to the top of the page, update the address and then
                  send a new EDR Request using the button underneath
                </Alert>
              )}

            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={sendEDRRequest}
              disabled={edrButtonDisabled}
              sx={{ marginTop: 2, marginX: 4 }}
            >
              Send EDR request
            </Button>
          </AccordionDetails>
        </AccordionNoBorder>
      </Grid>

      {documentsCard}

      <FraudDetectionCard />
      <br />
      <br />
      <Typography variant="h6">Complete report tools</Typography>

      <Grid container direction="row" alignItems="center" spacing={2} mt={1}>
        <Grid item>
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={closeDossier}
            disabled={completionButtonDis}
          >
            Complete dossier and send notification to client
          </Button>
        </Grid>

        {tenant['Tenant report'] && (<Grid item>
          <Button
            variant="contained"
            color="secondary"
            onClick={generateReport}
            disabled={completionButtonDis}
            style={{
              borderRadius: 0,
              fontSize: "16px",
            }}
          >
            {t("GENERATE REPORT")}
          </Button>
        </Grid>)}

        {reportURL && (
          <Grid item>
            <DownloadReportButton reportURL={reportURL} />
          </Grid>
        )}
      </Grid>

      <br />
      <br />
      <br />

      <form onSubmit={getPresignedURL}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h6">Generate presigned URL</Typography>
          </Grid>
          <Grid item>
            <TextField inputRef={urlToSignRef} label={"Enter URL"} fullWidth />
          </Grid>
          <Grid item>
            <Grid container direction="row" spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  size="large"
                  color="error"
                  type="submit"
                >
                  Generate URL
                </Button>
              </Grid>
              {presignedURL && (
                <Grid item>
                  <DownloadReportButton reportURL={presignedURL} />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </form>

      <Grid container direction="column" mt={4} spacing={3}>
        <Grid item>
          <Typography variant="h6">ChatGPT customer support</Typography>
        </Grid>
        <Grid item>
          <TextField
            inputRef={clientMessageRef}
            label="Client message"
            rows={6}
            multiline
            fullWidth
          />
        </Grid>
        <Grid item>
          <TextField
            inputRef={responseContentRef}
            label="Response content"
            rows={6}
            multiline
            fullWidth
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={sendGPTCompletion}
            disabled={gptCompletionButtonDisabled}
          >
            Submit
          </Button>
        </Grid>
        <Grid item>
          {"Answer:"}
          <br />
          {gptResponse}
        </Grid>
      </Grid>

    </div>
  );
};

const EnterPassDialog = ({ enterPassDialogOpen, setEnterPassDialogOpen }) => {
  const [password, setPassword] = useState("")
  const [wrongPassword, setWrongPassword] = useState(false)


  function changePassword(e) {
    setPassword(e.target.value)
  }

  function handleSubmit(e) {
    e.preventDefault();
    setEnterPassDialogOpen(password !== process.env.REACT_APP_ADMIN_PANEL_PASSWORD)
    setWrongPassword(password !== process.env.REACT_APP_ADMIN_PANEL_PASSWORD)

  }
  return (
    <Dialog open={enterPassDialogOpen}>
      <DialogContent>
        <Typography sx={{ margin: 2, fontSize: 24, fontWeight: "bold" }}>
          Log In
        </Typography>

        <form onSubmit={handleSubmit}>

          <TextField
            label='e-mail'
            type='email'
            fullWidth
            required
          />

          <TextField
            label='password'
            value={password}
            type='password'
            onChange={changePassword}
            fullWidth
            required
            style={{ marginTop: 10 }}
          />

          <Button
            type="submit"
            color="secondary"
            variant="contained"
            fullWidth
            style={{
              borderRadius: 16,
              fontSize: "18px",
              marginTop: 30
            }}
          >
            SIGN IN
          </Button>

          {wrongPassword && <p style={{ color: "red" }}>Wrong password. Try again</p>}

        </form>
      </DialogContent>
    </Dialog>
  );
};

const AdminPanel = ({ url }) => {

  const [enterPassDialogOpen, setEnterPassDialogOpen] = useState(true)

  return (
    <div>
      <EnterPassDialog enterPassDialogOpen={enterPassDialogOpen} setEnterPassDialogOpen={setEnterPassDialogOpen} />
      {!enterPassDialogOpen && <AdminPanelLogged url={url} />}
    </div>
  )
};

export default AdminPanel;
