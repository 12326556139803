import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

import { Player } from "@lottiefiles/react-lottie-player";

import ImagePreview from "../components/ImagePreview";
import { uploadPictureToS3Directly } from "../utils/uploadToS3";

import passportLottie from "../lottie_files/passport_lottie.json";
import phoneToComputer from "../lottie_files/phone_to_computer.json";
import idFront from "../lottie_files/id-front.json";
import idBack from "../lottie_files/id-back.json";
import SelfieLottie from "../lottie_files/selfie.json";
import WarningLottie from "../lottie_files/lf20_dVJMow.json";

const CapturePicture = ({
  setInputFile,
  headerText,
  activeCamera,
  lottieLink,
}) => {
  const { t } = useTranslation();

  const [cameraOpened, setCameraOpened] = useState(false);
  const [file, setFile] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleFileSelect = (event) => {
    setFile(event.target.files[0]);
  };

  return (
    <Grid align="center">
      {file ? (
        <Grid container spacing={1} direction="column">
          <Grid item>
            <Typography variant="h6" color="black" sx={{ margin: 2 }}>
              {t(
                headerText === "Take a selfie"
                  ? "Take a selfie"
                  : "Capture ID Document"
              )}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="p" color="black" sx={{ margin: 2 }}>
              {t(
                headerText === "Take a selfie"
                  ? "is face clearly visible"
                  : "is id document visible"
              )}
            </Typography>
          </Grid>
          <Grid item>
            <ImagePreview
              file={file}
              setFile={setFile}
              setInputFile={setInputFile}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Typography variant="h6" color="black" sx={{ margin: 2 }}>
              {t(headerText)}
            </Typography>
          </Grid>

          <Grid item style={{ width: "100%" }}>
            <div
              style={{
                maxWidth: "325px",
                maxHeight: "500px",
                marginBottom: "40px",
              }}
            >
              <Player autoplay loop src={lottieLink} />
            </div>
            <ul align="left">
              <li>
                <Typography variant="p" color="textSecondary">
                  {t(
                    headerText === "Take a selfie"
                      ? "selfie_instructions_1"
                      : "id_document_instructions_1"
                  )}
                </Typography>
              </li>
              <li>
                <Typography variant="p" color="textSecondary">
                  {t(
                    headerText === "Take a selfie"
                      ? "selfie_instructions_2"
                      : "id_document_instructions_2"
                  )}
                </Typography>
              </li>
              <li>
                <Typography variant="p" color="textSecondary">
                  {t(
                    headerText === "Take a selfie"
                      ? "selfie_instructions_3"
                      : "id_document_instructions_3"
                  )}
                </Typography>
              </li>
              <li>
                <Typography variant="p" color="textSecondary">
                  {t(
                    headerText === "Take a selfie"
                      ? "selfie_instructions_4"
                      : "id_document_instructions_4"
                  )}
                </Typography>
              </li>
              {headerText !== "Take a selfie" && (
                <li>
                  <Typography variant="p" color="textSecondary">
                    {t("id_document_instructions_5")}
                  </Typography>
                </li>
              )}
            </ul>
          </Grid>

          <Grid item mt={3} align="center">
            <Button
              variant="contained"
              component="label"
              color="secondary"
              sx={{
                minWidth: "250px",
                maxWidth: "450px",
              }}
            >
              {t("OK, I got it")}
              <input
                type="file"
                accept="image/*"
                hidden
                capture={activeCamera}
                onChange={handleFileSelect}
              />
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const PictureScreenDialog = ({
  pictureScreenDialogOpen,
  setPictureScreenDialogOpen,
  setShowDocumentType,
}) => {
  const { t } = useTranslation();
  function onOKPictureScreen() {
    setPictureScreenDialogOpen(false);
    setShowDocumentType(false);
  }
  return (
    <Dialog open={pictureScreenDialogOpen}>
      <DialogContent>
        <Grid container direction="column" alignItems="center" wrap="nowrap">
          <Grid item>
            <Player
              keepLastFrame
              src={WarningLottie}
              style={{ height: "150px", width: "150px", margin: 2 }}
            />
          </Grid>
          <Grid item>
            <DialogContentText>
              {t("picture_of_screen_not_allowed")}
            </DialogContentText>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Button
            variant="contained"
            color="secondary"
            onClick={onOKPictureScreen}
            sx={{ marginTop: 7, minWidth: "200px" }}
          >
            OK
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const SelectDocumentType = ({ setShowDocumentType, setIsDoubleSideDoc }) => {
  const { t } = useTranslation();
  const [documentType, setDocumentType] = useState("");
  const [pictureScreenDialogOpen, setPictureScreenDialogOpen] = useState(false);

  function handleSubmit(event) {
    event.preventDefault();
    setIsDoubleSideDoc(documentType !== "Passport");
    setPictureScreenDialogOpen(true);
  }

  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="p" sx={{ margin: 2 }}>
        {t("Select your document")}
      </Typography>

      <RadioGroup
        value={documentType}
        onChange={(event) => setDocumentType(event.target.value)}
        sx={{ margin: 2 }}
      >
        <FormControlLabel
          value="Passport"
          control={<Radio required />}
          label={t("Passport")}
        />
        <FormControlLabel
          value="Identity Card"
          control={<Radio required />}
          label={t("Identity Card")}
        />
        <FormControlLabel
          value="Residence Permit"
          control={<Radio required />}
          label={t("Residence Permit")}
        />
      </RadioGroup>

      <Grid
        item
        sx={{ width: Math.max(0.35 * window.outerWidth, 325) }}
        align="left"
      >
        <Alert
          severity="info"
          color="error"
          sx={{
            marginTop: 2,
            marginBottom: 2,
          }}
        >
          <AlertTitle>{t("Please Read")}</AlertTitle>
          {t("driver_license_not_allowed")}
        </Alert>
      </Grid>

      <Button
        type="submit"
        variant="contained"
        sx={{
          minWidth: "250px",
          maxWidth: "300px",
          marginTop: 4,
          marginLeft: 1,
        }}
        color="secondary"
      >
        {t("next")}
      </Button>
      <PictureScreenDialog
        pictureScreenDialogOpen={pictureScreenDialogOpen}
        setPictureScreenDialogOpen={setPictureScreenDialogOpen}
        setShowDocumentType={setShowDocumentType}
      />
    </form>
  );
};

const LastScreen = ({
  guarantor_id,
  url,
  frontImage,
  backImage,
  selfie,
  setFrontImage,
  setBackImage,
  setSelfie,
  onCompleted,
}) => {
  const { t } = useTranslation();

  const [alreadyRendered, setAlreadyRendered] = useState(false);
  const [invalidDocumentDialog, setInvalidDocumentDialog] = useState(false);
  const [recognisedDocumentDialog, setRecognisedDocumentDialog] =
    useState(false);

  useEffect(() => {
    async function uploadPics() {
      // Upload front image
      await uploadPictureToS3Directly(guarantor_id, "front", url, frontImage);

      // Upload selfie
      await uploadPictureToS3Directly(guarantor_id, "selfie", url, selfie);

      // Upload back image if any
      if (backImage) {
        await uploadPictureToS3Directly(guarantor_id, "back", url, backImage);
      }
      let payload = {
        guarantor_id: guarantor_id,
        isBackPhoto: false,
      };
      if (backImage) {
        payload["isBackPhoto"] = true;
      }

      axios
        .post(url + "/auto_id_extraction_pipeline_guarantor", payload, {
          timeout: 700000,
        })
        .then((response) => {
          if (!response.data.isDocumentTypeValid) {
            setInvalidDocumentDialog(true);
          } else if (!response.data.isDocumentRecognized) {
            setRecognisedDocumentDialog(true);
          } else {
            onCompleted();
          }
          setAlreadyRendered(true);
        })
        .catch((err) => {
          onCompleted();
          setAlreadyRendered(true);
        });
    }
    uploadPics();
  }, []);

  const ExtractingDataComponent = () => {
    return (
      <Dialog open={!alreadyRendered}>
        <DialogContent>
          <Grid container direction="column" spacing={2}>
            <Grid item>{t("extracting_data")}</Grid>
            <Grid item align="center">
              <CircularProgress />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <div>
      <ExtractingDataComponent />
      <Dialog open={invalidDocumentDialog || recognisedDocumentDialog}>
        <DialogContent>
          <DialogContentText>
            {invalidDocumentDialog && t("This document is not accepted")}
            {recognisedDocumentDialog && t("This document was not recognized")}
            <br />
            <br />
            <Grid container justifyContent="center">
              <Button
                variant="contained"
                onClick={() => {
                  setFrontImage(null);
                  setBackImage(null);
                  setSelfie(null);
                  setInvalidDocumentDialog(false);
                  setRecognisedDocumentDialog(false);
                }}
              >
                OK
              </Button>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      {alreadyRendered && !invalidDocumentDialog && (
        <Grid align="center">
          <Typography variant="p" sx={{ margin: 2 }}>
            {t("id_verification_completed_mobile")}
          </Typography>
          <div style={{ maxWidth: "325px", maxHeight: "500px" }}>
            <Player autoplay loop src={phoneToComputer} />
          </div>
        </Grid>
      )}
    </div>
  );
};

export const DoneIDDialog = ({ isCompleted, setIsCompleted, guarantor_id }) => {
  const { t } = useTranslation();
  return (
    <Dialog open={isCompleted}>
      <DialogContent>
        <DialogContentText>
          {t("id_verification_completed")}
          <br />
          <br />
          <Grid container justifyContent="space-around">
            <Button
              variant="outlined"
              style={{ width: "47%" }}
              onClick={() => setIsCompleted(false)}
            >
              {t("repeat")}
            </Button>
            <Button
              variant="contained"
              style={{ width: "47%" }}
              color="primary"
              onClick={() =>
                window.location.assign(
                  "/guarantor_proof_of_income?guarantor_id=" + guarantor_id
                )
              }
            >
              {t("next")}
            </Button>
          </Grid>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export const InputPictures = ({ guarantor_id, url, onCompleted }) => {
  const [frontImage, setFrontImage] = useState(null);
  const [backImage, setBackImage] = useState(null);
  const [selfie, setSelfie] = useState(null);

  const [isDoubleSideDoc, setIsDoubleSideDoc] = useState(false);

  const [showDocumentType, setShowDocumentType] = useState(true);

  const showTakeFrontPic = !showDocumentType && !frontImage;
  const showTakeBackPic =
    !showDocumentType && frontImage && !backImage && isDoubleSideDoc;
  const showTakeSelfie =
    !showDocumentType &&
    frontImage &&
    (backImage || (!backImage && !isDoubleSideDoc)) &&
    !selfie;
  const showLastScreen =
    !showDocumentType &&
    frontImage &&
    (backImage || (!backImage && !isDoubleSideDoc)) &&
    selfie;

  return (
    <div>
      {showDocumentType && (
        <SelectDocumentType
          setShowDocumentType={setShowDocumentType}
          setIsDoubleSideDoc={setIsDoubleSideDoc}
        />
      )}
      {showTakeFrontPic && (
        <CapturePicture
          setInputFile={setFrontImage}
          headerText="Take picture of the front of your ID document"
          activeCamera="environment"
          lottieLink={isDoubleSideDoc ? idFront : passportLottie}
        />
      )}
      {showTakeBackPic && (
        <CapturePicture
          setInputFile={setBackImage}
          headerText="Take picture of the back of your ID document"
          activeCamera="environment"
          lottieLink={idBack}
        />
      )}
      {showTakeSelfie && (
        <CapturePicture
          setInputFile={setSelfie}
          headerText="Take a selfie"
          activeCamera="user"
          lottieLink={SelfieLottie}
        />
      )}
      {showLastScreen && (
        <LastScreen
          guarantor_id={guarantor_id}
          url={url}
          frontImage={frontImage}
          backImage={backImage}
          selfie={selfie}
          setFrontImage={setFrontImage}
          setBackImage={setBackImage}
          setSelfie={setSelfie}
          onCompleted={onCompleted}
        />
      )}
    </div>
  );
};
