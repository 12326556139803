import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";

import cookies from "js-cookie";
import axios from "axios";

import LoadingDataComponent from "./LoadingDataComponent";

const countriesData = [
  {
    name: "Austria",
    country_code: "at",
  },
  {
    name: "Belgium",
    country_code: "be",
  },
  {
    name: "Bulgaria",
    country_code: "bg",
  },
  {
    name: "Croatia",
    country_code: "hr",
  },
  {
    name: "Cyprus",
    country_code: "cy",
  },
  {
    name: "Czech Republic",
    country_code: "cz",
  },
  {
    name: "Denmark",
    country_code: "dk",
  },
  {
    name: "Estonia",
    country_code: "ee",
  },
  {
    name: "Finland",
    country_code: "fi",
  },
  {
    name: "France",
    country_code: "fr",
  },
  {
    name: "Germany",
    country_code: "de",
  },
  {
    name: "Greece",
    country_code: "gr",
  },
  {
    name: "Hungary",
    country_code: "hu",
  },
  {
    name: "Iceland",
    country_code: "is",
  },
  {
    name: "Ireland",
    country_code: "ie",
  },
  {
    name: "Italy",
    country_code: "it",
  },
  {
    name: "Latvia",
    country_code: "lv",
  },
  {
    name: "Lithuania",
    country_code: "lt",
  },
  {
    name: "Liechtenstein",
    country_code: "li",
  },
  {
    name: "Luxembourg",
    country_code: "lu",
  },
  {
    name: "Malta",
    country_code: "mt",
  },
  {
    name: "Netherlands",
    country_code: "nl",
  },
  {
    name: "Norway",
    country_code: "no",
  },
  {
    name: "Poland",
    country_code: "pl",
  },
  {
    name: "Portugal",
    country_code: "pt",
  },
  {
    name: "Romania",
    country_code: "ro",
  },
  {
    name: "Slovakia",
    country_code: "sk",
  },
  {
    name: "Slovenia",
    country_code: "si",
  },
  {
    name: "Spain",
    country_code: "es",
  },
  {
    name: "Sweden",
    country_code: "se",
  },
  {
    name: "United Kingdom",
    country_code: "gb",
  },
];

const Nordigen = ({ url, properize_id, whatToDo }) => {
  const { t } = useTranslation();

  const [accessToken, setAccessToken] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [banks, setBanks] = useState([]);
  const [institutionId, setInstitutionId] = useState("");
  const [bankAccountType, setBankAccountType] = useState("");

  const [alreadyRendered, setAlreadyRendered] = useState(true);

  let countriesDataSorted = countriesData.map((country) =>
    Object.assign({}, country, { name_translated: t(country.name) })
  );
  countriesDataSorted = countriesDataSorted.sort((a, b) =>
    a.name_translated.localeCompare(b.name_translated)
  );

  const countries = countriesDataSorted.map((country) => (
    <MenuItem key={country.country_code} value={country.country_code}>
      <Grid container direction="row" wrap="nowrap" spacing={1}>
        <Grid item>
          <span
            className={`flag-icon flag-icon-${country.country_code} mx-2`}
          ></span>
        </Grid>
        <Grid item>{t(country.name)}</Grid>
      </Grid>
    </MenuItem>
  ));

  useEffect(() => {
    async function fetchData() {
      let response = await axios.post(url + "/nordigen_generate_token", {
        properize_id: properize_id,
      });
      setAccessToken(response.data);
    }
    fetchData();
  }, []);

  async function handleCountryChange(event) {
    event.preventDefault();

    setCountryCode(event.target.value);
    setInstitutionId("");

    const payload = {
      access_token: accessToken,
      country_code: event.target.value,
    };

    const response = await axios.post(
      url + "/nordigen_get_institutions",
      payload
    );

    setBanks(
      response.data.map((bank) => ({
        ...bank,
        label: bank["name"],
      }))
    );
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setAlreadyRendered(false);

    const payload = {
      properize_id: properize_id,
      access_token: accessToken,
      institution_id: institutionId,
      language: cookies.get("i18next") || "en",
      bank_account_type: bankAccountType,
    };

    const response = await axios.post(
      url + "/nordigen_generate_bank_url",
      payload
    );

    window.location.assign(response.data);
  }

  return (
    <div>
      <Typography variant="p" color="textSecondary">
        {t("income_verification_paragraph1")}
      </Typography>
      <br />
      <br />
      <Typography variant="p" color="textSecondary">
        {t("income_verification_paragraph2")}
      </Typography>
      <br />

      {["Self-employed", "Entrepreneur"].includes(whatToDo) && (
        <Grid mt={3}>
          <Typography variant="p" color="textSecondary">
            {t("What type of bank account")}
          </Typography>

          <RadioGroup
            name="radio-buttons-group"
            value={bankAccountType}
            onChange={(event) => setBankAccountType(event.target.value)}
            style={{ marginTop: 5 }}
          >
            <FormControlLabel
              value="Personal"
              control={<Radio />}
              label={t("My personal bank account")}
            />
            <FormControlLabel
              value="Business"
              control={<Radio />}
              label={t("My business' bank account")}
            />
          </RadioGroup>

          {bankAccountType === "Business" && (
            <Alert
              severity="info"
              color="error"
              sx={{
                marginTop: 2,
              }}
            >
              <AlertTitle>{t("Please Read")}</AlertTitle>
              {t("We recommend that you connect your personal bank account")}
            </Alert>
          )}
        </Grid>
      )}
      <TextField
        value={countryCode}
        onChange={handleCountryChange}
        select
        label={t("select_country")}
        style={{
          margin: 1,
          marginTop: "30px",
          minWidth: "320px",
          maxWidth: "450px",
          backgroundColor: "#FBF4E9",
        }}
        fullWidth
      >
        {countries}
      </TextField>

      <div>
        <Autocomplete
          disablePortal
          key={countryCode}
          options={banks}
          style={{
            margin: 1,
            marginTop: "10px",
            minWidth: "320px",
            maxWidth: "450px",
            backgroundColor: "#FBF4E9",
          }}
          getOptionLabel={(option) => option.label}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
              key={option.id}
            >
              <img
                loading="lazy"
                height={30}
                width={30}
                src={option.logo}
                alt=""
              />
              {option.label}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("select_bank")}
              required
              InputLabelProps={{
                required: false,
              }}
            />
          )}
          onChange={(event, selectedBank) =>
            setInstitutionId(selectedBank ? selectedBank.id : "")
          }
        />
      </div>
      <Button
        variant="contained"
        color="secondary"
        size="large"
        type="submit"
        style={{
          minWidth: "320px",
          fontSize: "16px",
          marginTop: "30px",
        }}
        onClick={handleSubmit}
        disabled={!institutionId || (!bankAccountType && ["Self-employed", "Entrepreneur"].includes(whatToDo))}
      >
        {t("Submit")}
      </Button>
      <LoadingDataComponent alreadyRendered={alreadyRendered} />
    </div>
  );
};

export default Nordigen;
