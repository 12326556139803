import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import { Grid, ListItemIcon, Typography } from "@mui/material";

const SectionHeader = ({ Icon, text }) => {
  return (
    <table>
      <tbody>
        <TableRow
          sx={{
            [`& .${tableCellClasses.root}`]: {
              border: "none",
              padding: 0.75,
            },
          }}
        >
          <TableCell>
            <Grid container alignItems="center">
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
            </Grid>
          </TableCell>
          <TableCell>
            <Typography variant="h6" color="black">
              {text}
            </Typography>
          </TableCell>
        </TableRow>
      </tbody>
    </table>
  );
};

export default SectionHeader;
