import React from "react";
import { useTranslation } from "react-i18next";
import { Player } from "@lottiefiles/react-lottie-player";
import { Grid, Link, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import ProgressBar from "../components/ProgressBar";

const Error = () => {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const properize_id = searchParams.get("properize_id");

  return (
    <div>
      <ProgressBar completed={80} />
      <Grid container justifyContent="space-between" spacing={1}>
        <Grid item sm={6}>
          <Player
            autoplay
            loop
            src="https://assets10.lottiefiles.com/packages/lf20_LlRvIg.json"
          ></Player>
        </Grid>
        <Grid item sm={6}>
          <br />
          <br />
          <Typography variant="h4" color="textSecondary">
            {t("bank_not_available1")}
          </Typography>
          <br />
          <br />
          <Typography variant="h6" color="textSecondary">
            {t("bank_not_available2")}
          </Typography>
          <br />
          <br />
          <Typography variant="p" color="textSecondary">
            <Link
              href={"/income_verification?properize_id=" + properize_id}
              color="inherit"
            >
              {t("back_to_income_verification")}
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default Error;
