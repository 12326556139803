import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
} from "@mui/material";

import { useSearchParams } from "react-router-dom";

import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import ProgressBar from "../components/ProgressBar";
import SubmitBankStatement from "../components/SubmitBankStatement";
import SaveAndContinueLater from "../components/SaveAndContinueLater";

import { ReactComponent as Bank } from "../svg_logos/bank.svg";
import { ReactComponent as PDF } from "../svg_logos/pdf.svg";
import Nordigen from "../components/Nordigen";
import SectionHeader from "../components/SectionHeader";
import { IsFlowDeletedDialog } from "./WelcomePage";

const theme = createTheme({
  palette: {
    primary: {
      main: "#257cff",
    },
    secondary: {
      main: "#f45a2a",
    },
  },
  typography: {
    footer: {
      fontSize: 14,
    },
    title: {
      fontSize: 16,
      fontWeight: "bold",
    },
    subtitle: {
      fontSize: 14,
    },
  },
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        asterisk: { display: "none" }
      }
    },
  },
});

const IncomeVerification = ({ url, setShowLanguageSelection }) => {
  const [openBankingClicked, setOpenBankingClicked] = useState(false);
  const [manualUploadClicked, setManualUploadClicked] = useState(false);

  const [isDeleted, setIsDeleted] = useState(false);
  const [nameAgent, setNameAgent] = useState("");
  const [emailAgent, setEmailAgent] = useState("");
  const [incomeVerificationCompleted, setIncomeVerificationCompleted] = useState(false);

  const [whatToDo, setWhatToDo] = useState("");

  const [searchParams] = useSearchParams();
  const properize_id = searchParams.get("properize_id");

  const { t } = useTranslation();

  setShowLanguageSelection(true);

  const ShowIncomeVerificationCompleted = () => {
    return (
      <Dialog open={incomeVerificationCompleted === "Completed"}>
        <DialogContent>
          <DialogContentText>
            {t("income_verification_was_completed")}
            <br />
            <br />
            <Grid container justifyContent="space-around">
              <Button
                variant="outlined"
                color="primary"
                style={{ width: "47%" }}
                onClick={() => setIncomeVerificationCompleted(false)}
              >
                {t("repeat")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ width: "47%" }}
                onClick={() =>
                  window.location.assign(
                    "/optional_information?properize_id=" + properize_id
                  )
                }
              >
                {t("next")}
              </Button>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  };

  const defaultProps = {
    direction: "column",
    alignItems: "center",
    justifyContent: "space-around",
    bgcolor: "background.paper",
    m: 2,
    border: 2,
    borderColor: "#257cff",
    borderRadius: 8,
    style: { width: "15rem", height: "15rem" },
    px: { xs: 0, sm: 4 },
    py: { xs: 0, sm: 5 },
    sx: [
      {
        "&:hover": {
          color: "#f45a2a",
          cursor: "pointer",
        },
      },
    ],
  };

  const payload = {
    id: properize_id,
    database: "Tenants",
  };

  useEffect(() => {
    axios.post(url + "/get_data_from_airtable_table?step=income_verification", payload).then((response) => {
      setIncomeVerificationCompleted(response.data['CheckB_Status'] === "Completed");
      setWhatToDo(response.data['WhatToDo'])
      setIsDeleted(response.data["ArchiveRemove"] === "2");
      setNameAgent(response.data["Name agent"]);
      setEmailAgent(response.data["Email agent"]);
    });
  }, []);

  const handleOpenBanking = () => {
    setOpenBankingClicked(!openBankingClicked);
    setManualUploadClicked(false);
  };

  const handleManualUpload = () => {
    setOpenBankingClicked(false);
    setManualUploadClicked(!manualUploadClicked);
  };

  const ItemBox = ({ handleEvent, Title, Picture }) => {
    return (
      <Grid container {...defaultProps} onClick={handleEvent}>
        <Typography variant="title" align="center">
          {t(Title)}
        </Typography>
        <Picture
          width="100"
          height="100"
          style={{
            marginTop: 5,
          }}
        />
      </Grid>
    );
  };

  const OpenBankingBox = () => {
    const showItem = !manualUploadClicked;
    return (
      <div>
        <Grid container alignItems="center" direction="column">
          {showItem && (
            <ItemBox
              handleEvent={handleOpenBanking}
              Title={"open_banking_title"}
              Picture={Bank}
            />
          )}
          {openBankingClicked && (
            <Button
              variant="contained"
              color="primary"
              style={{ width: "47%" }}
              onClick={handleOpenBanking}
            >
              {t("back")}
            </Button>
          )}
        </Grid>
      </div>
    );
  };

  const ManualUploadBox = () => {
    const showItem = !openBankingClicked;
    return (
      <div>
        <Grid container alignItems="center" direction="column">
          {showItem && (
            <ItemBox
              handleEvent={handleManualUpload}
              Title={"manual_upload_title"}
              Picture={PDF}
            />
          )}
          {manualUploadClicked && (
            <Button
              variant="contained"
              color="primary"
              style={{ width: "47%" }}
              onClick={handleManualUpload}
            >
              {t("back")}
            </Button>
          )}
        </Grid>
      </div>
    );
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        <ShowIncomeVerificationCompleted />
        <ProgressBar completed={80} />
        <br />
        <SectionHeader Icon={AccountBalanceIcon} text={t("step_income_verification")} />
        <br />
        {!(openBankingClicked || manualUploadClicked) && (
          <div>
            <Typography variant="p" color="textSecondary">
              {t("income_verification_intro")}
              <br />
            </Typography>
            <Typography variant="p" color="textSecondary">
              {t("how_open_banking_works")}
            </Typography>
          </div>
        )}

        <Grid container alignItems="center">
          <OpenBankingBox />
          <ManualUploadBox />
        </Grid>

        <br />
        {openBankingClicked && (
          <Nordigen properize_id={properize_id} url={url} whatToDo={whatToDo} />
        )}
        {manualUploadClicked && (
          <SubmitBankStatement
            properize_id={properize_id}
            url={url}
            whatToDo={whatToDo}
          />
        )}
      </ThemeProvider>
      <SaveAndContinueLater properize_id={properize_id} url={url} />
      <IsFlowDeletedDialog isDeleted={isDeleted} nameAgent={nameAgent} emailAgent={emailAgent} />
    </div>
  );
};

export default IncomeVerification;
