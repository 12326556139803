import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";

import ListIcon from "@mui/icons-material/List";

import CircularProgressWithLabel from "../components/CircularProgressWithLabel";
import DropzoneReact from "../components/DropzoneReact";
import NoFileDialog from "../components/NoFileDialog";
import ProgressBar from "../components/ProgressBar";
import MessagePopup from "../components/MessagePopup";
import { uploadFilesToS3Directly } from "../utils/uploadToS3";
import unidecode from "unidecode"
import SectionHeader from "../components/SectionHeader";
import { IsFlowDeletedDialog } from "./WelcomePage";

const Miscellaneous = ({ url }) => {
  const [isDeleted, setIsDeleted] = useState(false);
  const [nameAgent, setNameAgent] = useState("");
  const [emailAgent, setEmailAgent] = useState("");

  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState(0);

  const [submitClicked, setSubmitClicked] = useState(false);

  const [checkBoxDoc, setCheckBoxDoc] = useState(false);
  const [multipleSourcesIncome, setMultipleSourcesIncome] = useState(false);
  const [showSourcesIncome, setShowSourcesIncome] = useState(false);

  const [message, setMessage] = useState(false);

  const [other, setOther] = useState("");

  const [searchParams] = useSearchParams();
  const properize_id = searchParams.get("properize_id");

  const { t } = useTranslation();

  useEffect(() => {
    const payload = {
      id: properize_id,
      database: "Tenants",
    };

    axios
      .post(url + "/get_data_from_airtable_table", payload)
      .then((response) => {
        setShowSourcesIncome(response.data["WhatToDo"] !== "No income");
        setIsDeleted(response.data["ArchiveRemove"] === "2");
        setNameAgent(response.data["Name agent"]);
        setEmailAgent(response.data["Email agent"]);
      });
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (checkBoxDoc && files.length === 0) {
      setSubmitClicked(true);
    } else {
      if (!checkBoxDoc) {
        setProgress(100);
      }
      handleSubmit2(event);
    }
  };

  const handleSubmit2 = async () => {
    setSubmitClicked(true);

    await uploadFilesToS3Directly(
      properize_id,
      files,
      "properize-miscellaneous",
      setProgress
    );

    const payload = {
      properize_id: properize_id,
      miscellaneous_text: other,
      multiple_sources_of_income: multipleSourcesIncome,
      fileNames: files.map((file) =>
        unidecode(file.file.name.replaceAll(" ", "_").replaceAll(",", "_"))
      ),
    };

    axios
      .post(url + "/miscellaneous_pipeline", payload, { timeout: 1500 })
      .then((response) => {
        window.location.assign("/completed?properize_id=" + properize_id);
      })
      .catch((err) => {
        window.location.assign("/completed?properize_id=" + properize_id);
      });
  };

  return (
    <div>
      <IsFlowDeletedDialog isDeleted={isDeleted} nameAgent={nameAgent} emailAgent={emailAgent} />
      <ProgressBar completed={90} />
      <br />
      <SectionHeader Icon={ListIcon} text={t("optional_info")} />
      <br />
      <Typography variant="p" color="black">
        {t("optional_info_paragraph")}
      </Typography>
      <br />
      <br />
      <form onSubmit={handleSubmit}>
        <Box color="black" mt={1}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkBoxDoc}
                  onChange={() => setCheckBoxDoc(!checkBoxDoc)}
                />
              }
              label={t("upload_miscellaneous")}
            />
          </FormGroup>
        </Box>
        {checkBoxDoc && (
          <div>
            <DropzoneReact files={files} setFiles={setFiles} acceptedTypes="application/*, image/*" />
            <NoFileDialog
              fileName={files.length > 0}
              submitClicked={submitClicked}
              setSubmitClicked={setSubmitClicked}
            />
          </div>
        )}

        {showSourcesIncome && (
          <Box color="black" mt={1}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={multipleSourcesIncome}
                    onChange={() =>
                      setMultipleSourcesIncome(!multipleSourcesIncome)
                    }
                  />
                }
                label={t("I have multiple sources of income")}
              />
            </FormGroup>
          </Box>
        )}

        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <br />
          {t("anything_else_to_add")}
          <br />
          <br />
          <TextField
            value={other}
            onChange={(event) => setOther(event.target.value)}
            style={{ minWidth: "320px", width: "90%" }}
            rows={4}
            multiline
            variant="filled"
          />
          <br />
          <Button
            variant="contained"
            color="secondary"
            size="large"
            type="submit"
            disabled={submitClicked}
          >
            {!submitClicked && t("finalize")}
            {submitClicked && <CircularProgressWithLabel value={progress} />}
          </Button>
        </FormControl>
      </form>
      <MessagePopup state={message} setState={setMessage} message={message} />
    </div>
  );
};

export default Miscellaneous;
