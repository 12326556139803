import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
  Link,
  Paper,
  Typography,
} from "@mui/material";

import SkipNextIcon from "@mui/icons-material/SkipNext";
import ErrorIcon from "@mui/icons-material/Error";

import cookies from "js-cookie";

import { useLocation, useSearchParams } from "react-router-dom";
import axios from "axios";

import LoadingDataComponent from "../components/LoadingDataComponent";
import ProgressBar from "../components/ProgressBar";

import { Player } from "@lottiefiles/react-lottie-player";
import MessagePopup from "../components/MessagePopup";

const paperStyle = {
  padding: 3,
  mx: 2,
  minWidth: 350,
  maxWidth: 450,
  bgcolor: "#f5f5f5",
  borderColor: "#257cff",
  borderRadius: 4,
  boxShadow: 3,
};

const InvalidAddressMessage = () => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      spacing={3}
      direction="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      mb={5}
    >
      <Grid item align="center" maxWidth="350px">
        <Paper sx={paperStyle}>
          <ErrorIcon color="error" fontSize="large" />

          <Typography sx={{ margin: 2, fontSize: 24, fontWeight: "bold" }}>
            {t("Invalid address")}
          </Typography>
          <Typography sx={{ margin: 2, marginTop: 6, fontSize: 16 }}>
            {window.location.href}
          </Typography>
          <Typography sx={{ margin: 2, fontSize: 16 }}>
            {t("Invalid address paragraph")}
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
};

export const IsFlowDeletedDialog = ({ isDeleted, nameAgent, emailAgent }) => {
  const { t } = useTranslation();
  return (
    <Dialog open={isDeleted}>
      <DialogContent>
        <DialogContentText>
          {t("your_agent_has_deleted_your_request1")}
          {nameAgent}
          {t("your_agent_has_deleted_your_request2")}
          <Link href={"mailto:" + emailAgent} color="inherit" target="_blank">
            {emailAgent}
          </Link>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

const WelcomePage = ({ setShowLanguageSelection, url }) => {
  const { t } = useTranslation();
  const { search } = useLocation();

  const [isDeleted, setIsDeleted] = useState(false);

  const [firstName, setFirstName] = useState(false);
  const [propertyCity, setPropertyCity] = useState(false);
  const [createdTimestamp, setCreatedTimestamp] = useState(false);
  const [daysDeadline, setDaysDeadline] = useState(false);

  const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] =
    useState(null);
  const [nameAgent, setNameAgent] = useState("");
  const [emailAgent, setEmailAgent] = useState("");

  const [nextLink, setNextLink] = useState(null);

  const [termsAndConditionsCheckbox, setTermsAndConditionsCheckbox] = useState(false);
  const [message, setMessage] = useState(false);

  const [alreadyRendered, setAlreadyRendered] = useState(false);
  const [searchParams] = useSearchParams();
  const properize_id = searchParams.get("properize_id");

  setShowLanguageSelection(true);

  const currentLanguage = cookies.get("i18next");
  let endpoint = "tenant-information";

  if (currentLanguage === "nl") {
    endpoint = "huurders";
  } else {
    endpoint = "tenant-information";
  }

  useEffect(() => {
    if (properize_id) {
      let payload = {
        properize_id: properize_id,
        step: "started_flow",
      };

      axios.post(url + "/add_timestamp_api", payload);

      payload = {
        id: properize_id,
        database: "Tenants",
      };

      axios
        .post(url + "/get_data_from_airtable_table", payload)
        .then(async (response) => {
          if (response.data["Flow Completed"] === "Yes") window.location.assign("/extra_information?properize_id=" + properize_id)
          setFirstName(response.data["First name"]);
          setPropertyCity(response.data["Property city"]);
          setNameAgent(response.data["Name agent"]);
          setEmailAgent(response.data["Email agent"]);
          setCreatedTimestamp(response.data["Created"]);
          setDaysDeadline(response.data["Days deadline"]);
          setAcceptedTermsAndConditions(
            response.data["Accepted Terms and Conditions"]
          );
          setIsDeleted(response.data["ArchiveRemove"] === "2");

          if (
            response.data["Resume link"] &&
            response.data["ArchiveRemove"] !== "2"
          ) {
            window.location.assign(response.data["Resume link"]);
          } else if (response.data["notAllowCotenants"] || response.data["family_composition_done"]) {
            setNextLink(`/about_yourself${search}`);
            setAlreadyRendered(true);
          } else {
            payload = {
              properize_id: properize_id,
            };
            await axios
              .post(url + "/get_number_of_tenants", payload)
              .then((response) => {
                if (response.data === 1) {
                  setNextLink(`/family_composition${search}`);
                } else {
                  setNextLink(`/about_yourself${search}`);
                }
                setAlreadyRendered(true);
              });
          }
        });
    }
  }, [properize_id, search, url]);



  const nameStyles = {
    fontWeight: "bold",
  };

  const getForwardDate = (createdDate, daysDeadline, separator = "-") => {
    function addBusinessDays(startDate, days) {
      if (isNaN(days)) {
        return;
      }
      if (!(startDate instanceof Date)) {
        return;
      }
      // Get the day of the week as a number (0 = Sunday, 1 = Monday, .... 6 = Saturday)
      var daysToAdd = parseInt(days);

      startDate.setDate(startDate.getDate() + daysToAdd);
      return startDate;
    }

    let targetDate = new Date(createdDate);
    targetDate = addBusinessDays(targetDate, daysDeadline);
    let date = targetDate.getDate();
    let month = targetDate.getMonth() + 1;
    let year = targetDate.getFullYear();

    return `${date}${separator}${month < 10 ? `0${month}` : `${month}`
      }${separator}${year}`;
  };

  function handleSubmit() {
    if (!acceptedTermsAndConditions & !termsAndConditionsCheckbox) {
      setMessage(t("You need to agree with terms and conditions"));
    } else if (!acceptedTermsAndConditions) {
      setAlreadyRendered(false);
      axios
        .post(url + "/log_terms_and_conditions_timestamp", {
          properize_id: properize_id,
        })
        .then((response) => {
          window.location.assign(nextLink);
        });
    } else {
      window.location.assign(nextLink);
    }
  }

  if (window.location.href === 'https://tenant.properize.com/')
    return (<div>
      <InvalidAddressMessage />
    </div>)

  return (
    <div>
      <IsFlowDeletedDialog isDeleted={isDeleted} nameAgent={nameAgent} emailAgent={emailAgent} />
      <LoadingDataComponent alreadyRendered={alreadyRendered} />
      <ProgressBar completed={0} />
      <br />
      <Typography variant="h6" color="textSecondary">
        {t("welcome_to_consent_flow")}
      </Typography>
      <br />
      <Grid container direction="row" spacing={6}>
        <Grid item sm={6}>
          <Typography variant="p" color="textSecondary">
            {t("Hi")}
            <span style={nameStyles}>{firstName}</span>!
          </Typography>
          <br />
          <br />
          <Typography variant="p" color="textSecondary">
            {t("congratulations")}
            <span style={nameStyles}>{propertyCity}</span>.
          </Typography>
          <Player
            autoplay
            loop
            src="https://assets8.lottiefiles.com/packages/lf20_puciaact.json"
          ></Player>
        </Grid>
        <Grid item sm={6}>
          <Grid container direction="row" alignItems="center">
            <Typography variant="p" color="textSecondary">
              {t("welcome_intro1")}
              <span style={nameStyles}>{nameAgent}</span>
              {t("welcome_intro2")}
            </Typography>
            <br />
            <br />
            <Typography variant="p" color="textSecondary">
              {t("you_need")}
            </Typography>
            <ul>
              <li>
                <Typography variant="p" color="textSecondary">
                  {t("you_need_passport")}
                </Typography>
              </li>
              <li>
                <Typography variant="p" color="textSecondary">
                  {t("you_need_income")}
                </Typography>
              </li>
              <li>
                <Typography variant="p" color="textSecondary">
                  {t("you_need_payslips")}
                </Typography>
              </li>
              <li>
                <Typography variant="p" color="textSecondary">
                  {t("you_need_employment")}
                </Typography>
              </li>
            </ul>
          </Grid>
          <br />

          {t("deadline")}
          <span style={nameStyles}>
            {getForwardDate(createdTimestamp, daysDeadline)}
          </span>
          <br />
          <br />
          <Link
            href={"https://properize.com/" + endpoint + "/"}
            color="inherit"
            target="_blank"
          >
            {t("welcome_data_use")}
          </Link>
          <br />
          <br />
          {!acceptedTermsAndConditions && (
            <FormControlLabel
              sx={{
                marginTop: "20px",
                marginBottom: "20px",
                marginLeft: "5px",
                alignItems: "flex-end",
              }}
              control={
                <Checkbox
                  onChange={() =>
                    setTermsAndConditionsCheckbox(!termsAndConditionsCheckbox)
                  }
                  value={termsAndConditionsCheckbox}
                />
              }
              label={
                <Typography
                  mb={1.5}
                  align="left"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  {t("By ticking, you agree to our")}
                  <Link
                    href="https://properize.com/terms-and-conditions/"
                    color="inherit"
                    target="_blank"
                  >
                    {t("terms and conditions")}
                  </Link>
                  {t("and")}
                  <Link
                    href="https://properize.com/privacy-policy/"
                    color="inherit"
                    target="_blank"
                  >
                    {t("privacy policy")}
                  </Link>
                </Typography>
              }
            />
          )}
          <br />
          <Button
            variant="contained"
            endIcon={<SkipNextIcon />}
            size="large"
            color="secondary"
            onClick={handleSubmit}
            sx={{
              marginLeft: "15px",
            }}
          >
            {t("start")}
          </Button>
        </Grid>
      </Grid>
      <MessagePopup state={message} setState={setMessage} message={message} />
    </div>
  );
};

export default WelcomePage;
