import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Container, Grid, Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import properizeLogo from '../logos/properize.png';

const theme = createTheme({
  palette: {
    primary: {
      main: "#257cff",
    },
    secondary: {
      main: "#f45a2a",
    },
  },
  typography: {
    footer: {
      fontSize: 14,
    },
    copyright: {
      fontSize: 12,
    },
    poweredby: {
      fontSize: 9,
    },
  },
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        asterisk: { display: "none" }
      }
    },
  },
});

const Footer = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <ThemeProvider theme={theme}>
        <Box px={{ xs: 1, sm: 2 }} py={{ xs: 1, sm: 2 }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={12} sm={4}>
              <Box>
                <img
                  loading="lazy"
                  src={properizeLogo}
                  alt="properizeLogo"
                  width="138"
                  height="36"
                />
              </Box>
              <br />
              <Box>
                <Typography variant="footer">
                  <p>
                    <strong>Anno MMX B.V.</strong>
                    <br />
                    Blaak 555<br />
                    3011 GB Rotterdam
                    <br />
                    The Netherlands
                  </p>
                  <p>
                    KVK nummer: 34386709
                    <br />
                    BTW nummer: NL822210095B01
                  </p>
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Typography variant="footer">
                <Box>
                  <Link
                    href="https://properize.com/terms-and-conditions/"
                    color="inherit"
                    target="_blank"
                  >
                    {t("terms_and_conditions")}
                  </Link>
                </Box>
                <Box>
                  <Link
                    href="https://properize.com/privacy-policy/"
                    color="inherit"
                    target="_blank"
                  >
                    {t("privacy_policy")}
                  </Link>
                </Box>
                <Box>
                  <Link
                    href="https://properize.com/cookies/"
                    color="inherit"
                    target="_blank"
                  >
                    {t("cookies")}
                  </Link>
                </Box>
              </Typography>
            </Grid>
          </Grid>
          <Box textAlign="center" pt={{ xs: 5, sm: 10 }} pb={{ xs: 5, sm: 0 }}>
            <Typography variant="copyright">
              Copyright © {new Date().getFullYear()}
            </Typography>
          </Box>
        </Box>
      </ThemeProvider>
    </Container>
  );
};

export default Footer;
