import axios from "axios";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { InputPictures } from "../components/IDVerificationComponents";
import { Grid } from "@mui/material";

const IDVerificationMobile = ({ url, setShowLanguageSelection }) => {
  const [searchParams] = useSearchParams();
  const properize_id = searchParams.get("properize_id");

  setShowLanguageSelection(true);
  
  const onCompleted = () => {}

  useEffect(() => {
    const payload = { properize_id: properize_id, handoverDone: true };
    axios.post(url + "/selfie_handover_done", payload);
  }, [properize_id, url]);

  return (
    <Grid container direction="column" alignItems="center" mt={1}>
      <br />
      <InputPictures properize_id={properize_id} url={url} onCompleted={onCompleted} isHandover={true}/>
    </Grid>
  );
};

export default IDVerificationMobile;
