import React, { useEffect, useState } from "react";

const ProgressBar = ({ completed }) => {
  const [completedState, setCompletedState] = useState(0);

  const containerStyles = {
    height: 20,
    backgroundColor: "#e0e0de",
    borderRadius: 50,
    marginLeft: 10,
    marginRight: 10,
  };

  const fillerStyles = {
    height: "100%",
    width: `${completedState}%`,
    backgroundColor: "#f45a2a",
    borderRadius: "inherit",
    textAlign: "center",
    transition: "width 1s ease-in-out",
  };

  const labelStyles = {
    padding: 5,
    color: (completedState != 0) ? "white": "black",
    fontWeight: "bold",
  };

  useEffect(() => {
    setInterval(() => setCompletedState(completed), 2000);
  }, []);

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
        <span style={labelStyles}>{`${completedState}%`}</span>
      </div>
    </div>
  );
};

export default ProgressBar;
