import React from "react";
import { useTranslation } from "react-i18next";
import { GeoapifyGeocoderAutocomplete, GeoapifyContext } from '@geoapify/react-geocoder-autocomplete'
import '@geoapify/geocoder-autocomplete/styles/round-borders.css'
import { Grid, Link } from "@mui/material";

function AutoCompleteAddress({ setStreet, setHouseNumber, setPostCode, setCity, setCountryCode, countryRestrictions = [] }) {
  const { t } = useTranslation();

  function onPlaceSelect(value) {
    setStreet(value.properties.street)
    setPostCode(value.properties.postcode)
    setCountryCode(value.properties.country)
    setCity(value.properties.city)
    setHouseNumber(value.properties.housenumber)
  }

  return <div style={{ maxWidth: "800px", marginTop: "10px" }}>
    <GeoapifyContext apiKey={process.env.REACT_APP_GEOAPIFY_MAPS_API}>
      <GeoapifyGeocoderAutocomplete placeholder={t("AddressGooglePlaceholder")}
        placeSelect={onPlaceSelect}
        filterByCountryCode={countryRestrictions}
        debounceDelay={500}
        skipDetails
      />
    </GeoapifyContext>
    <Grid ml={1} style={{fontStyle: "italic", fontSize: 12}}>
      Powered by{"\u00a0"}
      <Link
        href="https://www.geoapify.com"
        color="inherit"
        target="_blank"
      >
        Geoapify
      </Link>
    </Grid>
  </div>
}

export default AutoCompleteAddress;
