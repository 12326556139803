import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";

import LoadingDataComponent from "../components/LoadingDataComponent";

const RelashionshipDropdown = ({
  guarantorRelationship,
  setGuarantorRelationship,
  setGuarantorRelationshipOther,
}) => {
  const { t } = useTranslation();

  const handleChange = (event) => {
    setGuarantorRelationship(event.target.value);
  };

  return (
    <div>
      <br />
      <TextField
        value={guarantorRelationship}
        onChange={handleChange}
        select
        label={t("relationship_type")}
        InputLabelProps={{ required: false }}
        style={{ marginTop: "10px" }}
        fullWidth
        required
      >
        <MenuItem value={"parent"}>{t("parent")}</MenuItem>
        <MenuItem value={"family"}>{t("family")}</MenuItem>
        <MenuItem value={"friend"}>{t("friend")}</MenuItem>
        <MenuItem value={"other"}>{t("other")}</MenuItem>
      </TextField>{" "}
      <br />
      {guarantorRelationship === "other" && (
        <TextField
          onChange={(event) =>
            setGuarantorRelationshipOther(event.target.value)
          }
          label={t("guarantor_relationship_other")}
          variant="standard"
          style={{ marginTop: "10px" }}
          InputLabelProps={{ required: false }}
          fullWidth
          required
        />
      )}
    </div>
  );
};

const GuarantorDialog = ({
  properize_id,
  url,
  guarantorDialogOpen,
  setGuarantorDialogOpen,
  guarantorName,
  setGuarantorName,
  guarantorLastname,
  setGuarantorLastname,
  setGuarantorRequestSent,
}) => {
  const [guarantorEmail, setGuarantorEmail] = useState("");
  const [guarantorRelationship, setGuarantorRelationship] = useState("");
  const [guarantorRelationshipOther, setGuarantorRelationshipOther] =
    useState(null);

  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    const payload_form = {
      properize_id: properize_id,
      guarantorName: guarantorName,
      guarantorLastname: guarantorLastname,
      guarantorEmail: guarantorEmail,
      guarantorRelationship: guarantorRelationship,
      guarantorRelationshipOther: guarantorRelationshipOther,
    };

    axios
      .post(url + "/add_guarantor_to_database", payload_form)
      .then((response) => {
        setLoading(false);
        setGuarantorDialogOpen(false);
        setGuarantorRequestSent(true);
      });
  };

  return (
    <div>
      <Dialog open={guarantorDialogOpen}>
        <DialogTitle align="center">
          <Grid p={1}>{t("Add a guarantor")}</Grid>
        </DialogTitle>
        <DialogContent sx={{ minWidth: "310px" }}>
        <form onSubmit={handleSubmit}>
          <TextField
            onChange={(event) => setGuarantorName(event.target.value)}
            label={t("firstname")}
            variant="standard"
            InputLabelProps={{ required: false }}
            fullWidth
            required
          />
          <br />
          <TextField
            onChange={(event) => setGuarantorLastname(event.target.value)}
            label={t("lastname")}
            variant="standard"
            style={{ marginTop: "10px" }}
            InputLabelProps={{ required: false }}
            fullWidth
            required
          />
          <br />
          <TextField
            onChange={(event) => setGuarantorEmail(event.target.value)}
            label={t("email")}
            type="email"
            variant="standard"
            style={{ marginTop: "10px" }}
            InputLabelProps={{ required: false }}
            fullWidth
            required
          />
          <br />
          <RelashionshipDropdown
            guarantorRelationship={guarantorRelationship}
            setGuarantorRelationship={setGuarantorRelationship}
            setGuarantorRelationshipOther={setGuarantorRelationshipOther}
          />
          <Grid container direction="column" wrap="nowrap" mt={5} spacing={1}>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                onClick={() => setGuarantorDialogOpen(false)}
                fullWidth
              >
                {t("back")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                fullWidth
              >
                {t("add_guarantor")}
              </Button>
            </Grid>
          </Grid>
          </form>
        </DialogContent>
      </Dialog>
      <LoadingDataComponent alreadyRendered={!loading} />
    </div>
  );
};

export default GuarantorDialog;
