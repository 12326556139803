import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Player } from "@lottiefiles/react-lottie-player";
import { Grid, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import EmailEnvelope from "../lottie_files/email_envelope-1714555980603.json";
import axios from "axios";

const ResendEmail = ({ url }) => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const properize_id = searchParams.get("properize_id");

  const [email, setEmail] = useState("");

  useEffect(() => {
    let payload = {
      id: properize_id,
      database: "Tenants",
    };
    axios
      .post(url + "/get_data_from_airtable_table", payload)
      .then((response) => {
        setEmail(response.data["Email"])
      })

    axios.post(
      url + "/resend_email_invitation_to_tenant",
      { properize_id: properize_id }
    );

  }, []);

  return (
    <div>
      <Grid container justifyContent="space-between" spacing={1}>
        <Grid item sm={6}>
          <Player
            autoplay
            loop
            src={EmailEnvelope}
          ></Player>
        </Grid>
        <Grid item sm={6}>
          <br />
          <br />
          <Typography variant="h4" color="textSecondary">
            {t("Email invite has been resent successfully to")}:
          </Typography>
          <br />
          <br />
          <Typography variant="h6" color="textSecondary">
            {email}
          </Typography>
          <br />
          <br />
          <Typography variant="p" color="textSecondary">
            {t("thank_you_for_signing_paragraph")}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default ResendEmail;
