import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { Container, CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useState } from "react";

import TopBar from "./components/TopBar";
import Footer from "./components/Footer";

import WelcomePage from "./pages/WelcomePage";
import TellUsAboutYourself from "./pages/TellUsAboutYourself";
import IncomeVerification from "./pages/IncomeVerification";
import Error from "./pages/Error";
import ResendEmail from "./pages/ResendEmail";

import AdditionalQuestions from "./pages/AdditionalQuestions";
import DescribeYourPersonalSituation from "./pages/DescribeYourPersonalSituation";
import AddMoreTenants from "./pages/AddMoreTenants";
import Miscellaneous from "./pages/Miscellaneous";
import Completed from "./pages/Completed";
import AdminPanel from "./pages/AdminPanel";

import GuarantorWelcomePage from "./pages/GuarantorWelcomePage";
import GuarantorProofOfIncome from "./pages/GuarantorProofOfIncome";
import GuarantorCompleted from "./pages/GuarantorCompleted";
import ExtraInfo from "./pages/ExtraInfo";
import WelcomePageLite from "./pages/LiteVersion/WelcomePageLite";
import TellUsAboutYourselfLite from "./pages/LiteVersion/TellUsAboutYourselfLite";
import IDVerificationLite from "./pages/LiteVersion/IDVerificationLite";
import AdditionalQuestionsLite from "./pages/LiteVersion/AdditionalQuestionsLite";
import AddMoreTenantsLite from "./pages/LiteVersion/AddMoreTenantsLite";
import Redirect from "./pages/Redirect";
import CancelRequest from "./pages/CancelRequest";
import IDVerification from "./pages/IDVerification";
import IDVerificationMobile from "./pages/IDVerificationMobile";
import GuarantorIDVerification from "./pages/GuarantorIDVerification";
import GuarantorIDVerificationMobile from "./pages/GuarantorIDVerificationMobile";
import ThankYouForSigning from "./pages/ThankYouForSigning";
import NotYouClickHere from "./pages/NotYouClickHere";


const theme = createTheme({
  palette: {
    primary: {
      main: "#257cff",
    },
    secondary: {
      main: "#f45a2a",
    },
    background: {
      default: "#e7f3fc",
    },
    typography: {
      body2: {
          fontSize: [30, "!important"]
      }
    },
  },
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        asterisk: { display: "none" }
      }
    },
  },
});


const App = () => {
  const backend_url = "https://api.properize.com";
  //const backend_url = "http://localhost:8080"; 

  const [showLanguageSelection, setShowLanguageSelection] = useState(true);
  
  return (
    <div>
      <Container>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <TopBar showLanguageSelection={showLanguageSelection}/>
            <NotYouClickHere url={backend_url} />
            <Routes>
              <Route path="/" element={<WelcomePage setShowLanguageSelection={setShowLanguageSelection} url={backend_url}/>} />
              <Route path="/cancel_request/" element={<CancelRequest url={backend_url}/>} />
              <Route path="/resend_email/" element={<ResendEmail url={backend_url} />} />

              <Route path="/about_yourself" element={<TellUsAboutYourself url={backend_url} />} />
              <Route path="/id_verification_main" element={<IDVerification url={backend_url} setShowLanguageSelection={setShowLanguageSelection} />} />
              <Route path="/id_verification_mobile" element={<IDVerificationMobile url={backend_url} setShowLanguageSelection={setShowLanguageSelection}/>} />

              <Route path="/income_verification/" element={<IncomeVerification url={backend_url} setShowLanguageSelection={setShowLanguageSelection}/>} />
              <Route path="/signing_completed/" element={<ThankYouForSigning />} />
              <Route path="/error/" element={<Error />} />
              <Route path="/redirect/" element={<Redirect url={backend_url} />} />
              <Route path="/personal_situation/" element={<DescribeYourPersonalSituation url={backend_url} setShowLanguageSelection={setShowLanguageSelection}/>} />
              <Route path="/additional_questions/" element={<AdditionalQuestions url={backend_url} setShowLanguageSelection={setShowLanguageSelection}/>} />
              <Route path="/family_composition/" element={<AddMoreTenants url={backend_url} setShowLanguageSelection={setShowLanguageSelection}/>} />
              <Route path="/optional_information/" element={<Miscellaneous url={backend_url} setShowLanguageSelection={setShowLanguageSelection}/>} />
              <Route path="/extra_information/" element={<ExtraInfo url={backend_url} setShowLanguageSelection={setShowLanguageSelection}/>} />
              <Route path="/completed/" element={<Completed url={backend_url}/>} />
              <Route path="/admin_panel" element={<AdminPanel url={backend_url} setShowLanguageSelection={setShowLanguageSelection} />} />
              
              <Route path="/guarantor" element={<GuarantorWelcomePage url={backend_url} setShowLanguageSelection={setShowLanguageSelection} />} />
              <Route path="/guarantor_id_verification" element={<GuarantorIDVerification url={backend_url} setShowLanguageSelection={setShowLanguageSelection} />} />
              <Route path="/guarantor_id_verification_mobile" element={<GuarantorIDVerificationMobile url={backend_url} setShowLanguageSelection={setShowLanguageSelection} />} />
              <Route path="/guarantor_proof_of_income" element={<GuarantorProofOfIncome url={backend_url} setShowLanguageSelection={setShowLanguageSelection} />} />
              <Route path="/guarantor_completed" element={<GuarantorCompleted url={backend_url} setShowLanguageSelection={setShowLanguageSelection} />} />

              <Route path="/lite" element={<WelcomePageLite setShowLanguageSelection={setShowLanguageSelection} url={backend_url}/>} />
              <Route path="/lite_family_composition" element={<AddMoreTenantsLite url={backend_url} setShowLanguageSelection={setShowLanguageSelection}/>} />
              <Route path="/lite_about_yourself" element={<TellUsAboutYourselfLite url={backend_url} />} />
              <Route path="/lite_id_verification_main" element={<IDVerificationLite url={backend_url} setShowLanguageSelection={setShowLanguageSelection} />} />
              <Route path="/lite_additional_questions" element={<AdditionalQuestionsLite url={backend_url} setShowLanguageSelection={setShowLanguageSelection}/>} />
            </Routes>
          </Router>
        </ThemeProvider><br /><br />
      </Container>
      <div style={{backgroundColor: 'white' }}>
        <Footer />
      </div>
    </div>
  );
};

export default App;
