import axios from "axios";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { InputPictures } from "../components/GuarantorIDAnalyzerComponents";
import { Grid } from "@mui/material";

const GuarantorIDVerificationMobile = ({ url, setShowLanguageSelection }) => {
  const [searchParams] = useSearchParams();
  const guarantor_id = searchParams.get("guarantor_id");

  setShowLanguageSelection(true);

  const onCompleted = () => { };

  useEffect(() => {
    // Get guarantor_id
    const payload = { guarantor_id: guarantor_id, handoverDone: true };
    axios.post(url + "/selfie_handover_done_guarantor", payload);
  }, []);

  return (
    <Grid container direction="column" alignItems="center" mt={3}>
      <br />
      <InputPictures
        guarantor_id={guarantor_id}
        url={url}
        onCompleted={onCompleted}
      />
    </Grid>
  );
};

export default GuarantorIDVerificationMobile;
