import React from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
} from "@mui/material";

const NoFileDialog = ({ fileName, submitClicked, setSubmitClicked }) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={!fileName && submitClicked}
      onClose={() => setSubmitClicked(false)}
    >
      <DialogContent>
        <DialogContentText>
          {t("no_file_selected")}
        </DialogContentText>
        <Grid align="center" mt={3}>
          <Button variant="contained" onClick={() => setSubmitClicked(false)}>
            OK
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default NoFileDialog;
